import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllOrgPatient,
  updatePatientDetails,
  getAllBedAssign,
  getAllBed,
} from "../../slices/thunk";
import { FaQrcode, FaSearch, } from "react-icons/fa";
import dischargeIcon from '../../assets/images/admit2.png';
import { DatePicker, DateValidationError, LocalizationProvider } from '@mui/x-date-pickers';
import {
  Modal, ModalFooter, Row, Col, Card, CardBody, CardTitle, CardSubtitle, CardFooter, Badge, ModalBody, ModalHeader, List
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import { Bed } from '@mui/icons-material';
import { Paginator } from 'primereact/paginator'
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  Autocomplete,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Box
} from "@mui/material";
import { baseURL, successCode } from "../../configuration/url";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faPencil } from "@fortawesome/free-solid-svg-icons";
import { Button } from "primereact/button";
import axios from 'axios';
import * as Constants from "../Constants/Constant";
import { toast } from "react-toastify";
import PatientCreation from "../Patient/patientCreation";
import { BrowserMultiFormatReader } from "@zxing/library";
import { authorize, formatDateToYYYYMMDD, formatSSN } from '../../helpers/common';
import Loader from "../../components/loader/Loader";
// import roomimg from "../../assets/images/room.svg";
// import bedimg from "../../assets/images/bed.svg";
// import LogoImg from "../../assets/images/profile.svg";
import "../bedAssign/bedassign.css";
import { CircularProgress } from "@material-ui/core";
import { grey, primarybg, primarytext, secondarybg } from "../../common/primary";
import PatientBed from "./patientBed";
interface DropdownItem {
  id: string;
  value: string;
  type: string;
}

interface Dropdown {
  id: string;
  dropdown: string;
  list: DropdownItem[];
}

const Patient: React.FC = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  }
  const [dropdownData, setDropdownData] = useState<Dropdown[]>([]);
  const dispatch = useDispatch<any>();
  const [admitD, setAdmitD] = useState<boolean>(false);
  // const [admitDate, setAdmitDate] = useState('');
  const [reason, setReason] = useState('')
  const [selectPatientId, setSelectPatientId] = useState<string | null>(null);
  const [editModal, setEditModal] = useState(false);
  const { allOrgData, loading, totalElements } = useSelector((state: any) => state.Patient);
  const { organization, userData } = useSelector((state: any) => state.Login);
  const userId = userData?.userDetail.id;
  const [bedAssignDialog, setBedAssignDialog] = useState(false);
  let [bedSelected, setBedSelected] = useState<string | null>(null);
  const [bedClick, setBedClick] = useState(false)
  const [patientAndBedAssign, setPatientAndBedAssign] = useState<any[]>([]);
  const [bedId, setBedId] = useState<string | null>(null);
  const [options, setOptions] = useState<any>([]);
  const videoRef = useRef(null);
  const codeReader = new BrowserMultiFormatReader();
  const [scanning, setScanning] = useState(false);
  const [deviceId, setDeviceId] = useState('')
  const [currentPage, setCurrentPage] = useState(0);
  let [cityDropDown, setCityDropDown] = useState(new Array<any>());
  let [highlightfirstname, setHighlight] = useState(false);
  let [highlightlastname, sethighlightlastname] = useState(false);
  let [highlightssn, sethighlightssn] = useState(false);
  let [highlightemail, sethighlightemail] = useState(false);
  let [highlightzipcode, sethighlightzipcode] = useState(false);
  let [highlightdate1, sethighlightdate1] = useState(false);
  const [newPatientData, setNewPatientData] = useState<any[]>([]);
  let [highlightdate, sethighlightdate] = useState(false);
  const [dateFieldError, setDateFieldError] = React.useState<string>('');
  const [error, setError] = React.useState<DateValidationError | null>(null);
  const [admitDate, setAdmitDate] = React.useState<dayjs.Dayjs | null>(null);
  const [search, setSearch] = useState('')
  let [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    birthDate: "",
    ssn: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    postalCode: "",
    mrNumber: "",
    email: "",
    beaconDevice1: [],
    gender: "",
    country: "",
  });
  const errorMessage = React.useMemo(() => {
    if (dateFieldError) return dateFieldError;
    switch (error) {
      case 'maxDate':
      case 'minDate':
        return 'Please select a date in the first quarter of 2022';
      case 'invalidDate':
        return 'Your date is not valid';
      default:
        return '';
    }
  }, [error, dateFieldError]);
  useEffect(() => {
    getAllOrgPatient(dispatch, organization, 0, search ? search : '');
    fetchNewPatientData();
  }, [dispatch, organization, search]);

  useEffect(() => {
    const fetchData = async () => {
      const { header1 } = authorize();
      try {
        const response = await axios.get(`${baseURL}/sensor/getAllByorgId/${organization}`, { headers: header1 });
        setOptions(response.data.data.content);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);
  const fetchNewPatientData = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/patient/get/activePatientMob/${organization}`
      );

      if (response.data.data && Array.isArray(response.data.data)) {
        setNewPatientData(response.data.data);
      } else {
        console.error("Invalid data format for patients:", response.data);
      }
    } catch (error) {
      console.warn(error);
    }
  }
  const fetchPatientsandBedAssign = async () => {
    const { header1 } = authorize();
    try {
      const response = await axios.get(
        `${baseURL}/Q15Bed/getByOrg/${organization}`, { headers: header1 }
      );
      if (response.data.data && Array.isArray(response.data.data)) {
        setPatientAndBedAssign(response.data.data);
      } else {
        console.error("Invalid data format for patients:", response.data);
      }
    } catch (error) {
      console.warn(error);
    }
  };
  useEffect(() => {
    if (scanning) {
      startScanning();
    } else {
      codeReader.reset();
    }

    return () => {
      codeReader.reset();
    };
  }, [scanning]);

  const startScanning = async () => {
    try {
      setScanning(true);
      const videoInputDevices = await codeReader.listVideoInputDevices();
      const selectedDeviceId = videoInputDevices[0].deviceId;
      const constraints = {
        video: { deviceId: selectedDeviceId },
      };
      codeReader.decodeFromVideoDevice(
        selectedDeviceId,
        videoRef.current,
        (result: any, err: any) => {
          if (result) {
            let newDeviceId = "";
            const result1 = result.getText();
            if (result1 !== "" && result1 !== undefined && result1 !== null) {
              if (result1.length < 15) {
                const resultId = result1.match(/.{2}/g).join(':');
                newDeviceId = resultId;
              } else {
                const resultId = result1.match(/.{2}/g).join(':');
                newDeviceId = resultId.slice(6, 23);
              }
              setShow(false);
              setScanning(false);
            }
            const scannedOption = options.find((option: any) => option.deviceId === newDeviceId);
            if (scannedOption) {
              setDeviceId(scannedOption.deviceId)
              const modal = document.getElementById("exampleModal");
              if (modal) {
                modal.classList.add("show");
                modal.style.display = "block";
              }
            } else {
              setDeviceId("");
              toast.error("Scanned device ID not found in the options.");
            }
          }
          if (err && err.name === "NotFoundError") {
            console.error("No QR code found in the video feed.");
          }
          if (err) {
            console.error("Error during scanning:", err);
          }
        },
      );
    } catch (error) {
      console.error("Error starting the scanner:", error);
    }
  };

  const [show, setShow] = useState(false);

  const handleClose1 = () => {
    setShow(false)
    setScanning(false)
    codeReader.reset()
  };

  const handleQrClick = () => {
    if (options.length < 1) return;
    setShow(true);
    setScanning(!scanning);
  }
  const getPatientName = (patientId: any) => {
    const selectedPatient = newPatientData?.find((patient: any) => patient.id === patientId);
    if (selectedPatient) {
      if (selectedPatient.name && selectedPatient.name.length > 0) {
        const { family, given } = selectedPatient.name[0];
        const fullName = `${given} ${family}`;
        return fullName;
      } else if (selectedPatient.basicDetails && selectedPatient.basicDetails.length > 0) {
        const { family, given } = selectedPatient.basicDetails[0].name[0];
        const fullName = `${given} ${family}`;
        return fullName;
      }
    }
    console.warn(`Patient data issue for ID: ${patientId}`, selectedPatient);
    return "Unknown";
  };

  useEffect(() => {
    getAllBedAssign(dispatch, organization);
    getAllBed(dispatch, organization);
  }, [dispatch, organization]);

  useEffect(() => {
    fetchPatientsandBedAssign();
  }, [admitD]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const [loading1, setLoading] = useState(false);
  const handlePostalCodeChange = async (e: any) => {
    formData.postalCode = e.target.value;
    setFormData({ ...formData });
    sethighlightzipcode(false);
    if (e.target.value.length === 5) {
      setLoading(true);
      try {
        const response = await axios.get(`https://app.zipcodebase.com/api/v1/search?codes=${e.target.value}&country=US&${Constants.apiKey}`);
        formData.state = response.data.query.codes !== null && response.data.query.codes !== undefined ? response.data.results[response.data.query.codes[0]][0].state : "";
        formData.country = response.data.query.codes !== null && response.data.query.codes !== undefined ? response.data.results[response.data.query.codes[0]][0].country_code : "";
        formData.city = response.data.query.codes !== null && response.data.query.codes !== undefined ? response.data.results[response.data.query.codes[0]][0].city : "";
        setCityDropDown(response.data.query.codes !== null && response.data.query.codes !== undefined ? response.data.results[response.data.query.codes[0]].map((k: any) => k.city_en) : [])
      } catch (error) {
        console.error('Error fetching city:', error);
      } finally {
        setLoading(false);
      }
    } else {
      formData.state = "";
      formData.country = "";
      formData.city = "";
    }
    setFormData({ ...formData });
  };

  const handleAddress = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const highlight = () => {
    let highlighted = false;
    if (!formData.firstName.trim()) {
      setHighlight(true);
      toast.error("Please Enter First Name");
      highlighted = true;
    } else {
      setHighlight(false);
    }
    if (!formData.lastName.trim()) {
      sethighlightlastname(true);
      toast.error("Please Enter LastName");
      highlighted = true;
    } else {
      sethighlightlastname(false);
    }
    if (!formData.birthDate.trim()) {
      sethighlightdate(true);
      toast.error("Please Enter birthDate");
      highlighted = true;
    } else {
      sethighlightdate(false);
    }
    if (!formData.ssn.trim()) {
      sethighlightssn(true);
      toast.error("Please Enter tin");
      highlighted = true;
    } else {
      sethighlightssn(false);
    }
    if (!formData.email.trim()) {
      sethighlightemail(true);
      toast.error("Please Enter email");
      highlighted = true;
    } else {
      sethighlightemail(false);
    }
    if (!formData.postalCode.trim()) {
      sethighlightzipcode(true);
      toast.error("Please Enter Postalcode");
      highlighted = true;
    } else {
      sethighlightzipcode(false);
    }
    return highlighted;
  };

  const handleinputchange = (event: any) => {
    if (event.target.id === 'firstName') {
      formData.firstName = event.target.value;
      setHighlight(false);
    } else if (event.target.id === 'middleName') {
      formData.middleName = event.target.value;
    } else if (event.target.id === 'lastName') {
      formData.lastName = event.target.value;
      sethighlightlastname(false);
    } else if (event.target.id === 'mrNumber') {
      formData.mrNumber = event.target.value;
    } else if (event.target.id === 'ssn') {
      formData.ssn = event.target.value;
      sethighlightdate(false);
    } else if (event.target.id === 'email') {
      formData.email = event.target.value;
      sethighlightemail(false);
    } else if (event.target.id === 'addressLine1') {
      formData.addressLine1 = event.target.value;
    } else if (event.target.id === 'AddressLine2') {
      formData.addressLine2 = event.target.value;
    } else if (event.target.id === 'city') {
      formData.city = event.target.value;
    } else if (event.target.id === 'state') {
      formData.state = event.target.value;
    } else if (event.target.id === 'country') {
      formData.country = event.target.value;
    }
    setFormData({ ...formData });
  }
  useEffect(() => {
    const fetchDropdownData = async () => {
      const { header1 } = authorize();
      try {
        const response = await axios.get(`${baseURL}/dropdowns/getByDropdown?dropdown=gender`, { headers: header1 });
        if (response && response.data.message && response.data.message.code === 'MHC - 0200') {
          setDropdownData(response.data.data[0].list);
        } else {
          console.error('Error fetching dropdown data:', response.data.message.description);
        }
      } catch (error) {
        console.error('Error fetching dropdown data:', error);
      }
    };
    fetchDropdownData();
  }, []);

  const handleBedClick = (selectedBed: any) => {
    setBedSelected(selectedBed.roomNo + "-" + selectedBed.bedNo);
    const bedAssignId = selectedBed.id || " ";
    setBedId(bedAssignId);
    setBedClick(!bedClick)
  };

  const handleDioCancel = () => {
    setBedSelected('')
    setReason('')
    setAdmitDate(null)
    setDeviceId('')
    setBedAssignDialog(false);
    setAdmitD(false);
    setBedClick(false);
    setDateFieldError('');
    setError(null);
  }

  const closeModalAndRec = () => {
    setShow(false)
    setScanning(false)
    codeReader.reset()
  }
  const handleSave = async () => {
    if (!admitDate) {
      setDateFieldError('Date field cannot be empty.');
      return;
    }
    try {
      const formattedDate = formatDateToYYYYMMDD(admitDate);
      const requestBody = {
        bedId: bedId,
        pid: selectPatientId,
        assignedBy: userId,
        admitDate: formattedDate,
        admitReason: reason,
        deviceId: deviceId
      };
      const { header1 } = authorize();
      const response = await axios.post(
        `${baseURL}/Q15Bed/assign`,
        requestBody, { headers: header1 }
      );
      if (
        response.data.message &&
        response.data.message.code === successCode
      ) {
        getAllOrgPatient(dispatch, organization, currentPage, '');
        toast.success(response.data.message.description)
        handleDioCancel()
      } else {
        console.error("Error:", response.data.message);
      }
    } catch (error) {
      console.error("API Request Error:", error);
    }
  }
  const handleSaveChanges = () => {
    const isHighlighted = highlight();
    if (!isHighlighted) {
      if (!selectPatientId) {
        return;
      } else if (formData.postalCode.length > 0 && formData.city === "" && formData.state === "") {
        formData.city = "";
        formData.state = "";
        formData.country = "";
        alert("Please Enter Valid Zip Code");
        return;
      }
      const formatDateold = (dateString: any) => {
        const [year, month, day] = dateString.split('-');
        return `${year}${month}${day}`;
      };
      const updatedPatientFields = {
        id: selectPatientId,
        active: (admitDate === null) ? "0" : "1",
        basicDetails: [
          {
            name: [
              {
                use: formData.middleName,
                given: formData.firstName,
                family: formData.lastName,
              },
            ],
            ssn: formData.ssn,
            mrNumber: formData.mrNumber,
            gender: formData.gender,
            birthDate: formatDateold(formData.birthDate),
          },
        ],
        email: formData.email,
        organization,
        contact: [
          {
            address: [
              {
                addressLine1: formData.addressLine1,
                addressLine2: formData.addressLine2,
                city: formData.city,
                state: formData.state,
                postalCode: formData.postalCode,
                country: formData.country,
              },
            ],
          },
        ],
        beaconDevice: deviceId
      };
      dispatch(
        updatePatientDetails(
          selectPatientId,
          updatedPatientFields,
          organization,
          currentPage
        )
      );
      setEditModal(false);
    }
  };
  const bedsByNumber = patientAndBedAssign.reduce<{ [key: string]: any[] }>((acc, bedassign) => {
    const { roomNo, bedNo } = bedassign;

    if (!acc[roomNo]) {
      acc[roomNo] = [];
    }
    acc[roomNo].push(bedassign);
    return acc;
  }, {});
  // const bedsByNumber: { [key: string]: any[] } = {};
  // patientAndBedAssign.forEach((bedassign) => {
  //   let { bedNo } = bedassign;
  //   if (!bedsByNumber[bedNo]) {
  //     bedsByNumber[bedNo] = [];
  //   }
  //   bedsByNumber[bedNo].push(bedassign);
  // });
  const [selectedPatientName, setSelectedPatientName] = useState('');
  const formatDate = (dateString: any) => {
    const [year, month, day] = dateString.split('-');
    return `${month}-${day}-${year}`;
  };
  const formatDateForDisplay = (dateString: any) => {
    if (!dateString || dateString.length !== 8) return '';
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    return `${month}-${day}-${year}`;
  };
  const formatDateForInput = (dateString: any) => {
    if (!dateString || dateString.length !== 8) return '';
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    return `${year}-${month}-${day}`;
  };
  const handleClick = (selectedPatient: any) => {
    if (selectedPatient) {
      const fullName = `${selectedPatient.basicDetails[0].name[0].given || ''} ${selectedPatient.basicDetails[0].name[0].family || ''}`;
      setSelectedPatientName(fullName);
      const patientId = selectedPatient.id || "";
      setSelectPatientId(patientId);
      setDeviceId(selectedPatient.beaconDevice);
      const basicDetails = selectedPatient.basicDetails[0];
      const address = selectedPatient.contact[0]?.address[0];
      setFormData({
        firstName: basicDetails.name[0]?.given || "",
        middleName: basicDetails.name[0]?.use || "",
        lastName: basicDetails.name[0]?.family || "",
        birthDate: formatDateForInput(basicDetails.birthDate) || "",
        ssn: basicDetails.ssn || "",
        addressLine1: address?.addressLine1 || "",
        addressLine2: address?.addressLine2 || "",
        city: address?.city || "",
        state: address?.state || "",
        postalCode: address?.postalCode || "",
        mrNumber: basicDetails.mrNumber || "",
        email: selectedPatient.email || "",
        beaconDevice1: selectedPatient.beaconDevice1 || [],
        gender: basicDetails.gender || "",
        country: address?.country || "",
      });
      setEditModal(true);
    } else {
      console.error("Invalid patient data:", selectedPatient);
    }
  };

  return (
    <div className="container m5 p3" style={{ width: '90%', paddingTop: "10px" }}>
      {loading && <Loader />}
      <div className="row" style={{ position: "relative" }}>
        <div className="col-md-8 d-flex align-items-center">
          <h5>All Patient List</h5>
        </div>
        <div className="col-md-4 d-flex justify-content-end align-items-center gap-2 mb-2">
          <div style={{ backgroundColor: primarybg, borderRadius: "4px", cursor: "pointer", fontSize: '30px', color: 'white', display: "flex", justifyContent: "center", alignItems: "center", width: "35px", height: "35px" }}>
            <i style={{ fontSize: "34px", fontWeight: "lighter" }} onClick={toggle} className="material-icons">add</i>
          </div>
          <div className="mx-0 search-container d-flex align-items-center">
            <input
              type="text"
              placeholder="Search..."
              className="search form-control"
              onChange={(e) => setSearch(e.target.value)}
            />
            <FaSearch className="search-icon mt-1" />
          </div>
        </div>
        <table className="table table-bordered" style={{ fontSize: '13px' }}>
          <thead style={{ backgroundColor: grey }}>
            <tr>
              <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey, width: '13px' }}>S.No</th>
              <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Patient Name</th>
              <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>SSN</th>
              <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Date of Birth</th>
              <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey, width: '13px' }}>Status</th>
              <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey, width: '10%' }}>Action</th>
            </tr>
          </thead>
          <tbody>
            { allOrgData.length > 0 ?
              allOrgData?.map((patient: any, index: number) => (
                <tr key={index}>
                  <td className="text text-center">{currentPage * 10 + index + 1}</td>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleClick(patient)
                    }
                    className="text"
                  >
                    {patient.basicDetails[0].name[0].given}{" "}
                    {patient.basicDetails[0].name[0].family}
                  </td>
                  <td className="text">{'***' + patient.basicDetails[0].ssn.slice(-4)}</td>
                  <td className="text">{(patient.basicDetails[0].birthDate)}</td>
                  <td className="text text-center">
                    <Tooltip title={(patient.active == 0) ? "Not Active" : "Active"} arrow>
                      <FontAwesomeIcon
                        icon={faCircle}
                        style={{
                          fontSize: "13px",
                          cursor: "pointer",
                          color: (patient.active == 0) ? '#5FB477' : '#EF6868',
                          display: "inline-block",
                          textAlign: "center",
                        }}
                      />
                    </Tooltip>
                  </td>
                  <td className="text d-flex justify-content-around align-items-center">
                    <Tooltip title="Edit" arrow>
                      <FontAwesomeIcon
                        icon={faPencil}
                        className="text-primary"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleClick(patient)
                        }
                      />
                    </Tooltip>
                    {(patient.active == 0) &&
                      <Tooltip title="Admit " arrow>
                        <img
                          src={dischargeIcon}
                          alt="Discharge Icon"
                          className="text-danger"
                          style={{ cursor: "pointer", width: '18px', height: '20px' }}
                          onClick={() => {
                            setBedAssignDialog(true);
                            setSelectPatientId(patient.id);
                            setSelectedPatientName(`${patient.basicDetails[0].name[0].given || ''} ${patient.basicDetails[0].name[0].family || ''}`)
                          }}
                        />
                      </Tooltip>
                    }
                  </td>
                </tr>
              )) : 
              <tr>
                <td colSpan={7} className="text-center">
                  <h5>No Patients found</h5>
                </td>
              </tr>
              }
          </tbody>
        </table>
      </div>
      {allOrgData?.length === 0 && (
        <div className="no-records row ">
          <div className="col-md-9 text-center">
            <p>No Records Found</p>
          </div>
          <div className="col-md-3">
            <Button label="Register New Patient" style={{ backgroundColor: '#0f3995', fontSize: '14px', fontWeight: 'bold' }} onClick={toggle}></Button>
          </div>
        </div>
      )}
      <PatientCreation modal={modal} toggle={toggle} currentPage={currentPage} />
      <div className="pagination-container" >
        <div className="d-flex justify-content-center">
          <Paginator
            first={currentPage * 10}
            rows={10}
            totalRecords={totalElements}
            onPageChange={(e: any) => {
              setCurrentPage(e.page)
              getAllOrgPatient(dispatch, organization, e.page, search ? search : '')
            }}
            currentPageReportTemplate={`Page ${currentPage} of ${totalElements / 10}`}
          />
        </div>
      </div>
      <Modal
        isOpen={editModal}
        // toggle={() => setEditModal(false)}
        centered
        size="lg"
      ><div className="d-flex align-items-center justify-content-center vh-90">
          <div className="row">
            <div className="container col-md-12">
              <ModalHeader toggle={() => setEditModal(false)}>{selectedPatientName}</ModalHeader>
              <ModalBody style={{ maxHeight: '65vh', overflowY: 'auto' }}>
                <div className="row w-100 ">
                  <div className="col-md-4 mb-2"  >
                    <TextField
                      error={highlightfirstname}
                      id="firstName"
                      name="firstName"
                      label="First Names"
                      placeholder="Enter First Name"
                      value={formData.firstName}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                  <div className="col-md-4 mb-2">
                    <TextField
                      id="middleName"
                      name="middleName"
                      label="Middle Name"
                      placeholder="Enter Middle Name"
                      value={formData.middleName}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                  <div className="col-md-4 mb-2">
                    <TextField
                      error={highlightlastname}
                      id="lastName"
                      name="lastName"
                      label="Last Name"
                      placeholder="Enter LastName"
                      value={formData.lastName}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                </div>
                <div className="row w-100">
                  <div className="col-md-4 mt-3">
                    <Autocomplete
                      id='gender'
                      options={dropdownData.map((list: any) => list.value)}
                      value={formData.gender}
                      onChange={(e, v) => { formData.gender = v; setFormData({ ...formData }); }}
                      renderInput={(params) => (
                        <TextField {...params} label='Gender' variant="outlined" />
                      )}
                    />
                  </div>
                  <div className="col-md-4 mb-2">
                    <TextField
                      type="date"
                      sx={{
                        border: highlightdate ? "1px solid red" : "",
                        borderRadius: "5px"
                      }}
                      id="birthDate"
                      name="birthDate"
                      label="Date Of Birth"
                      placeholder="Enter DateOfBirth"
                      value={formData.birthDate}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                  <div className="col-md-4 mb-2">
                    <TextField
                      id="mrNumber"
                      name="mrNumber"
                      label="MrNumber"
                      placeholder="Enter Phone Number"
                      value={formData.mrNumber}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                </div>
                <div className="row w-100">
                  <div className="col-md-6 mb-2">
                    <TextField
                      error={highlightemail}
                      id="email"
                      name="email"
                      label="Email"
                      placeholder="Enter Email"
                      value={formData.email}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                  <div className="col-md-6 mb-2">
                    <TextField
                      error={highlightssn}
                      id="ssn"
                      name="ssn"
                      label="SSN"
                      placeholder="Enter SSN"
                      value={formData.ssn}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                </div>
                <div className="row w-100">
                  <div className="col-md-4 mb-2">
                    <TextField
                      id="addressLine1"
                      name="addressLine1"
                      label="Address Line 1"
                      placeholder="Enter Address"
                      value={formData.addressLine1}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                  <div className="col-md-4 mb-2">
                    <TextField
                      id="addressLine2"
                      name="addressLine2"
                      label="Address Line 2"
                      placeholder="Enter Address"
                      value={formData.addressLine2}
                      onChange={handleAddress}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                  <div className="col-md-4 mt-3">
                    <TextField
                      error={highlightzipcode}
                      id="zipcode"
                      label="Zip/Postal Code"
                      variant="outlined"
                      fullWidth
                      value={formData.postalCode}
                      onChange={handlePostalCodeChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        maxLength: 5,
                        pattern: "\\d{5}",
                      }}
                    />
                    {loading1 && <CircularProgress size={'25px'} />}
                  </div>
                </div>
                <div className="row w-100">
                  <div className="col-md-4 mt-3">
                    {cityDropDown !== null && cityDropDown.length === 1 ?
                      <TextField
                        id="city"
                        label="City"
                        variant="outlined"
                        fullWidth
                        value={formData.city}
                        onChange={handleinputchange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      /> :
                      <Autocomplete
                        id="city"
                        options={cityDropDown}
                        value={formData.city}
                        getOptionLabel={(option) => option}
                        onChange={(e, v) => { formData.city = v; setFormData({ ...formData }); }}
                        sx={{ width: "100%" }}
                        size="medium"
                        renderInput={params =>
                          <TextField
                            name=""
                            {...params}
                            variant="outlined"
                            label="City"
                            placeholder=""
                            margin="none"
                            size="medium"
                            fullWidth
                          />
                        }
                      />}
                  </div>
                  <div className="col-md-4 mt-3">
                    <TextField
                      id="state"
                      label="State"
                      variant="outlined"
                      fullWidth
                      value={formData.state}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <TextField
                      id="country"
                      name="country"
                      label="Country"
                      placeholder="Enter Country"
                      value={formData.country}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                </div>
              </ModalBody>
            </div>
          </div>
        </div>
        <ModalFooter className="">
          <div className="d-flex gap-3 justify-content-center">
            <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={() => setEditModal(false)}></Button>
            <Button label="Save Changes" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={handleSaveChanges}></Button>
          </div>
        </ModalFooter>
      </Modal>
      <Dialog maxWidth={'md'} PaperProps={{ sx: { width: '72%', maxWidth: '72%', position: 'absolute', height: '500px', top: '40px', overflow: 'hidden' } }}
        open={bedAssignDialog}
        onClose={() => handleDioCancel()}
      >
        <div style={{ display: "flex", justifyContent: "space-between", padding: "10px 10px", alignItems: "center", gap: "10px", top: 0 }}>
          <div style={{ fontWeight: 600 }}>
            <h5>Admit</h5>
          </div>
          <div>
            <button type="button" className="btn-close" aria-label="Close" onClick={handleDioCancel}></button>
          </div>
        </div>

        <DialogContentText >
          <DialogContent style={{ padding: '20px', background: '#F8FAFB', overflowY: 'auto', height: '400px' }}>
            <div>
              <>
                <div className="row w-100 mb-3">
                  <div className="col-md-12">
                    <TextField id="Reason" label="Patient Name" variant="outlined" value={selectedPatientName} fullWidth />
                  </div>
                </div>
                <div className="row w-100 ">
                  <div className='col-md-6 mb-2' >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Box >
                        <DatePicker
                          sx={{ width: "100%" }}
                          defaultValue={dayjs('2022-07-17')}
                          onError={(newError) => setError(newError)}
                          label={'Admit Date'}
                          value={admitDate}
                          onChange={(newValue) => {
                            setAdmitDate(newValue);
                            if (dateFieldError) setDateFieldError('')
                          }}
                          slotProps={{
                            textField: {
                              helperText: errorMessage,
                              error: Boolean(dateFieldError || error),
                            },
                          }}
                        />
                      </Box>
                    </LocalizationProvider>
                  </div>
                  <div className='col-md-6 mb-2'>
                    <TextField id="Reason" label="Admit Reason" variant="outlined" value={reason} fullWidth onChange={(e) => setReason(e.target.value)} />
                  </div>
                </div>
                <div className="row w-100 ">
                  <div className='col-md-6 mb-2' style={{ textAlign: 'left' }}>{"Device-Id"}</div>
                  <div className='col-md-3 mb-2' ></div>
                  <div className='col-md-3 mb-2' ></div>
                </div>
                <div className="row w-100">
                  <div className='col-md-6 mb-2'>
                    <List style={{ paddingLeft: '0px' }}>
                      <ListItem disableGutters style={{ paddingTop: '0px' }}>
                        <Select
                          id="device-select"
                          value={deviceId}
                          onChange={(event) => setDeviceId(event.target.value)}
                          fullWidth
                          IconComponent={() => (
                            <FaQrcode
                              className="position-absolute bottom-0 m-3" onClick={handleQrClick}
                              role='button'
                              style={{ fontSize: '1.2rem', color: '#000', right: '0', cursor: 'pointer' }}
                            />
                          )}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: '300px',
                              },
                            },
                          }}
                        >
                          {options?.map((option: any) => (
                            <MenuItem key={option.id} value={option.deviceId}>
                              {option.deviceId}
                            </MenuItem>
                          ))}
                        </Select>
                      </ListItem>
                    </List>
                  </div>
                  <div className="col-md-6 mb-2" style={{ textAlign: 'end' }}>
                    <TextField
                      label="Room-Bed"
                      type="text"
                      placeholder=''
                      margin="none"
                      fullWidth
                      value={bedSelected ? bedSelected : ""}
                      disabled={true}
                      InputProps={{
                        endAdornment: (
                          <IconButton onClick={() => {
                            if (admitD === false) {
                              setAdmitD(true);
                            } else {
                              setAdmitD(false);
                            }
                          }} edge="end">
                            <Bed />
                          </IconButton>
                        )
                      }}
                    />
                  </div>
                </div>
              </>
              { admitD && <> <PatientBed admitD={admitD} bedId={bedId} handleBedClick={handleBedClick} bedsByNumber={bedsByNumber} /> </>}
            </div>
          </DialogContent>
        </DialogContentText>
        <div className="d-flex gap-3 pt-2 mb-3 justify-content-center">
          <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }}
            onClick={handleDioCancel}></Button>
          <Button label="Save Changes" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={handleSave}></Button>
        </div>
      </Dialog>
      <Dialog open={show} onClose={handleClose1}>
        <DialogTitle>Scanning</DialogTitle>
        <DialogContent>
          <video ref={videoRef} style={{ display: scanning ? "block" : "none", width: '100%', height: '400px' }} />
        </DialogContent>
        <div className="d-flex gap-3 justify-content-center">
          <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={handleClose1}></Button>
          <Button label={scanning ? "Stop Scanning" : "Start Scanning"} style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={closeModalAndRec}></Button>
        </div>
      </Dialog>
    </div>
  );
};

export default Patient;