import React, { useEffect, useState } from "react";
import Calendar from "../../components/calendar";
import './../organizationDetails/form.css'
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import axios from 'axios';
import {
    Modal, ModalFooter, ModalBody, ModalHeader
} from "reactstrap";
import { getOrgByID } from "../../slices/organization/thunk";
import { saveOrganization } from "../../slices/login/reducer";
import { HttpLogin } from "../../utils/Http";
import { Button } from "primereact/button";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { baseURL, successCode } from "../../configuration/url";
import calendarMuiImage from '../../assets/images/calendarMuiImage.svg';
import { authorize } from "../../helpers/common";
import { getAllPatient, getAllStaff } from "../../slices/thunk";
import { Autocomplete, MenuItem, Select, TextField, Tooltip, Menu, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { FaSearch } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import localStorage from "redux-persist/es/storage";
import { grey, primarybg, primarytext, secondarybg } from "../../common/primary";

type Note = {
    content: string;
    timestamp: string;
};

type ShiftRecord = {
    staffId: string;
    startTime: string;
    endTime: string;
    note: Note[];
};

type RecordStringFields = 'startTime' | 'endTime';

type ShiftName = 'Shift-A' | 'Shift-B' | 'Shift-C';

type ShiftsType = {
    "Shift-A": { start: string; end: string };
    "Shift-B": { start: string; end: string };
    "Shift-C": { start: string; end: string };
};
const OneToOne = () => {
    const getCurrentFormattedDate = () => {
        const currentDate = new Date();
        const options: Intl.DateTimeFormatOptions = { month: 'short', day: '2-digit', year: 'numeric' };
        return currentDate.toLocaleDateString('en-US', options);
    };
    const dispatch = useDispatch<any>()

    const { organization } = useSelector((state: any) => state.Login)
    const [selectedDate, setSelectedDate] = useState(new Date());

    const [date, setDate] = useState(getCurrentFormattedDate)
    const [searchTerm, setSearchTerm] = useState('');
    const { staffData } = useSelector((state: any) => state.Staff);
    const [currentPage, setCurrentPage] = useState(0);
    const [saved, setSaved] = useState(false);
    const formatDate = (date: any) => {
        const options = { day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };
    const { patientData } = useSelector((state: any) => state.Patient);

    let [Patientname, setPatientname] = useState(new Array<any>());
    let [addNewPatient, setAddNewPatient] = useState(new Array<any>());
    const shiftsTime: ShiftsType = {
        "Shift-A": { start: "06:00", end: "14:00" },
        "Shift-B": { start: "14:00", end: "22:00" },
        "Shift-C": { start: "22:00", end: "06:00" } // Shift C ends at 6am next day
    };
    const timeToDate = (time: string, baseDate: Date): Date => {
        const [hours, minutes] = time.split(':').map(Number);
        const date = new Date(baseDate);
        date.setHours(hours, minutes, 0, 0);
        return date;
    };

    const isValidShiftTime = (time: string, shift: ShiftName, baseDate: Date): boolean => {
        // Helper function to convert a time string to a Date object based on baseDate
        const timeToDate = (time: string, baseDate: Date): Date => {
            const [hours, minutes] = time.split(':').map(Number);
            const date = new Date(baseDate);
            date.setHours(hours, minutes, 0, 0);
            return date;
        };

        const [hours, minutes] = time.split(':').map(Number);
        const totalDate = timeToDate(time, baseDate);
        const { start, end } = shiftsTime[shift];
        const startDate = timeToDate(start, baseDate);
        let endDate = timeToDate(end, baseDate);

        if (shift === 'Shift-C') {
            // For Shift C that ends after midnight, add 1 day to endDate
            if (endDate <= startDate) {
                endDate.setDate(endDate.getDate() + 1);
            }
        }

        // If totalDate is less than startDate, it means it’s in the next day for shifts that cross midnight
        if (totalDate < startDate) {
            totalDate.setDate(totalDate.getDate() + 1);
        }

        return totalDate >= startDate && totalDate < endDate;
    };
    const [selectedTab, setSelectedTab] = useState<ShiftName>('Shift-A');

    const handleDate = (date: any) => {
        setSelectedDate(date)
        const options: Intl.DateTimeFormatOptions = { month: 'short', day: '2-digit', year: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        setDate(formattedDate)
    }
    const renderDateBoxes = () => {
        const dateBoxes = [];
        for (let i = 0; i < 7; i++) {
            const currentDate = new Date(selectedDate);
            currentDate.setDate(selectedDate.getDate() + i);
            const date = selectedDate.getDate().toString().padStart(2, '0');
            const year = selectedDate.getFullYear();
            const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
            window.localStorage.setItem("getByDate", `${year}${month}${date}`)
            dateBoxes.push(
                <Calendar
                    key={i}
                    day={currentDate.toLocaleDateString('en-US', { weekday: 'short' })}
                    date={formatDate(currentDate)}
                    onClick={() => handleDate(currentDate)}
                    isSelected={selectedDate?.toDateString() === currentDate.toDateString()}
                />
            );
        }
        return dateBoxes;
    };
    const initFormData = {
        date: "",
        endTime: "",
        id: "",
        oneToOneObserver: "",
        oneToOneObserverName: "",
        organization: "",
        patientId: "",
        patientName: "",
        patientIncharge: "",
        patientInchargeName: "",
        prescribedby: "",
        prescribedbyName: "",
        startTime: ""
    }

    const [Addpatient, setAddpatient] = useState(initFormData);

    function addHours(time: any, hours: any) {
        const baseTime = new Date(`2000-01-01T${time}`);
        const newTime = new Date(baseTime.getTime() + hours * 60 * 60 * 1000);
        return `${newTime.getHours().toString().padStart(2, '0')}:${newTime.getMinutes().toString().padStart(2, '0')}`;
    }

    const date1 = selectedDate.getDate().toString().padStart(2, '0');
    const year = selectedDate.getFullYear();
    const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
    const forDate = `${year}${month}${date1}`
    localStorage.setItem("Date", forDate);
    useEffect(() => {
        saveOrganization(dispatch)
        getAllPatient(dispatch, organization, -1, '')
        getOrgByID(dispatch, organization)
    }, [dispatch, selectedDate])
    const [editModal1, setEditModal1] = useState(false);
    const [editModalview, setEditModalview] = useState(false);
    const [shifts, setShifts] = useState([
        {
            date: "",
            id: "",
            organization: "",
            pid: "",
            shift: [
                {
                    shiftName: "",
                    shiftTime: "",
                    shiftIncharge: "",
                    record: [
                        {
                            distance: "",
                            endTime: "",
                            note: [
                                {
                                    note: "",
                                    time: ""
                                }
                            ],
                            staffId: "",
                            staffName: "",
                            startTime: ""
                        }
                    ]
                }
            ]
        }
    ]);

    const addRecord = () => {
        setShifts(prevShifts => {
            const newRecord = {
                distance: "",
                endTime: "",
                note: [
                    {
                        note: "",
                        time: ""
                    }
                ],
                staffId: "",
                staffName: "",
                startTime: "",
            };
            const updatedShifts = [...prevShifts];
            updatedShifts[0].shift[0].record.push(newRecord);
            return updatedShifts;
        });
    };
    const [formValues, setFormValues] = useState({
        date: "",
        endTime: "",
        id: "",
        oneToOneObserver: "",
        organization: "",
        patientId: "",
        patientName: "",
        patientIncharge: "",
        patientInchargeName: '',
        prescribedby: "",
        prescribedbyName: "",
        startTime: ""
    });

    const [confirmDelete, setConfirmDelete] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState<number | null>(null);


    const confirmDeleteRecord = (index: number) => {
        setDeleteIndex(index);
        setConfirmDelete(true);
    };

    const removeRecord = async (index: any) => {

        if (deleteIndex !== null) {
            const url = `${baseURL}/oneToOne/deleteStaff?date=${forDate}&endTime=${shifts[0].shift[0].record[index].endTime}&organization=${organization}&pid=${formValues.patientId}&staffId=${shifts[0].shift[0].record[index].staffId}&startTime=${shifts[0].shift[0].record[index].startTime}`;

            await axios.delete(url)
                .then(response => {
                    setShifts(prevShifts => {
                        const updatedShifts = [...prevShifts];
                        updatedShifts[0].shift[0].record.splice(deleteIndex, 1);
                        return updatedShifts;
                    });
                })
                .catch(error => {
                    console.error('There was an error deleting the record:', error);
                });
            setConfirmDelete(false);
            setDeleteIndex(null);
        }
    };
    const cancelDelete = () => {
        setConfirmDelete(false);
        setDeleteIndex(null);
    };

    const [selectedPatient, setSelectedPatient] = useState(null);
    const editChange = (patient: any) => {
        const { header1 } = authorize();
        const date = selectedDate.getDate().toString().padStart(2, '0');
        const year = selectedDate.getFullYear();
        const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
        if (patient) {
            setSelectedPatient(patient);
            setFormValues({
                date: patient.date || '',
                endTime: patient.endTime || '',
                id: patient.id || '',
                oneToOneObserver: patient.oneToOneObserver || '',
                organization: patient.organization || '',
                patientId: patient.patientId || '',
                patientName: patient.patientName || '',
                patientIncharge: patient.shiftIncharge || '',
                patientInchargeName: patient.patientIncharge || '',
                prescribedby: patient.staffId || '',
                prescribedbyName: patient.staffName || '',
                startTime: patient.startTime || ''
            });
            setEditModal1(true);
            HttpLogin.axios().get(`${baseURL}/oneToOne/oneToOnePatientList?date=${year}${month}${date}&organization=${organization}&shiftName=${selectedTab}`, { headers: header1 })
                .then((response) => {
                    if (response.data.message.code === successCode) {
                        setPatientDate(response.data.data)
                    } else {
                        setPatientDate([""]);
                    }
                })

            HttpLogin.axios().get(`${baseURL}/oneToOne/oneToOneByPidAndDateAndOrganizationAndShift?date=${forDate}&organization=${organization}&patientId=${patient.patientId}&shiftName=${selectedTab}`, { headers: header1 })
                .then((response) => {
                    if (response.data.message.code === successCode) {
                        const responseData = response.data.data;
                        const transformedData = [
                            {
                                date: forDate,
                                id: patient.id,
                                organization: organization,
                                pid: patient.patientId,
                                shift: [
                                    {
                                        shiftName: responseData.shiftName,
                                        shiftTime: responseData.shiftTime,
                                        shiftIncharge: responseData.shiftIncharge,
                                        record: responseData.record
                                    }
                                ]
                            }
                        ];
                        setShifts(transformedData);
                    } else {
                        setShifts([
                            {
                                date: "",
                                id: "",
                                organization: "",
                                pid: "",
                                shift: [{
                                    shiftName: "",
                                    shiftTime: "",
                                    shiftIncharge: "",
                                    record: [
                                        {
                                            distance: "",
                                            endTime: "",
                                            note: [{ note: "", time: "" }],
                                            staffId: "",
                                            staffName: "",
                                            startTime: "",

                                        }
                                    ]
                                }]
                            }
                        ]);
                    }
                });
        } else {
            console.error("Invalid patient data:", patient);
        }
    };
    const [selectedPatientView, setSelectedPatientview] = useState<any>(null);
    const Viewstaff = (patient: any) => {
        const { header1 } = authorize();
        const date = selectedDate.getDate().toString().padStart(2, '0');
        const year = selectedDate.getFullYear();
        const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
        if (patient) {
            setSelectedPatientview(patient);
            setFormValues({
                date: patient.date || '',
                endTime: patient.endTime || '',
                id: patient.id || '',
                oneToOneObserver: patient.oneToOneObserver || '',
                organization: patient.organization || '',
                patientId: patient.patientId || '',
                patientName: patient.patientName || '',
                patientIncharge: patient.shiftIncharge || '',
                patientInchargeName: patient.patientIncharge || '',

                prescribedby: patient.staffId || '',
                prescribedbyName: patient.staffName || '',
                startTime: patient.startTime || ''
            });
            setEditModalview(true);
            HttpLogin.axios().get(`${baseURL}/oneToOne/oneToOnePatientList?date=${year}${month}${date}&organization=${organization}&shiftName=${selectedTab}`, { headers: header1 })
                .then((response) => {
                    if (response.data.message.code === successCode) {
                        setPatientDate(response.data.data)
                    } else {
                        setPatientDate([""]);
                    }
                })
            HttpLogin.axios().get(`${baseURL}/oneToOne/oneToOneByPidAndDateAndOrganizationAndShift?date=${year}${month}${date}&organization=${organization}&patientId=${patient?.patientId}&shiftName=${selectedTab}`, { headers: header1 })
                .then((response) => {
                    if (response.data.message.code === successCode) {
                        const responseData = response.data.data;
                        const transformedData = [
                            {
                                date: forDate,
                                id: patient?.id,
                                organization: organization,
                                pid: patient?.patientId,
                                shift: [
                                    {
                                        shiftName: responseData.shiftName,
                                        shiftTime: responseData.shiftTime,
                                        shiftIncharge: responseData.shiftIncharge,
                                        record: responseData.record
                                    }
                                ]
                            }
                        ];
                        setShifts(transformedData);
                    } else {
                        setShifts([
                            {
                                date: "",
                                id: "",
                                organization: "",
                                pid: "",
                                shift: [{
                                    shiftName: "",
                                    shiftTime: "",
                                    shiftIncharge: "",
                                    record: [
                                        {
                                            distance: "",
                                            endTime: "",
                                            note: [{ note: "", time: "" }],
                                            staffId: "",
                                            staffName: "",
                                            startTime: "",

                                        }
                                    ]
                                }]
                            }
                        ]);
                    }
                });
        } else {
            console.error("Invalid patient data:", patient);
        }
    };
    useEffect(() => {
        setAddNewPatient(patientData !== null && patientData !== undefined && patientData);
        setPatientname(patientData !== null && patientData !== undefined && patientData.map((k: any) => { return k.basicDetails[0].name[0].given + " " + k.basicDetails[0].name[0].family }))
    }, [patientData])

    useEffect(() => {
        saveOrganization(dispatch)
        getAllStaff(dispatch, organization, -1, searchTerm ? searchTerm : '');
        getOrgByID(dispatch, organization)
    }, [dispatch, selectedDate, searchTerm])

    const handleRecordChange = (e: any, recordIndex: any) => {
        const { value } = e.target;
        setShifts(prevShifts => {
            const updatedShifts = [...prevShifts];
            updatedShifts[0].shift[0].record[recordIndex].staffId = value;
            return updatedShifts;
        });
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event: any, patient: any) => {
        setAnchorEl(event.currentTarget);
        setSelectedPatient(patient);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        recordIndex: number,
        field: RecordStringFields
    ) => {
        const value = e.target.value;
        const updatedShifts = [...shifts];
        updatedShifts[0].shift[0].record[recordIndex][field] = value;
        setShifts(updatedShifts);
    };

    const [modal, setModal] = useState(false);
    const toggle = () => {
        setModal(!modal);
        setErrors({});
        Addpatient.patientId = "";
        Addpatient.oneToOneObserver = "";
        Addpatient.patientName = "";
        Addpatient.prescribedbyName = "";
        Addpatient.patientIncharge = "";
        Addpatient.prescribedby = "";
        Addpatient.oneToOneObserverName = "";
        Addpatient.patientInchargeName = "";
        Addpatient.startTime = "";
        Addpatient.endTime = "";
    }

    const handleInputChangeadd = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = event.target;
        setAddpatient({ ...Addpatient, [id]: value });
        setErrors({ ...errors, [id]: !value });
    };

    const handleAutocompleteChange = (id: string, value: any, name: string) => {
        setAddpatient({
            ...Addpatient,
            [id]: value ? value.id : null,
            [name]: value ? `${value.name[0].given} ${value.name[0].family}` : ''
        });
        setErrors({ ...errors, [id]: !value });
    };

    // window.localStorage.setItem("PatientId", formValues.patientName);
    // window.localStorage.setItem("PatientIds", formValues.patientId);
    const [Patientnamess, setPatientNamess] = useState(new Array<any>());
    useEffect(() => {
        HttpLogin.axios().get("/api/patient/get/activePatientMob/" + organization)
            .then((newInData: any) => {
                if (newInData.data.message.code === successCode) {
                    setPatientNamess(newInData.data.data || []);
                } else {
                    setPatientNamess([]);
                }
            })
    }, []);
    const [Patientnames, setPatientNames] = useState(new Array<any>());
    useEffect(() => {
        HttpLogin.axios().get("/api/patient/get/activePatientMob/" + organization)
            .then((newInData: any) => {
                if (newInData.data.message.code === successCode) {
                    setPatientNames(newInData.data.data || []);
                } else {
                    setPatientNames([]);
                }
            })
    }, []);
    const [Staff, setStaff] = useState(new Array<any>());
    useEffect(() => {
        axios.get(`${baseURL}/staff/get/ActiveStaff`)
            .then((newInData) => {
                if (newInData.data.message.code === "MHC - 0200") {
                    setStaff(newInData.data.data || []);
                } else {
                    setStaff([]);
                }
            })
            .catch(error => {
                console.error("Error fetching staff data:", error);
                setStaff([]);
            });
    }, []);

    let [PatientDate, setPatientDate] = useState<any[]>([{
        date: "",
        endTime: "",
        id: "",
        oneToOneObserver: "",
        organization: "",
        patientId: "",
        patientName: "",
        patientIncharge: "",
        patientInchargeName: '',
        prescribedby: "",
        prescribedbyName: "",
        startTime: ""
    }])

    const fetchPatientData = async () => {
        const date = selectedDate.getDate().toString().padStart(2, '0');
        const year = selectedDate.getFullYear();
        const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
        const { header1 } = authorize();
        HttpLogin.axios().get(`${baseURL}/oneToOne/oneToOnePatientList?date=${year}${month}${date}&organization=${organization}&shiftName=${selectedTab}`, { headers: header1 })
            .then((response) => {
                if (response.data.message.code === successCode) {
                    setPatientDate(response.data.data)
                } else {
                    setPatientDate([""]);
                }
            })
    }
    useEffect(() => {
        fetchPatientData();
    }, []);
    const handleTabClick = (tabId: any) => {
        setSelectedTab(tabId);
        setSelectedPatient(tabId);
        setFormValues({
            date: tabId.date || '',
            endTime: tabId.endTime || '',
            id: tabId.id || '',
            oneToOneObserver: tabId.oneToOneObserver || '',
            organization: tabId.organization || '',
            patientId: tabId.patientId || '',
            patientName: tabId.patientName || '',
            patientIncharge: tabId.patientIncharge || '',
            patientInchargeName: tabId.patientIncharge || '',
            prescribedby: tabId.staffId || '',
            prescribedbyName: tabId.staffName || '',
            startTime: tabId.startTime || ''
        });
        const date = selectedDate.getDate().toString().padStart(2, '0');
        const year = selectedDate.getFullYear();
        const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
        const { header1 } = authorize();
        HttpLogin.axios().get(`${baseURL}/oneToOne/oneToOnePatientList?date=${year}${month}${date}&organization=${organization}&shiftName=${tabId}`, { headers: header1 })
            .then((response) => {
                if (response.data.message.code === successCode) {
                    setPatientDate(response.data.data)
                } else {
                    setPatientDate([]);
                }
            })
        HttpLogin.axios().get(`${baseURL}/oneToOne/oneToOneByPidAndDateAndOrganizationAndShift?date=${year}${month}${date}&organization=${organization}&patientId=${tabId?.patientId}&shiftName=${tabId}`, { headers: header1 })
            .then((response) => {
                if (response.data.message.code === successCode) {
                    const responseData = response.data.data;
                    const transformedData = [
                        {
                            date: forDate,
                            id: tabId?.id,
                            organization: organization,
                            pid: tabId?.patientId,
                            shift: [
                                {
                                    shiftName: responseData.shiftName,
                                    shiftTime: responseData.shiftTime,
                                    shiftIncharge: responseData.shiftIncharge,
                                    record: responseData.record
                                }
                            ]
                        }
                    ];
                    setShifts(transformedData);
                } else {
                    setShifts([
                        {
                            date: "",
                            id: "",
                            organization: "",
                            pid: "",
                            shift: [{
                                shiftName: "",
                                shiftTime: "",
                                shiftIncharge: "",
                                record: [
                                    {
                                        distance: "",
                                        endTime: "",
                                        note: [{ note: "", time: "" }],
                                        staffId: "",
                                        staffName: "",
                                        startTime: "",

                                    }
                                ]
                            }]
                        }
                    ]);
                }
            });
    }
    const fetchPatientDataByDate = async (date: any, tab: any) => {
        const { header1 } = authorize();
        try {
            const response = await axios.get(`${baseURL}/oneToOne/oneToOnePatientList?date=${date}&organization=${organization}&shiftName=${tab}`, { headers: header1 });
            if (response.data.message.code === successCode) {
                setPatientDate(response.data.data);
            } else {
                setPatientDate([]);
                console.error('No Record:');
            }
        } catch (error) {
            setPatientDate([]);
            console.error('No Record:');
        }
    };

    const handleDateChange = (event: any) => {
        const newDate = new Date(event.target.value);
        const options: Intl.DateTimeFormatOptions = { month: 'short', day: '2-digit', year: 'numeric' };
        const formattedDate = newDate.toLocaleDateString('en-US', options);
        setDate(formattedDate);
        setSelectedDate(newDate);
    };

    useEffect(() => {
        const date = selectedDate.getDate().toString().padStart(2, '0');
        const year = selectedDate.getFullYear();
        const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
        const currentDate = `${year}${month}${date}`;
        fetchPatientDataByDate(currentDate, selectedTab);
    }, [selectedDate, selectedTab]);
    const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
    const savePatients = async (patient: any) => {
        const { header1 } = authorize();
        try {
            let newErrors = {
                patientIncharge: !formValues.patientIncharge,
            };
            setErrors(newErrors);
            const hasErrors = Object.values(newErrors).some(error => error);
            if (hasErrors) {
                return;
            }
            let bodyData = {
                date: forDate,
                id: "",
                organization,
                pid: formValues.patientId,
                shift: shifts?.map((k) => ({
                    record: k.shift[0].record?.map((l: any) => ({
                        distance: "",
                        endTime: l.endTime,
                        note: [{ note: "", time: "" }],
                        staffId: l.staffId,
                        startTime: l.startTime,
                    })),
                    shiftIncharge: formValues.patientIncharge,
                    shiftName: "",
                    shiftTime: k.shift[0].shiftTime,
                })),
            };

            switch (selectedTab) {
                case "Shift-A":
                    bodyData.shift.forEach(shift => shift.shiftName = "Shift-A");
                    break;
                case "Shift-B":
                    bodyData.shift.forEach(shift => shift.shiftName = "Shift-B");
                    break;
                case "Shift-C":
                default:
                    bodyData.shift.forEach(shift => shift.shiftName = "Shift-C");
                    break;
            }
            const response = await axios.post(`${baseURL}/oneToOne/register`, bodyData, { headers: header1 });
            if (response.data.message.code === successCode) {
                setSaved(true);
                toast.success(response.data.message.description)
                editChange(patient);
                setEditModal1(false);
            } else {
                toast.error("Login failed: " + response.data.message.description);
            }

        } catch (error) {
            toast.error("An error occurred during register.");
        }
    }
    const handleSaveClick = async (event: any) => {
        event.preventDefault();
        try {

            let newErrors = {
                patientId: !Addpatient.patientId,
                startTime: !Addpatient.startTime,
                endTime: !Addpatient.endTime,
                prescribedby: !Addpatient.prescribedby,
                patientIncharge: !Addpatient.patientIncharge,
            };
            setErrors(newErrors);

            const hasErrors = Object.values(newErrors).some(error => error);
            if (hasErrors) {
                return;
            }
            const { header1 } = authorize();
            const bodyData = {
                date: forDate,
                id: "",
                organization,

                pid: Addpatient.patientId,
                shift: shifts?.map((k) => ({
                    record: k.shift[0].record?.map((l: any) => ({
                        distance: "",
                        endTime: Addpatient.endTime,
                        note: [
                            {
                                note: "",
                                time: ""
                            }
                        ],
                        staffId: Addpatient.prescribedby,
                        startTime: Addpatient.startTime,
                    })),
                    shiftIncharge: Addpatient.patientIncharge,
                    shiftName: "Shift-A",
                    shiftTime: "",
                })),
            }
            switch (selectedTab) {
                case "Shift-A":
                    bodyData.shift.forEach(shift => shift.shiftName = "Shift-A");
                    break;
                case "Shift-B":
                    bodyData.shift.forEach(shift => shift.shiftName = "Shift-B");
                    break;
                case "Shift-C":
                default:
                    bodyData.shift.forEach(shift => shift.shiftName = "Shift-C");
                    break;
            }
            const response = await axios.post(`${baseURL}/oneToOne/register`, bodyData, { headers: header1 });

            if (response.data.message.code === successCode) {
                dispatch({ type: 'SET_FORM_VALUES', payload: response.data.data });
                toast.success(response.data.message.description);
                toggle();
                fetchPatientData();
            } else {
                toast.error("Login failed: " + response.data.message.description);
            }
        } catch (error) {
            toast.error("An error occurred during register.");
        }
    };

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: "space-evenly", padding: "10px", width: "90%", position: "relative", left: "66px" }}>
                {renderDateBoxes()}
                <div className="inpMain">
                    <input
                        type="date"
                        value={selectedDate.toISOString().split('T')[0]}
                        onChange={handleDateChange}
                    />
                    <div style={{ border: "1px groove" }} />
                    <img style={{ position: "absolute", left: '12px', top: '21px', width: '32px', height: '22px' }} src={calendarMuiImage} />
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center", height: "79vh" }} >
                <div style={{ width: "15%", backgroundColor: "#eaf2fa", borderRight: "1px groove #ccd2d8" }} >
                    <div style={{ display: "flex", flexDirection: "column", height: "142px", justifyContent: "space-around", paddingTop: "20px", alignItems: "center" }}>
                        <button
                            className={`nav-link ${selectedTab === 'Shift-A' ? 'active' : ''}`}
                            id="Shift-A-tab"
                            type="button"
                            role="tab"
                            aria-controls="Shift-A"
                            aria-selected={selectedTab === 'Shift-A'}
                            onClick={() => handleTabClick('Shift-A')}
                            style={{ backgroundColor: selectedTab === 'Shift-A' ? '#cddcfa' : '', color: selectedTab === 'Shift-A' ? '' : 'black', width: "91%", height: "40px", borderRadius: "4px" }}
                        >
                            Shift-A
                        </button>
                        <button
                            className={`nav-link ${selectedTab === 'Shift-B' ? 'active' : ''}`}
                            id="Shift-B-tab"
                            type="button"
                            role="tab"
                            aria-controls="Shift-B"
                            aria-selected={selectedTab === 'Shift-B'}
                            onClick={() => handleTabClick('Shift-B')}
                            style={{ backgroundColor: selectedTab === 'Shift-B' ? '#cddcfa' : '', color: selectedTab === 'Shift-B' ? '' : 'black', width: "91%", height: "40px", borderRadius: "4px" }}
                        >
                            Shift-B
                        </button>
                        <button
                            className={`nav-link ${selectedTab === 'Shift-C' ? 'active' : ''}`}
                            id="Shift-C-tab"
                            type="button"
                            role="tab"
                            aria-controls="Shift-C"
                            aria-selected={selectedTab === 'Shift-C'}
                            onClick={() => handleTabClick('Shift-C')}
                            style={{ backgroundColor: selectedTab === 'Shift-C' ? '#cddcfa' : '', color: selectedTab === 'Shift-C' ? '' : 'black', width: "91%", height: "40px", borderRadius: "4px" }}
                        >
                            Shift-C
                        </button>
                    </div>
                </div>
                <div style={{ width: "83%", backgroundColor: "#f2f5f9" }} >
                    <div style={{ textAlign: "start", borderBottom: "1px groove #ccd2d8", height: "70px", lineHeight: 3.5, padding: "3px 0px 0px 10px", display: "flex", justifyContent: "space-between" }}>
                        <span style={{ fontSize: "20px", fontWeight: 600 }}>{date + " "}{selectedTab} 1:1 Assignment </span>
                        <div className="col-md-4 d-flex justify-content-end align-items-center gap-2 mb-2">
                            <div style={{ backgroundColor: primarybg, borderRadius: "4px", cursor: "pointer", fontSize: '30px', color: 'white', display: "flex", justifyContent: "center", alignItems: "center", width: "35px", height: "35px" }}>
                                <i style={{ fontSize: "36px", fontWeight: "lighter" }} className="material-icons" onClick={toggle}>add</i>
                            </div>
                            <div className="mx-0 search-container d-flex align-items-center">
                                <input
                                    type="text"
                                    placeholder="Search..."
                                    className="search form-control"
                                />
                                <FaSearch className="search-icon mt-1" />
                            </div>
                        </div>
                    </div>
                    <table className="table table-bordered" style={{ fontSize: '13px' }}>
                        <thead style={{ backgroundColor: grey }}>
                            <tr>
                                <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey, width: '13px' }}>S.No</th>
                                <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Patient Name</th>
                                <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Start Time</th>
                                <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>End Time</th>
                                <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Prescribed by</th>
                                <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Patient Incharge</th>
                                <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey, borderRightStyle: "none" }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {PatientDate && PatientDate.length > 0 ? (
                                PatientDate.map((patient: any, index: number) => (
                                    <tr key={index}>
                                        <td className="text text-center">{currentPage * 10 + index + 1}</td>
                                        <td
                                            style={{ cursor: "pointer", textAlign: "center" }}
                                            onClick={() => editChange(patient)}
                                            className="text"
                                        >
                                            {patient.patientName}
                                        </td>
                                        <td className="text" style={{ cursor: "pointer", textAlign: "center" }}>{patient.startTime}</td>
                                        <td className="text" style={{ cursor: "pointer", textAlign: "center" }}>{patient.endTime}</td>
                                        <td className="text" style={{ cursor: "pointer", textAlign: "center" }}>{patient.staffName}</td>
                                        <td className="text" style={{ cursor: "pointer", textAlign: "center" }}>{patient.shiftInchargeName}</td>
                                        <td className="text" style={{ display: "flex", justifyContent: "space-around", height: "35px", alignItems: "center", borderRightStyle: "none" }}>
                                        <Tooltip title="Edit" arrow>
                                        <div className="text-primary">
                                            <i
                                            style={{ fontSize: "18px", fontWeight: "lighter", cursor: "pointer", color: "#a5a5aa" }}
                                            onClick={() => { setEditModal1(true); editChange(patient); handleClose(); }}
                                            className="material-icons">create</i>
                                        </div>
                                        </Tooltip>
                                        <Tooltip title="View" arrow>
                                        <div className="text-primary">
                                            <i
                                            style={{ fontSize: "18px", fontWeight: "lighter", cursor: "pointer", color: "#a5a5aa" }}
                                            onClick={() => { setEditModalview(true); Viewstaff(patient); handleClose(); }}
                                            className="material-icons">preview</i>
                                        </div>
                                        </Tooltip>
                                            {/* <MenuItem onClick={() => { setEditModalview(true); Viewstaff(patient); handleClose(); }}>View</MenuItem> */}
                                            {/* <MenuItem onClick={() => { setEditModal1(true); editChange(patient); handleClose(); }}>Edit 1:1 Routine</MenuItem> */}
                                        </td>
                                        
                                    </tr>
                                ))

                            ) : (
                                <tr>
                                    <td colSpan={8} className="text-center">There are no records....</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <Modal isOpen={editModalview} centered size='lg'>
                        <div className="d-flex align-items-center justify-content-center vh-90">
                            <div className='row' style={{ width: "100%" }}>
                                <div className='container col-md-12'>
                                    <ModalHeader toggle={() => setEditModalview(false)}>
                                        <span>{formValues.patientName}</span>&nbsp;&nbsp;&nbsp;
                                        <span>{date + " "}</span>
                                    </ModalHeader>
                                    <ModalBody style={{ maxHeight: '65vh', overflowY: 'auto', display: "flex", justifyContent: "center" }}>
                                        <table className="table table-bordered" style={{ fontSize: '13px' }}>
                                            <thead style={{ backgroundColor: grey }}>
                                                <tr>
                                                    <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey, width: '13px' }}>S.No</th>
                                                    <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Assigned Staff Name</th>
                                                    <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Start Time</th>
                                                    <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>End Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {shifts.length > 0 && shifts[0].shift && shifts[0].shift.length > 0 && shifts[0].shift[0].record && shifts[0].shift[0].record.length > 0 ? (
                                                    shifts[0].shift[0].record.map((record: any, recordIndex: any) => (
                                                        <>
                                                            <tr
                                                            >
                                                                <td className="text text-center">{currentPage * 10 + recordIndex + 1}</td>
                                                                <td style={{ cursor: "pointer", textAlign: "center" }} className="text" >
                                                                    {record.staffName}
                                                                </td>
                                                                <td className="text" style={{ cursor: "pointer", textAlign: "center" }}>
                                                                    {record.startTime}
                                                                </td>
                                                                <td className="text" style={{ cursor: "pointer", textAlign: "center" }}>{record.endTime}</td>
                                                            </tr>
                                                        </>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={4} className="text-center">There are no records...</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </ModalBody>
                                </div>

                            </div>
                        </div >
                    </Modal >
                    <Modal isOpen={modal} centered size='lg'>
                        <div className="d-flex align-items-center justify-content-center vh-90">
                            <div className='row'>
                                <div className='container col-md-12'>
                                    <ModalHeader toggle={toggle}>
                                        Add 1:1 Routine
                                    </ModalHeader>
                                    <ModalBody style={{ maxHeight: '65vh', overflowY: 'auto', display: "flex", justifyContent: "center" }}>
                                        <div className="row w-100" style={{ marginTop: '10px' }}>
                                            <div className='col-md-12 mb-2'>Select Patient Name</div>
                                            <div className='col-md-12 mb-2'>
                                                <Autocomplete
                                                    id='patientId'
                                                    options={Patientnames}
                                                    getOptionLabel={(option) => `${option.basicDetails[0].name[0].given} ${option.basicDetails[0].name[0].family}`}
                                                    value={Patientnames.find(patient => patient.id === Addpatient.patientId) || null}
                                                    onChange={(event, value) => {
                                                        setAddpatient({ ...Addpatient, patientId: value ? value.id : null });
                                                        setErrors({ ...errors, patientId: false });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder='Select Patient Name'
                                                            variant="outlined"
                                                            error={!!errors.patientId}
                                                            helperText={errors.patientId ? 'Patient name is required' : ''}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className='col-md-3 mb-2'>StartTime</div>
                                            <div className='col-md-3 mb-2'>EndTime</div>
                                            <div className='col-md-6 mb-2'>Prescribed By</div>
                                            <div className='col-md-3 mb-2'>
                                                <input
                                                    type="time"
                                                    autoComplete="off"
                                                    value={Addpatient.startTime}
                                                    onChange={handleInputChangeadd}
                                                    id="startTime"
                                                    style={{ border: errors.startTime ? '2px solid red' : '1px solid #c6c6c6', height: '57px' }}
                                                    placeholder=" "
                                                    min="00:00"
                                                    max="23:59"
                                                />
                                            </div>
                                            <div className='col-md-3 mb-2'>
                                                <input
                                                    type="time"
                                                    autoComplete="off"
                                                    value={Addpatient.endTime}
                                                    onChange={handleInputChangeadd}
                                                    id="endTime"
                                                    style={{ border: errors.endTime ? '2px solid red' : '1px solid #c6c6c6', height: '57px' }}
                                                    placeholder=" "
                                                    min="00:00"
                                                    max="23:59"
                                                />
                                            </div>
                                            <div className='col-md-6 mb-2'>
                                                <Autocomplete
                                                    id='prescribedby'
                                                    options={Staff}
                                                    getOptionLabel={(option) => `${option.name[0].given} ${option.name[0].family}`}
                                                    value={Staff.find(staff => staff.id === Addpatient.prescribedby) || null}
                                                    onChange={(event, value) => handleAutocompleteChange('prescribedby', value, 'prescribedbyName')}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder='Select Staff Name'
                                                            variant="outlined"
                                                            error={!!errors.prescribedby}
                                                            helperText={errors.prescribedby ? 'Prescribed by is required' : ''}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className='col-md-6 mb-2'>Patient Incharge</div>
                                            <div className='col-md-6 mb-2'></div>
                                            <div className='col-md-6 mb-2'>
                                                <Autocomplete
                                                    id='patientIncharge'
                                                    options={Staff}
                                                    getOptionLabel={(option) => `${option.name[0].given} ${option.name[0].family}`}
                                                    value={Staff.find(staff => staff.id === Addpatient.patientIncharge) || null}
                                                    onChange={(event, value) => handleAutocompleteChange('patientIncharge', value, 'patientInchargeName')}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder='Select Staff Name'
                                                            variant="outlined"
                                                            error={!!errors.patientIncharge}
                                                            helperText={errors.patientIncharge ? 'Patient incharge is required' : ''}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className='col-md-6 mb-2'>
                                                {/* <Autocomplete
                                                    id='oneToOneObserver'
                                                    options={Staff}
                                                    getOptionLabel={(option) => `${option.name[0].given} ${option.name[0].family}`}
                                                    value={Staff.find(staff => staff.id === Addpatient.oneToOneObserver) || null}
                                                    onChange={(event, value) => handleAutocompleteChange('oneToOneObserver', value, 'oneToOneObserverName')}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder='Select Staff Name'
                                                            variant="outlined"
                                                            error={!!errors.oneToOneObserver}
                                                            helperText={errors.oneToOneObserver ? '1:1 Observer is required' : ''}
                                                        />
                                                    )}
                                                /> */}
                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter className="">
                                        <div className="d-flex gap-3 justify-content-center">
                                            <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={toggle} ></Button>
                                            <Button label="Save Changes" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={handleSaveClick}></Button>
                                        </div>
                                    </ModalFooter>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <Modal isOpen={editModal1} centered size='lg'>
                        <div className="d-flex align-items-center justify-content-center vh-90">
                            <div className='row'>
                                <div className='container col-md-12'>
                                    <ModalHeader toggle={() => setEditModal1(false)}>
                                        Edit 1:1 Routine
                                    </ModalHeader>
                                    <ModalBody style={{ maxHeight: '65vh', overflowY: 'auto' }}>
                                        <div className="row w-100 " style={{ marginTop: '10px' }}>
                                            <div className='col-md-12 mb-2'> Patient Name</div>
                                            <div className='col-md-12 mb-2' >
                                                <Autocomplete
                                                    id='PatientName'
                                                    options={Patientnamess}
                                                    value={formValues.patientName}
                                                    onChange={(e, v: any) => { formValues.patientId = v; setFormValues({ ...formValues }); }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} variant="outlined" />
                                                    )}
                                                    disabled
                                                />
                                            </div>
                                            <div className='col-md-3 mb-2' >StartTime</div>
                                            <div className='col-md-3 mb-2' >EndTime</div>
                                            <div className='col-md-6 mb-2'>Prescribed By</div>
                                            <div className='col-md-3 mb-2' >
                                                <TextField type="time"
                                                    autoComplete="off"
                                                    value={formValues.startTime}
                                                    id="StartTime"
                                                    style={{ border: '1px solid #c6c6c6', height: '57px', color: "#b6b1b1", backgroundColor: "#ffffff" }}
                                                    placeholder=" " disabled
                                                />
                                            </div>
                                            <div className='col-md-3 mb-2' >
                                                <TextField type="time"
                                                    autoComplete="off"
                                                    value={formValues.endTime}
                                                    id="EndTime"
                                                    style={{ border: '1px solid #c6c6c6', height: '57px', color: "#b6b1b1", backgroundColor: "#ffffff" }}
                                                    placeholder=" " disabled
                                                />
                                            </div>
                                            <div className='col-md-6 mb-2' >
                                                <Autocomplete
                                                    id='Prescribedby'
                                                    options={Staff}
                                                    getOptionLabel={(option) => `${option.name[0].given} ${option.name[0].family}`}
                                                    value={Staff.find(staff => staff.id === formValues.prescribedby) || null}
                                                    onChange={(event, value) => {
                                                        setFormValues({
                                                            ...formValues,
                                                            prescribedby: value ? value.id : null,
                                                            prescribedbyName: value ? `${value.name[0].given} ${value.name[0].family}` : ''
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} variant="outlined" />
                                                    )} disabled
                                                />
                                            </div>
                                            <div className='col-md-6 mb-2' >Patient Incharge</div>
                                            <div className='col-md-6 mb-2'></div>
                                            <div className='col-md-6 mb-2' >
                                                <Autocomplete
                                                    id='PatientIncharge'
                                                    options={Staff}
                                                    getOptionLabel={(option) => `${option.name[0].given} ${option.name[0].family}`}
                                                    value={Staff.find(staff => staff.id === formValues.patientIncharge) || null}
                                                    onChange={(event, value) => {
                                                        setFormValues({
                                                            ...formValues,
                                                            patientIncharge: value ? value.id : null,
                                                            patientInchargeName: value ? `${value.name[0].given} ${value.name[0].family}` : ''
                                                        });
                                                        setErrors({ ...errors, patientIncharge: false });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} placeholder='Select Patient Name' variant="outlined"
                                                            error={!!errors.patientIncharge}
                                                            helperText={errors.patientIncharge ? 'select Patient Incharge' : ''}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className='col-md-6 mb-2' >
                                                {/* <Autocomplete
                                                    id='Observer'
                                                    options={Staff}
                                                    value={formValues.oneToOneObserver}
                                                    onChange={(e, v: any) => { formValues.oneToOneObserver = v; setFormValues({ ...formValues }); }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} variant="outlined" />
                                                    )}
                                                    disabled
                                                /> */}
                                            </div>
                                            {shifts.length > 0 && shifts[0].shift && shifts[0].shift.length > 0 && shifts[0].shift[0].record && shifts[0].shift[0].record.length > 0 ? (
                                                shifts[0].shift[0].record.map((record: any, recordIndex: any) => (
                                                    <>
                                                        <div className="col-md-5">Select Staff Name</div>
                                                        <div className="col-md-3">Start Time</div>
                                                        <div className="col-md-3">End Time</div>
                                                        <div className="col-md-1"></div>
                                                        <div className="col-md-5 mb-2">
                                                            <Select
                                                                style={{ height: "57px", paddingRight: '8px' }}
                                                                className="form-select"
                                                                fullWidth
                                                                value={record.staffId}
                                                                onChange={(e) => handleRecordChange(e, recordIndex)}
                                                            >
                                                                {staffData.map((staff: any) => (
                                                                    <MenuItem key={staff.id} value={staff.id}>
                                                                        {staff.name[0].given + ' ' + staff.name[0].family}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                        <div className="col-md-3 mb-2">
                                                            <div>
                                                                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DemoContainer components={['TimePicker']}>
                                                                        <TimePicker
                                                                            value={record.startTime}
                                                                            onChange={(e: any) => handleInputChange(e, recordIndex, 'startTime')}
                                                                            ampm={false}
                                                                        />
                                                                    </DemoContainer>
                                                                </LocalizationProvider>  */}
                                                                <input
                                                                    type="time"
                                                                    autoComplete="off"
                                                                    value={record.startTime}
                                                                    onChange={(e) => handleInputChange(e, recordIndex, 'startTime')}
                                                                    id="StartTime"
                                                                    style={{ border: '1px solid #c6c6c6', height: '57px' }}
                                                                    placeholder=" "
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 mb-2">
                                                            <div>
                                                                <input
                                                                    type="time"
                                                                    autoComplete="off"
                                                                    value={record.endTime}
                                                                    onChange={(e) => handleInputChange(e, recordIndex, 'endTime')}
                                                                    id="EndTime"
                                                                    style={{ border: '1px solid #c6c6c6', height: '57px' }}
                                                                    placeholder=" "

                                                                    required

                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-1 mb-2" role="button">
                                                            {shifts[0].shift[0].record.length - 1 === recordIndex && (
                                                                <div>
                                                                    <i
                                                                        style={{ backgroundColor: "#d7dee5", borderRadius: "4px" }}
                                                                        onClick={addRecord}
                                                                        className="material-icons"
                                                                    >
                                                                        add
                                                                    </i>
                                                                </div>
                                                            )}
                                                            {shifts[0].shift[0].record.length !== 1 && (
                                                                <div>
                                                                    <i
                                                                        style={{ backgroundColor: "#d7dee5", borderRadius: "4px" }}
                                                                        onClick={() => confirmDeleteRecord(recordIndex)}
                                                                        className="material-icons"
                                                                    >
                                                                        remove
                                                                    </i>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <Dialog open={confirmDelete} onClose={cancelDelete} style={{ backgroundColor: "#ffffff36" }}>
                                                            <DialogTitle>Confirm Delete</DialogTitle>
                                                            <DialogContent>
                                                                <DialogContentText>
                                                                    Are you sure you want to delete this Staff?
                                                                </DialogContentText>
                                                            </DialogContent>
                                                            <DialogActions>
                                                                <div className="d-flex gap-3 justify-content-center">
                                                                    <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={cancelDelete} ></Button>
                                                                    <Button label="Delete Changes" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={() => removeRecord(recordIndex)}></Button>
                                                                </div>
                                                            </DialogActions>
                                                        </Dialog>
                                                        <div />
                                                    </>
                                                ))
                                            ) : (
                                                <div>No records available</div>
                                            )}

                                        </div>
                                    </ModalBody>
                                </div>
                                <ModalFooter className="">
                                    <div className="d-flex gap-3 justify-content-center">
                                        <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={() => setEditModal1(false)} ></Button>
                                        <Button label="Save Changes" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={savePatients}></Button>
                                    </div>
                                </ModalFooter>
                            </div>
                        </div>
                    </Modal >

                </div>
            </div>
        </div >
    );
};
export default OneToOne;