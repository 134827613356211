import React, { useEffect, useState } from 'react'
import { Autocomplete, TextField, FormControlLabel, Checkbox } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { baseURL, successCode } from '../../configuration/url';
import axios from 'axios';
import { Button } from 'reactstrap';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import { getAllPatient } from '../../slices/thunk';
import printImage from '../../assets/images/printer.svg'
import { secondarybt } from "../../common/primary";

const OneToOneReportView = () => {
  const addInputData = {
    date: null || '',
    patientName: ''
  }
  const org = useSelector((state: any) => state.Login.organization)
  const { patientData } = useSelector((state: any) => state.Patient);
  let [datas, setData] = useState(new Array<any>());
  let [addPatientName, setAddPatientName] = useState("");
  let [addNewPatient, setAddNewPatient] = useState(new Array<any>());
  const [viewContentData, setViewContent] = useState(true);
  const [newInputData, setNewInputData] = useState(addInputData)
  const [value, setValue] = useState()
  const [tableData, setTableData] = useState<any>()
  const [filterdata, setFilterData] = useState<any>([])
  const [disabled, setDisabled] = useState<boolean>(false);
  const [checkBox, setCheckBox] = useState<boolean>(false);
  const [selectShift, setSelectShift] = useState<any>('');
  const [selectShiftView, setSelectShiftView] = useState<boolean>(false);
  const [suicideRiskCheckBox, setSuicideRiskCheckBox] = useState<any>('')
  const [saocheckbox, setSAOCheckBox] = useState<any>('')
  const [aspcheckbox, setASPCheckBox] = useState<any>('')
  const [fpcheckbox, setFPCheckBox] = useState<any>('')
  const [gspcheckbox, setGSPCheckBox] = useState<any>('')
  const [etohcheckbox, setETOHCheckBox] = useState<any>('')
  const [drugcheckbox, setDRUGCheckBox] = useState<any>('')
  const [apcheckbox, setAPCheckBox] = useState<any>('')
  const [epocheckbox, setEPCheckBox] = useState<any>('')
  const [spcheckbox, setSPCheckBox] = useState<any>('')
  const [obsercheckbox, setOBSERCheckBox] = useState<any>('')
  const [onetoonecheckbox, setONETOONECheckBox] = useState<any>('')
  
  let noteArray: any = []
  const dispatch = useDispatch<any>()
  const option = ['Shift-A','Shift-B','Shift-C'] 

  useEffect(() => {
    getAllPatient(dispatch, org, -1, '');
  },[org])
  useEffect(() => {
    if (!tableData) return
    setSuicideRiskCheckBox(tableData.suicideRisk)
    if (!tableData.precaution) return
    const SAO = Array.isArray(tableData.precaution) && tableData.precaution.find((item: any) => item === "SEXUALLY ACTING OUT (SAO)")
    const ASP = Array.isArray(tableData.precaution) && tableData.precaution.find((item: any) => item === "ACTIVE SUICIDE PREC (ASP)")
    const FP = Array.isArray(tableData.precaution) && tableData.precaution.find((item: any) => item === "FALL PREC (FP)")
    const GSP = Array.isArray(tableData.precaution) && tableData.precaution.find((item: any) => item === "GEN SAFETY PREC (GSP)")
    const ETOH = Array.isArray(tableData.precaution) && tableData.precaution.find((item: any) => item === "DETOX-ETOH")
    const DRUG = Array.isArray(tableData.precaution) && tableData.precaution.find((item: any) => item === "DETOX-DRUG")
    const AP = Array.isArray(tableData.precaution) && tableData.precaution.find((item: any) => item === "ASSAULT PREC (AP)")
    const EP = Array.isArray(tableData.precaution) && tableData.precaution.find((item: any) => item === "ELOPEMENT PREC (EP)")
    const ONETOONE = Array.isArray(tableData.precaution) && tableData.precaution.find((item: any) => item === "ONE TO ONE (1:1)")
    const OBSER = Array.isArray(tableData.precaution) && tableData.precaution.find((item: any) => item === "OBSERVATION")
    const SP = Array.isArray(tableData.precaution) && tableData.precaution.find((item: any) => item === "SEIZURE PREC (SP)")
    setSAOCheckBox(SAO || '')
    setASPCheckBox(ASP || '')
    setFPCheckBox(FP || '')
    setGSPCheckBox(GSP || '')
    setETOHCheckBox(ETOH || '')
    setDRUGCheckBox(DRUG || '')
    setAPCheckBox(AP || '')
    setEPCheckBox(EP || '')
    setONETOONECheckBox(ONETOONE || '')
    setOBSERCheckBox(OBSER || '')
    setSPCheckBox(SP || '')
  }, [tableData])
  useEffect(() => {
    setAddNewPatient(patientData !== null && patientData !== undefined && patientData);
    setData(patientData !== null && patientData !== undefined && patientData.map((k: any) => { return k.basicDetails[0].name[0].given + " " + k.basicDetails[0].name[0].family }))
  }, [patientData])
  useEffect(() => {
    if (!value) return
    setNewInputData({ ...newInputData, date: moment(value).format("YYYYMMDD")});
  }, [value])
  const handleCheck=(e:React.ChangeEvent<HTMLInputElement>)=>{
    setDisabled(e.target.checked);
  }
  const handlesumbit = async () => {
    try {
      if (!newInputData.date && !newInputData.patientName) return
      setSAOCheckBox('')
      setASPCheckBox('')
      setFPCheckBox('')
      setGSPCheckBox('')
      setETOHCheckBox('')
      setDRUGCheckBox('')
      setAPCheckBox('')
      setEPCheckBox('')
      setONETOONECheckBox('')
      setOBSERCheckBox('')
      setSPCheckBox('')
     const response = await axios.get(`${baseURL}/register1to1/getOneToOneConfigReport?date=${newInputData.date}&organization=${org}&patientId=${newInputData.patientName}&shiftName=${selectShift}`)
     if ( response?.data.message && response?.data.message.code === successCode ) {
        setTableData(response.data.data)
        setSelectShiftView(selectShift ? true : false)
        setCheckBox(disabled)
      } else {
        console.error("Error:", response.data.message);
      }
    } catch (error) {
      console.error("API Request Error", error)
    }
  }

  useEffect(() => {
    const notesFilter =  tableData && tableData.configResponses.map((item: any, inx: number) => {
      if (!item.staff) return
      if (item.note === null) return
      return (
        Array.isArray(item.note) && item.note.map((n: any, index: number) => {
          const dataOffilter = {
            ...n,
            staff: item.staff
          }
          noteArray.push(dataOffilter)
          return (
            dataOffilter
          )
        })
      )
    })
    setFilterData(noteArray)
  }, [tableData])
  const handlePrint = () => {
      let printContents = document.getElementById('1to1Report')!.innerHTML;
      let printWindow = window.open('', '_blank');
      printWindow!.document.body.innerHTML = printContents;
      printWindow!.print();
      printWindow!.close();
  }
  return (
    <div className='container-fluid overflow-y-auto'>
      <div hidden={viewContentData === false} className='pt-2'>
        <div className="d-flex justify-content-between">
          <div className="col-md-3">
            <h5 style={{ float: 'left' }}>One To One Report</h5>
          </div>
          <div className="col-md-2 d-flex justify-content-end ">
            <button className="btn"  style={{  backgroundColor: secondarybt, border: 'none', width: 'fit-content', float: 'right', marginLeft: '5px'  }} onClick={handlePrint}><img src={printImage}/>Print</button>
          </div>
        </div>
        <div className=''>
          <div className=" d-flex flex-row mt-3" style={{ backgroundColor: '#EAF2FA', borderRadius: "5px", height: '90px', width: '100%' }}>
            <div className='col-3  d-flex justify-content-center align-items-center  ' >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  sx={{width: '90%'}}
                  className=""
                  label="Date"
                  value={value}
                  onChange={(newValue: any) => setValue(newValue)}
                />
              </LocalizationProvider>
            </div>
            <div className='col-4  d-flex justify-content-center align-items-center '>
            <Autocomplete
              className=""
              // style={{}}
              id="criteria"
              options={datas}
              getOptionLabel={(option) => option}
              value={addPatientName}
              onChange={(e, v) => {
                  setAddPatientName(v);
                  let newPatientData = addNewPatient.filter((m: any) => m.basicDetails[0].name[0].given + " " + m.basicDetails[0].name[0].family === v).map((k) => { return k.id });
                  newInputData.patientName = newPatientData[0];
                  setNewInputData({ ...newInputData });
              }}
              sx={{ width: "82%" }}
              renderInput={(params) => (
                  <TextField
                      {...params}
                      variant="outlined"
                      label="patient name"
                      placeholder="patient name"
                      margin="none"
                      fullWidth
                  />
              )}
            />
            </div>
            <div className='col-2  d-flex justify-content-center align-items-center  '>
            <Autocomplete
              className=" "
              id="criteria"
              options={option}
              getOptionLabel={(option) => option}
              value={selectShift}
              onChange={(e, v) => {
                setSelectShift(v)
              }}
              sx={{ width: "82%" }}
              renderInput={(params) => (
                  <TextField
                      {...params}
                      variant="outlined"
                      label="Shift"
                      placeholder="Shift"
                      margin="none"
                      fullWidth
                  />
              )}
            />
            </div>
            <div className='col-2  d-flex justify-content-center align-items-center  ' >
              <FormControlLabel
                control={<Checkbox checked={disabled} onChange={handleCheck} />}
                label="Proximity"
              />
            </div>
            <div className='col-1  d-flex justify-content-center align-items-center     '>
            <Button label='submit'  style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={handlesumbit}>Submit</Button>
            </div>
          </div>
          <div className='  container-fluid overflow-auto' id='1to1Report' >
            <section className='mt-3'>
              {tableData && <>
                <div className='d-flex justify-content-between'>
                  <div className='col-8'>
                    <div className='d-flex flex-row gap-3'>
                      <div><strong>Date: </strong>{ value && moment(value).format("DD-MM-YYYY")}</div>
                      { selectShiftView && <div><strong>Shift: </strong>{selectShift}</div>}
                    </div>
                    <strong>Patient Name: </strong>{tableData.patientId}
                  </div>
                  <div className='d-flex justify-content-between col-4'>
                    <div>
                      <strong>Room No: </strong>
                      {tableData.assignedBed && tableData.assignedBed.split('-')[0]} 
                    </div>
                    <div>
                      <strong className='pe-3'>AssignedBed:</strong>
                      {tableData.assignedBed && tableData.assignedBed.split('-')[1]}
                    </div>
                  </div>
                </div>
                <div className='mt-2'>
                  <table style={{ width: '100%', fontSize: '14px' }}>
                    <thead >
                      <tr style={{ height: '25px', border: '1px solid #C9C9C9', textAlign:'center', fontWeight: '600' }}>
                        <td style={{ width: '10%', border: '1px solid #C9C9C9' }} >Init</td>
                        <td style={{ width: '30%', border: '1px solid #C9C9C9' }}>Print Nurses Name </td>
                        <td style={{ width: '30%', border: '1px solid #C9C9C9' }}>Nurse Signature </td>
                        <td style={{ width: '10%', border: '1px solid #C9C9C9' }}>Time</td>
                        <td style={{ width: '15%', border: '1px solid #C9C9C9' }}>SUICIDE RISK</td>
                      </tr>
                    </thead>
                    <tbody style={{ border: '1px solid #C9C9C9' }}>
                      <tr style={{ height: '23px' }}>
                        <th style={{ width: '10%', border: '1px solid #C9C9C9' }}></th>
                        <th style={{ width: '30%', border: '1px solid #C9C9C9' }}></th>
                        <th style={{ width: '30%', border: '1px solid #C9C9C9' }}></th>
                        <th style={{ width: '10%', border: '1px solid #C9C9C9' }}></th>
                        <th style={{ width: '15%', paddingLeft: '20px' }}>
                        <div className="form-check " style={{height: '15px'}}>
                          <input className="form-check-input" type="checkbox" disabled checked={suicideRiskCheckBox === 'low'}  style={{padding: '7px', border: '1.2px solid black'}}/>
                          <label className="" style={{fontSize: '16px', fontWeight: 'normal', color: 'black'}} >
                            Low
                          </label>
                        </div>                     
                        </th>
                      </tr>
                      <tr style={{ height: '23px' }}>
                        <th style={{ width: '10%', border: '1px solid #C9C9C9' }}></th>
                        <th style={{ width: '30%', border: '1px solid #C9C9C9' }}></th>
                        <th style={{ width: '30%', border: '1px solid #C9C9C9' }}></th>
                        <th style={{ width: '10%', border: '1px solid #C9C9C9' }}></th>
                        <th style={{ width: '15%', paddingLeft: '20px' }}>
                        <div className="form-check " style={{height: '15px'}}>
                          <input className="form-check-input" type="checkbox" disabled checked={suicideRiskCheckBox === 'moderate'}  style={{padding: '7px', border: '1.2px solid black'}}/>
                          <label className="" style={{fontSize: '16px', fontWeight: 'normal'}} >
                            Moderate
                          </label>
                        </div>                      
                        </th>
                      </tr>
                      <tr style={{ height: '23px' }}>
                        <th style={{ width: '10%', border: '1px solid #C9C9C9' }}></th>
                        <th style={{ width: '30%', border: '1px solid #C9C9C9' }}></th>
                        <th style={{ width: '30%', border: '1px solid #C9C9C9' }}></th>
                        <th style={{ width: '10%', border: '1px solid #C9C9C9' }}></th>
                        <th style={{ width: '15%', paddingLeft: '20px' }}>
                        <div className="form-check " style={{height: '15px'}}>
                          <input className="form-check-input" type="checkbox" disabled checked={suicideRiskCheckBox === 'high'} style={{padding: '7px', border: '1.2px solid black'}}/>
                          <label className="" style={{fontSize: '16px', fontWeight: 'normal'}} >
                            High
                          </label>
                        </div>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
                { !selectShiftView ? <>
                <div className='mt-3' style={{ fontSize: '14px' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                    <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                    <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                    <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                    <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                    <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                    <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                    <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                    <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                    <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                    <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                  </div>
                </div>
                <div style={{ width: '100%', display: 'flex', fontSize: '13px' }}>
                    <div style={{ width: '50%', fontSize: '14px' }}>
                      {tableData && tableData.configResponses.map((t: any, ind: number) => {
                        return ind >= 0 && ind <= 23 && (<>
                          <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px' }} key={ind}>
                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[1]}</div>
                            <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && t.location}</div>
                            <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && t.activity}</div>
                            <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                            <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                            <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                          </div>
                        </>);
                      })}
                    </div>
                    <div style={{ width: '50%', fontSize: '14px' }}>
                      {tableData && tableData.configResponses.map((t: any, ind: number) => {
                        return ind >= 24 && ind <= 47 && (<>
                          <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px', maxHeight: '21.5px', overflow: 'hidden' }} key={ind}>
                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[1]}</div>
                            <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && t.location}</div>
                            <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && t.activity}</div>
                            <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                            <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                            <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                          </div>
                        </>);
                      })}
                    </div>
                </div>
                </> 
                    : 
                     <>
                       <div>
                         <div className='mt-3' style={{ fontSize: '14px' }}>
                           <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                             <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                             <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                             <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                             <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                             <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                             <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                             <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                             <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                             <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                             <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                             <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                             <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                           </div>
                         </div>
                         <div style={{ width: '100%', display: 'flex', fontSize: '13px' }}>
                             <div style={{ width: '50%', fontSize: '14px' }}>
                               {tableData && tableData.configResponses.map((t: any, ind: number) => {
                                 return ind >= 0 && ind <= 15 && (<>
                                   <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px', maxHeight: '21.5px', overflow: 'hidden'  }} key={ind}>
                                     <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[1]}</div>
                                     <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && t.location}</div>
                                     <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && t.activity}</div>
                                     <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                     <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                     <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                                   </div>
                                 </>);
                               })}
                             </div>
                             <div style={{ width: '50%', fontSize: '14px' }}>
                               {tableData && tableData.configResponses.map((t: any, ind: number) => {
                                 return ind >= 16 && ind <= 31 && (<>
                                   <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px', maxHeight: '21.5px', overflow: 'hidden' }} key={ind}>
                                     <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[1]}</div>
                                     <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && t.location}</div>
                                     <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && t.activity}</div>
                                     <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                     <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                     <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                                   </div>
                                 </>);
                               })}
                             </div>
                         </div>
                        </div>                  
                     </>}
                <div style={{width: '100%',backgroundColor: '#EAF2FA', display: 'flex', flexDirection: 'row', gap: '5px', paddingTop: '5px', paddingBottom: '5px'}}>
                  <div style={{width: '45%', fontSize: '13px', paddingLeft: '10px', paddingRight: '5px'}}>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', borderBottom: '1px solid black', fontWeight: '600'}}>
                      <div style={{width: '25%'}}>Location</div>
                      <div style={{width: '25%'}}>Code</div>
                      <div style={{width: '25%'}}>Location</div>
                      <div style={{width: '25%'}}>Code</div>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                      <div style={{width: '25%'}}>PT Room</div>
                      <div style={{width: '25%'}}>R</div>
                      <div style={{width: '25%'}}>Conf Rm</div>
                      <div style={{width: '25%'}}>C</div>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                      <div style={{width: '25%'}}>Day Room</div>
                      <div style={{width: '25%'}}>DR</div>
                      <div style={{width: '25%'}}>Mid Line</div>
                      <div style={{width: '25%'}}>L</div>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                      <div style={{width: '25%'}}>Dining RM</div>
                      <div style={{width: '25%'}}>D</div>
                      <div style={{width: '25%'}}>Quiet RM</div>
                      <div style={{width: '25%'}}>QR</div>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                      <div style={{width: '25%'}}>Nurses ST.</div>
                      <div style={{width: '25%'}}>N</div>
                      <div style={{width: '25%'}}>Shower</div>
                      <div style={{width: '25%'}}>S</div>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                      <div style={{width: '25%'}}>Telephone</div>
                      <div style={{width: '25%'}}>T</div>
                      <div style={{width: '25%'}}>Seclusion</div>
                      <div style={{width: '25%'}}>SC</div>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                      <div style={{width: '25%'}}>BathRoom</div>
                      <div style={{width: '25%'}}>B</div>
                      <div style={{width: '25%'}}>Hall</div>
                      <div style={{width: '25%'}}>H</div>
                    </div>
                  </div>
                  <div style={{width: '55%', fontSize: '13px', paddingLeft: '0px', paddingRight: '5px'}}>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', borderBottom: '1px solid black'}}>
                      <div style={{width: '100%', fontWeight: '600'}}>Behavior Code: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; See 1-10</div>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                      <div style={{width: '56%'}}>1. Sleeping (3 respirations observed)</div>
                      <div style={{width: '44%'}}>7. Oppositional</div>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                      <div style={{width: '56%'}}>2. Calm, interacting appropriately</div>
                      <div style={{width: '44%'}}>8. Bizarre behavior</div>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                      <div style={{width: '56%'}}>3. Withdrawn/Quiet</div>
                      <div style={{width: '44%'}}>9. Shouting/loud/agitated</div>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                      <div style={{width: '56%'}}>4. Crying, tearful</div>
                      <div style={{width: '44%'}}>10. Out of control, aggressive</div>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                      <div style={{width: '56%'}}>5. Anxious, restless</div>
                      <div style={{width: '44%'}}></div>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                      <div style={{width: '56%'}}>6. Elated, hyperactive, manic</div>
                      <div style={{width: '44%'}}></div>
                    </div>
                  </div>
                </div>
                <article style={{ width: '100%' }} className=''>
                  <div style={{ width: '100%', fontSize: '13px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', fontWeight: '600' }}>
                      <div style={{ width: '47%', border: '1px solid #C9C9C9' }} className='border'>Printed Name/Signature of Staff Member/Title</div>
                      <div style={{ width: '6%', border: '1px solid #C9C9C9' }} className='border'>Staff Initials</div>
                      <div style={{ width: '47%', border: '1px solid #C9C9C9' }} className='border'>Printed Name/Signature of Staff Member/Title</div>
                      <div style={{ width: '6%', border: '1px solid #C9C9C9' }} className='border'>Staff Initials</div>
                    </div>
                  </div>
                  <div style={{ width: '100%', fontSize: '13px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '22px' }}>
                      <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                      <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                      <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                      <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                    </div>
                  </div>
                  <div style={{ width: '100%', fontSize: '13px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '22px' }}>
                      <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                      <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                      <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                      <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                    </div>
                  </div>
                  <div style={{ width: '100%', fontSize: '13px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '22px' }}>
                      <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                      <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                      <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                      <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                    </div>
                  </div>
                  <div style={{ width: '100%', fontSize: '13px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 'auto' }}>
                      <div style={{ width: '100%', border: '1px solid #C9C9C9',paddingBottom: '10px' }}>
                        <div style={{padding:'1px 7px', fontWeight: '600'}}>PRECAUTIONS MEANING: </div>
                        <div style={{ display: 'flex', flex: 'row', width: '100%' }}>
                          <div style={{width: '33.33%', fontSize: '13px', padding: '0px 6px'}} >
                            <div className="form-check " style={{height: '11px'}}>
                              <input className="form-check-input" type="checkbox" disabled checked={saocheckbox} style={{padding: '0.5px', border: '1px solid black'}}/>
                              <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                SAO: SEXUALLY ACTING OUT
                              </label>
                            </div>
                            <div className="form-check " style={{height: '11px'}}>
                              <input className="form-check-input" type="checkbox" disabled checked={aspcheckbox} style={{padding: '0.5px', border: '1px solid black'}}/>
                              <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                ASP: ACTIVE SUICIDE PREC.
                              </label>
                            </div>
                            <div className="form-check " style={{height: '11px'}}>
                              <input className="form-check-input" type="checkbox" disabled checked={fpcheckbox} style={{padding: '0.5px', border: '1px solid black'}}/>
                              <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                FP: FALL PREC.
                              </label>
                            </div>
                            <div className="form-check " style={{height: '11px'}}>
                              <input className="form-check-input" type="checkbox" disabled checked={gspcheckbox} style={{padding: '0.5px', border: '1px solid black'}}/>
                              <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                GSP: GEN SAFETY PREC.
                              </label>
                            </div>
                          </div>
                          <div style={{width: '33.33%', fontSize: '13px', padding: '0px 6px'}} >
                            <div className="form-check " style={{height: '11px'}}>
                              <input className="form-check-input" type="checkbox" disabled checked={etohcheckbox} style={{padding: '0.5px', border: '1px solid black'}}/>
                              <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                               DETOX-ETOH
                              </label>
                            </div>
                            <div className="form-check " style={{height: '11px'}}>
                              <input className="form-check-input" type="checkbox" disabled checked={drugcheckbox} style={{padding: '0.5px', border: '1px solid black'}}/>
                              <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                               DETOX-DRUG
                              </label>
                            </div>
                            <div className="form-check " style={{height: '11px'}}>
                              <input className="form-check-input" type="checkbox" disabled checked={apcheckbox} style={{padding: '0.5px', border: '1px solid black'}}/>
                              <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                               AP: ASSAULT PREC.
                              </label>
                            </div>
                            <div className="form-check " style={{height: '11px'}}>
                              <input className="form-check-input" type="checkbox" disabled checked={epocheckbox} style={{padding: '0.5px', border: '1px solid black'}}/>
                              <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                               EP: ELOPEMENT PREC
                              </label>
                            </div>
                          </div>
                          <div style={{width: '33.33%', fontSize: '13px', padding: '0px 6px'}} >
                            <div className="form-check " style={{height: '11px'}}>
                              <input className="form-check-input" type="checkbox" disabled checked={onetoonecheckbox} style={{padding: '0.5px', border: '1px solid black'}}/>
                              <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                              1:1: ONE TO ONE
                              </label>
                            </div>
                            <div className="form-check " style={{height: '11px'}}>
                              <input className="form-check-input" type="checkbox" disabled checked={obsercheckbox} style={{padding: '0.5px', border: '1px solid black'}}/>
                              <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                              OBSERVATION
                              </label>
                            </div>
                            <div className="form-check " style={{height: '11px'}}>
                              <input className="form-check-input" type="checkbox" disabled checked={spcheckbox} style={{padding: '0.5px', border: '1px solid black'}}/>
                              <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                              SZ: SEIZURE PREC.
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                    </div>
                  </div>
                </article>
            </>
          }
            </section>
            { !selectShiftView && <section className='mt-3'>
              {tableData && <>
                <div className='d-flex justify-content-between'>
                  <div className='col-8'>
                    <div className='d-flex flex-row gap-3'>
                      <div><strong>Date: </strong>{ value && moment(value).format("DD-MM-YYYY")}</div>
                      { selectShiftView && <div><strong>Shift: </strong>{selectShift}</div>}
                    </div>
                    <strong>Patient Name: </strong>{tableData.patientId}
                  </div>
                  <div className='d-flex justify-content-between col-4'>
                    <div>
                      <strong>Room No: </strong>
                      {tableData.assignedBed && tableData.assignedBed.split('-')[0]} 
                    </div>
                    <div>
                      <strong className='pe-3'>AssignedBed:</strong>
                      {tableData.assignedBed && tableData.assignedBed.split('-')[1]}
                    </div>
                  </div>
                </div>
                <div className='mt-2'>
                  <table style={{ width: '100%', fontSize: '14px' }}>
                    <thead >
                      <tr style={{ height: '25px', border: '1px solid #C9C9C9', textAlign:'center', fontWeight: '600' }}>
                        <td style={{ width: '10%', border: '1px solid #C9C9C9' }} >Init</td>
                        <td style={{ width: '30%', border: '1px solid #C9C9C9' }}>Print Nurses Name </td>
                        <td style={{ width: '30%', border: '1px solid #C9C9C9' }}>Nurse Signature </td>
                        <td style={{ width: '10%', border: '1px solid #C9C9C9' }}>Time</td>
                        <td style={{ width: '15%', border: '1px solid #C9C9C9' }}>SUICIDE RISK</td>
                      </tr>
                    </thead>
                    <tbody style={{ border: '1px solid #C9C9C9' }}>
                      <tr style={{ height: '23px'  }}>
                        <th style={{ width: '10%', border: '1px solid #C9C9C9' }}></th>
                        <th style={{ width: '30%', border: '1px solid #C9C9C9' }}></th>
                        <th style={{ width: '30%', border: '1px solid #C9C9C9' }}></th>
                        <th style={{ width: '10%', border: '1px solid #C9C9C9' }}></th>
                        <th style={{ width: '15%', justifyContent: 'start' }}>
                        <div className="form-check " style={{height: '15px'}}>
                          <input className="form-check-input" type="checkbox" disabled checked={suicideRiskCheckBox === 'low'}  style={{padding: '7px', border: '1.2px solid black'}}/>
                          <label className="" style={{fontSize: '16px', fontWeight: 'normal', color: 'black'}} >
                            Low
                          </label>
                        </div>                     
                        </th>
                      </tr>
                      <tr style={{ height: '23px',  }}>
                        <th style={{ width: '10%', border: '1px solid #C9C9C9' }}></th>
                        <th style={{ width: '30%', border: '1px solid #C9C9C9' }}></th>
                        <th style={{ width: '30%', border: '1px solid #C9C9C9' }}></th>
                        <th style={{ width: '10%', border: '1px solid #C9C9C9' }}></th>
                        <th style={{ width: '15%', justifyContent: 'start' }}>
                        <div className="form-check " style={{height: '15px'}}>
                          <input className="form-check-input" type="checkbox" disabled checked={suicideRiskCheckBox === 'moderate'}  style={{padding: '7px', border: '1.2px solid black'}}/>
                          <label className="" style={{fontSize: '16px', fontWeight: 'normal'}} >
                            Moderate
                          </label>
                        </div>                      
                        </th>
                      </tr>
                      <tr style={{ height: '23px' }}>
                        <th style={{ width: '10%', border: '1px solid #C9C9C9' }}></th>
                        <th style={{ width: '30%', border: '1px solid #C9C9C9' }}></th>
                        <th style={{ width: '30%', border: '1px solid #C9C9C9' }}></th>
                        <th style={{ width: '10%', border: '1px solid #C9C9C9' }}></th>
                        <th style={{ width: '15%', justifyContent: 'start' }}>
                        <div className="form-check " style={{height: '15px'}}>
                          <input className="form-check-input" type="checkbox" disabled checked={suicideRiskCheckBox === 'high'} style={{padding: '7px', border: '1.2px solid black'}}/>
                          <label className="" style={{fontSize: '16px', fontWeight: 'normal'}} >
                            High
                          </label>
                        </div>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className='mt-3' style={{ fontSize: '14px' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                    <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                    <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                    <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                    <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                    <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                    <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                    <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                    <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                    <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                    <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                  </div>
                </div>
                <div style={{ width: '100%', display: 'flex', fontSize: '13px' }}>
                    <div style={{ width: '50%', fontSize: '14px' }}>
                      {tableData && tableData.configResponses.map((t: any, ind: number) => {
                        return ind >= 48 && ind <= 71 && (<>
                          <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px' }} key={ind}>
                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[1]}</div>
                            <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && t.location}</div>
                            <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && t.activity}</div>
                            <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                            <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                            <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                          </div>
                        </>);
                      })}
                    </div>
                    <div style={{ width: '50%', fontSize: '14px' }}>
                      {tableData && tableData.configResponses.map((t: any, ind: number) => {
                        return ind >= 72 && ind <= 95 && (<>
                          <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px', maxHeight: '21.5px', overflow: 'hidden' }} key={ind}>
                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[1]}</div>
                            <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && t.location}</div>
                            <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && t.activity}</div>
                            <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                            <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                            <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                          </div>
                        </>);
                      })}
                    </div>
                </div>
                <div style={{width: '100%',backgroundColor: '#EAF2FA', display: 'flex', flexDirection: 'row', gap: '5px', paddingTop: '5px', paddingBottom: '5px'}}>
                  <div style={{width: '45%', fontSize: '13px', paddingLeft: '10px', paddingRight: '5px'}}>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', borderBottom: '1px solid black', fontWeight: '600'}}>
                      <div style={{width: '25%'}}>Location</div>
                      <div style={{width: '25%'}}>Code</div>
                      <div style={{width: '25%'}}>Location</div>
                      <div style={{width: '25%'}}>Code</div>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                      <div style={{width: '25%'}}>PT Room</div>
                      <div style={{width: '25%'}}>R</div>
                      <div style={{width: '25%'}}>Conf Rm</div>
                      <div style={{width: '25%'}}>C</div>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                      <div style={{width: '25%'}}>Day Room</div>
                      <div style={{width: '25%'}}>DR</div>
                      <div style={{width: '25%'}}>Mid Line</div>
                      <div style={{width: '25%'}}>L</div>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                      <div style={{width: '25%'}}>Dining RM</div>
                      <div style={{width: '25%'}}>D</div>
                      <div style={{width: '25%'}}>Quiet RM</div>
                      <div style={{width: '25%'}}>QR</div>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                      <div style={{width: '25%'}}>Nurses ST.</div>
                      <div style={{width: '25%'}}>N</div>
                      <div style={{width: '25%'}}>Shower</div>
                      <div style={{width: '25%'}}>S</div>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                      <div style={{width: '25%'}}>Telephone</div>
                      <div style={{width: '25%'}}>T</div>
                      <div style={{width: '25%'}}>Seclusion</div>
                      <div style={{width: '25%'}}>SC</div>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                      <div style={{width: '25%'}}>BathRoom</div>
                      <div style={{width: '25%'}}>B</div>
                      <div style={{width: '25%'}}>Hall</div>
                      <div style={{width: '25%'}}>H</div>
                    </div>
                  </div>
                  <div style={{width: '55%', fontSize: '13px', paddingLeft: '0px', paddingRight: '5px'}}>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', borderBottom: '1px solid black'}}>
                      <div style={{width: '100%', fontWeight: '600'}}>Behavior Code: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; See 1-10</div>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                      <div style={{width: '56%'}}>1. Sleeping (3 respirations observed)</div>
                      <div style={{width: '44%'}}>7. Oppositional</div>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                      <div style={{width: '56%'}}>2. Calm, interacting appropriately</div>
                      <div style={{width: '44%'}}>8. Bizarre behavior</div>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                      <div style={{width: '56%'}}>3. Withdrawn/Quiet</div>
                      <div style={{width: '44%'}}>9. Shouting/loud/agitated</div>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                      <div style={{width: '56%'}}>4. Crying, tearful</div>
                      <div style={{width: '44%'}}>10. Out of control, aggressive</div>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                      <div style={{width: '56%'}}>5. Anxious, restless</div>
                      <div style={{width: '44%'}}></div>
                    </div>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                      <div style={{width: '56%'}}>6. Elated, hyperactive, manic</div>
                      <div style={{width: '44%'}}></div>
                    </div>
                  </div>
                </div>
                <article style={{ width: '100%' }} className=''>
                  <div style={{ width: '100%', fontSize: '13px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', fontWeight: '600' }}>
                      <div style={{ width: '47%', border: '1px solid #C9C9C9' }} className='border'>Printed Name/Signature of Staff Member/Title</div>
                      <div style={{ width: '6%', border: '1px solid #C9C9C9' }} className='border'>Staff Initials</div>
                      <div style={{ width: '47%', border: '1px solid #C9C9C9' }} className='border'>Printed Name/Signature of Staff Member/Title</div>
                      <div style={{ width: '6%', border: '1px solid #C9C9C9' }} className='border'>Staff Initials</div>
                    </div>
                  </div>
                  <div style={{ width: '100%', fontSize: '13px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '22px' }}>
                      <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                      <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                      <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                      <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                    </div>
                  </div>
                  <div style={{ width: '100%', fontSize: '13px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '22px' }}>
                      <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                      <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                      <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                      <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                    </div>
                  </div>
                  <div style={{ width: '100%', fontSize: '13px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '22px' }}>
                      <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                      <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                      <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                      <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                    </div>
                  </div>
                  <div style={{ width: '100%', fontSize: '13px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 'auto' }}>
                      <div style={{ width: '100%', border: '1px solid #C9C9C9',paddingBottom: '10px' }}>
                        <div style={{padding:'1px 7px', fontWeight: '600'}}>PRECAUTIONS MEANING: </div>
                        <div style={{ display: 'flex', flex: 'row', width: '100%' }}>
                          <div style={{width: '33.33%', fontSize: '13px', padding: '0px 6px'}} >
                            <div className="form-check " style={{height: '11px'}}>
                              <input className="form-check-input" type="checkbox" disabled checked={saocheckbox} style={{padding: '0.5px', border: '1px solid black'}}/>
                              <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                SAO: SEXUALLY ACTING OUT
                              </label>
                            </div>
                            <div className="form-check " style={{height: '11px'}}>
                              <input className="form-check-input" type="checkbox" disabled checked={aspcheckbox} style={{padding: '0.5px', border: '1px solid black'}}/>
                              <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                ASP: ACTIVE SUICIDE PREC.
                              </label>
                            </div>
                            <div className="form-check " style={{height: '11px'}}>
                              <input className="form-check-input" type="checkbox" disabled checked={fpcheckbox} style={{padding: '0.5px', border: '1px solid black'}}/>
                              <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                FP: FALL PREC.
                              </label>
                            </div>
                            <div className="form-check " style={{height: '11px'}}>
                              <input className="form-check-input" type="checkbox" disabled checked={gspcheckbox} style={{padding: '0.5px', border: '1px solid black'}}/>
                              <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                GSP: GEN SAFETY PREC.
                              </label>
                            </div>
                          </div>
                          <div style={{width: '33.33%', fontSize: '13px', padding: '0px 6px'}} >
                            <div className="form-check " style={{height: '11px'}}>
                              <input className="form-check-input" type="checkbox" disabled checked={etohcheckbox} style={{padding: '0.5px', border: '1px solid black'}}/>
                              <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                               DETOX-ETOH
                              </label>
                            </div>
                            <div className="form-check " style={{height: '11px'}}>
                              <input className="form-check-input" type="checkbox" disabled checked={drugcheckbox} style={{padding: '0.5px', border: '1px solid black'}}/>
                              <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                               DETOX-DRUG
                              </label>
                            </div>
                            <div className="form-check " style={{height: '11px'}}>
                              <input className="form-check-input" type="checkbox" disabled checked={apcheckbox} style={{padding: '0.5px', border: '1px solid black'}}/>
                              <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                               AP: ASSAULT PREC.
                              </label>
                            </div>
                            <div className="form-check " style={{height: '11px'}}>
                              <input className="form-check-input" type="checkbox" disabled checked={epocheckbox} style={{padding: '0.5px', border: '1px solid black'}}/>
                              <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                               EP: ELOPEMENT PREC
                              </label>
                            </div>
                          </div>
                          <div style={{width: '33.33%', fontSize: '13px', padding: '0px 6px'}} >
                            <div className="form-check " style={{height: '11px'}}>
                              <input className="form-check-input" type="checkbox" disabled checked={onetoonecheckbox} style={{padding: '0.5px', border: '1px solid black'}}/>
                              <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                              1:1: ONE TO ONE
                              </label>
                            </div>
                            <div className="form-check " style={{height: '11px'}}>
                              <input className="form-check-input" type="checkbox" disabled checked={obsercheckbox} style={{padding: '0.5px', border: '1px solid black'}}/>
                              <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                              OBSERVATION
                              </label>
                            </div>
                            <div className="form-check " style={{height: '11px'}}>
                              <input className="form-check-input" type="checkbox" disabled checked={spcheckbox} style={{padding: '0.5px', border: '1px solid black'}}/>
                              <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                              SZ: SEIZURE PREC.
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                    </div>
                  </div>
                </article>
            </>
          }
            </section>}
            <section className={`px-5 ${filterdata.length > 0 ? 'py-3': 'py-0' }`}>
              {
                (tableData && filterdata.length>0) && 
                <>
                  <div className='mt-2' style={{width: '100%', fontSize: '13px'}}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff</div>
                      <div style={{ width: '10%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time</div>
                      <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Notes</div>
                      <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff</div>
                      <div style={{ width: '10%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                      <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Notes</div>
                    </div>
                  </div>
                  <div style={{ width: '100%', display: 'flex', fontSize: '13px' }}>
                    <div style={{ width: '50%', fontSize: '14px' }}>
                      { filterdata && filterdata.map((item: any, inx: number) => {
                        const staffTime =  moment(item.time ,"YYYYMMDDHHmmss").format("HH:mm")
                        return inx >=0 && inx <= ((filterdata.length/2)-1) && (
                          <>
                            <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px' }} key={inx}>
                              <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{item.staff}</div>
                              <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{staffTime && staffTime}</div>
                              <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{item.note}</div>
                            </div>
                          </>  
                         )
                        })
                      }
                    </div>                    
                    <div style={{ width: '50%', fontSize: '14px' }}>
                      { filterdata && filterdata.map((item: any, inx: number) => {
                        const staffTime =  moment(item.time ,"YYYYMMDDHHmmss").format("HH:mm")
                        return inx >= (filterdata.length/2) && inx <= (filterdata.length-1) && (
                          <>
                            <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px' }} key={inx}>
                              <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{item.staff}</div>
                              <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{staffTime && staffTime}</div>
                              <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{item.note}</div>
                            </div>
                          </>  
                         )
                        })
                      }
                    </div>
                  </div>
                </> 
              }
            </section >
      </div> 
        </div>
      </div>
    </div>   
  )
} 
export default OneToOneReportView