import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import Rectangle6215 from './../../assets/images/Rectangle 6215.png';
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField, SelectChangeEvent, } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAllPatient, getAllStaff } from "../../slices/thunk";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { HttpLogin } from "../../utils/Http";
import trilliumImage from './../../assets/images/trillium.png';
import moment from 'moment';
import { successCode } from "../../configuration/url";
import { json } from "stream/consumers";
import { y } from "../../slices/secretkey/reducer";
import { secondarybt } from "../../common/primary";
import { IoPrintOutline } from "react-icons/io5";
import { primarybg, white } from "../../common/primary";

export default function ControlledAccordions() {

    const dispatch = useDispatch()
    const org = useSelector((state: any) => state.Login.organization)
    const { userData } = useSelector((state: any) => state.Login);
    const userId = userData.userDetail.id;
    const { patientData } = useSelector((state: any) => state.Patient);
    const { staffData } = useSelector((state: any) => state.Staff);
    let [addCalendarDate, setAddCalendarDate] = useState(new Array<any>());
    let [tableData, setTableData] = useState(new Array<any>());
    const [date, setDate] = useState("")
    let [data, setData] = useState(new Array<any>());
    let [getStaffData, setStaffData] = useState(new Array<any>());
    let [calendarDate,] = useState(new Date());
    let [newTimeSlot, setNewTimeSlot] = useState(new Array<any>());
    const addInputData = {
        shiftIncharge: "",
        criteria: "",
        patientName: "",
        slot: "",
        shift: [""],
        startDate: null,
        endDate: null
    }

    function removeDuplicates(arr: any[]) {
        return arr.filter((item,
            index) => arr.indexOf(item) === index);
    }
    let [newInputData, setNewInputData] = useState(addInputData);
    let [addNurseIncharge, setAddNurseIncharge] = useState("");
    let [addEnteredBy, setAddEnteredBy] = useState("");
    let [addNewStaff, setAddNewStaff] = useState(new Array<any>());
    let [addPatientName, setAddPatientName] = useState("");
    let [addNewPatient, setAddNewPatient] = useState(new Array<any>());
    let [loginStaff, setLoginStaff] = useState("");
    function formatDate(epoch: any) {
        let d = new Date(epoch);
        let hours = String((d.getHours())).padStart(2, '0');
        let mins = String((d.getMinutes())).padStart(2, '0');
        return `${hours}:${mins}`;
    }
    useEffect(() => {
        getAllPatient(dispatch, org, -1, '');
        getAllStaff(dispatch, org, -1, '');
        return () => {
          setViewContent(false);
        }
    }, [dispatch, org]);
    useEffect(() => {
        HttpLogin.axios().get("/api/org/getById/" + org)
            .then((res) => {
                if (res.data.message.code === successCode) {
                    var newResult = res.data.data.shift.startTime !== "" ? res.data.data.shift.startTime : "08:00";
                    const createTimeSlots = (fromTime: string, toTime: string, slotLength = 15 * 60) => {
                        let slotStart = new Date(fromTime).valueOf();
                        let slotEnd = new Date(fromTime).valueOf() + slotLength * 1000;
                        let endEpoch = new Date(toTime).valueOf();
                        let ob = [];
                        for (slotEnd; slotEnd <= endEpoch; slotEnd = slotEnd + slotLength * 1000) {
                            ob.push(formatDate(slotStart));
                            slotStart = slotEnd;
                        }
                        return ob;
                    }
                    const from = "2022-05-25 " + newResult;
                    const to = "2022-05-26 " + newResult;
                    const slotLength = 15 * 60;
                    var r = createTimeSlots(from, to, slotLength);
                    setNewTimeSlot(r);
                }
            })
        setNewInputData({ ...newInputData });
        var from = newInputData.startDate !== null ? new Date(newInputData.startDate) : new Date();
        var dayCalendar = [];
        dayCalendar.push(moment(new Date(from.setDate(from.getDate()))).format("YYYYMMDD"));
        setAddCalendarDate(dayCalendar);
    }, []);
    useEffect(() => {
        getAllPatient(dispatch, org, -1, '');
        getAllStaff(dispatch, org, -1, '');
    },[org])

    useEffect(() => {
        setAddNewPatient(patientData !== null && patientData !== undefined && patientData);
        setData(patientData !== null && patientData !== undefined && patientData.map((k: any) => { return k.basicDetails[0].name[0].given + " " + k.basicDetails[0].name[0].family }))
    }, [patientData])
    useEffect(() => {
        let newLoginstaff = staffData !== null && staffData !== undefined && staffData?.filter((t: any) => ((t.role === "Social Worker" || t.role === "Registered Nurse" || t.role === "Nurse Practitioner") && (t.id === userId)))
        if (newLoginstaff.length > 0) {
            setLoginStaff(newLoginstaff[0].name[0].given + " " + newLoginstaff[0].name[0].family);
        }
        setAddNewStaff(staffData !== null && staffData !== undefined && staffData?.filter((t: any) => t.role === "Social Worker" || t.role === "Registered Nurse" || t.role === "Nurse Practitioner"));
        setStaffData(staffData !== null && staffData !== undefined && staffData?.filter((t: any) => t.role === "Social Worker" || t.role === "Registered Nurse" || t.role === "Nurse Practitioner").map((k: any) => { return k.name[0].given + " " + k.name[0].family }))

    }, [staffData])

    useEffect(() => {
        if (newInputData.endDate === null && newInputData.startDate !== null) {
            var newFromDate = moment(newInputData.startDate).format("YYYYMMDD");
            var newToDate = moment(new Date()).format("YYYYMMDD");
            HttpLogin.axios().get("/api/config/getByDateRange?startDate=" + newFromDate + "&endDate=" + newToDate + `&organization=${org}`)
                .then((response) => {
                    if (response.data.data !== null && response.data.data !== undefined) {
                        setTableData(response.data.data);
                    }
                })
        } else if (newInputData.endDate !== null && newInputData.startDate !== null) {
            var newFromDate = moment(newInputData.startDate).format("YYYYMMDD");
            var newToDate = moment(newInputData.endDate).format("YYYYMMDD");
            HttpLogin.axios().get("/api/config/getByDateRange?startDate=" + newFromDate + "&endDate=" + newToDate + `&organization=${org}`)
                .then((response) => {
                    if (response.data.data !== null && response.data.data !== undefined) {
                        setTableData(response.data.data);
                    }
                })
        } else if (newInputData.endDate === null && newInputData.startDate === null) {
            HttpLogin.axios().get("/api/config/getByDate/" + addCalendarDate[0]+`/${org}`)
                .then((response) => {
                    if (response.data.data !== undefined && response.data.data !== null) {
                        setTableData(response.data.data);
                    }
                })
        }
    }, [addCalendarDate, newInputData]);

    const handleDateChange = (event: any) => {
        const d = new Date(event)
        let w = d.getFullYear().toString()
        if (w.length !== 4) return
        newInputData.startDate = event;
        setNewInputData({ ...newInputData });
        var from = newInputData.startDate !== null ? new Date(newInputData.startDate) : new Date();
        let dayCalendar = [];
        var to = newInputData.endDate !== null ? new Date(newInputData.endDate) : new Date(calendarDate.getFullYear(), calendarDate.getMonth(), calendarDate.getDate());
        for (var day = from; day <= to; day.setDate(day.getDate() + 1)) {
            dayCalendar.push(moment(new Date(day)).format("YYYYMMDD"));
        }
        setAddCalendarDate(removeDuplicates(dayCalendar));
    }

    const handleNewDateChange = (event: any) => {
        const d = new Date(event)
        let w = d.getFullYear().toString()
        if (w.length !== 4) return
        newInputData.endDate = event;
        setNewInputData({ ...newInputData });
        var from = newInputData.startDate !== null ? new Date(newInputData.startDate) : new Date();
        var dayCalendar = [];
        var to = newInputData.endDate !== null ? new Date(newInputData.endDate) : new Date(calendarDate.getFullYear(), calendarDate.getMonth(), calendarDate.getDate());
        for (var day = from; day <= to; day.setDate(day.getDate() + 1)) {
            dayCalendar.push(moment(new Date(day)).format("YYYYMMDD"));
        }
        setAddCalendarDate(removeDuplicates(dayCalendar));
    }

    const [expanded, setExpanded] = useState<string | false>('panel1');
    const [newExpanded, setNewExpanded] = useState<string | false>('panel11');
    const [newAddExpandeds, setNewAddExpandeds] = useState<string | false>('');
    const [newAddExpanded, setNewAddExpanded] = useState<string | false>('');

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    const handleNewChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setNewExpanded(newExpanded ? panel : false);
        };

    const handleNewAddChanges = (panel: string, access: any) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setNewAddExpandeds(isExpanded ? panel + "/" + access : false);
    };
    const handleNewAddChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setNewAddExpanded(isExpanded ? panel : false);
    };
    const handleChanges = (event: SelectChangeEvent, y: any) => {
        if (event.target.name === "shift") {
            newInputData.shift[y] = newInputData.shift.length > 1 ? "" : event.target.value
            if (event.target.value !== "") {
                setNewAddExpandeds(event.target.value ? event.target.value + "/" + y : false);
            } else {
                newInputData.shift = [];
                setNewInputData({ ...newInputData });
                setNewAddExpandeds(false);
            }
        } else if (event.target.name === "shiftIncharge") {
            newInputData.shiftIncharge = event.target.value;
            var newTableData = (tableData.map((k) => {
                var sureData = "";
                if (k.shiftIncharge.shiftInchargeA === event.target.value && sureData === 'Shift A') {
                    setNewAddExpandeds('Shift A' + "/" + y);
                    return k;
                } else if (k.shiftIncharge.shiftInchargeB === event.target.value && sureData === 'Shift B') {
                    setNewAddExpandeds('Shift B' + "/" + y);
                    return k;
                } else if (k.shiftIncharge.shiftInchargeC === event.target.value && sureData === 'Shift C') {
                    setNewAddExpandeds('Shift C' + "/" + y);
                    return k;
                }
            }))
            setTableData(newTableData);

        } else if (event.target.name === "criteria") {
            newInputData.criteria = event.target.value;
        } else if (event.target.name === "patientName") {
            newInputData.patientName = event.target.value;
            var newTableData = (tableData.map((k) => {
                if (k.pid === event.target.value) {
                    return k;
                } else {
                    return k;
                }
            }))
            setTableData(newTableData);
        } else if (event.target.name === "q15Slot") {
            newInputData.slot = event.target.value;
        }

        setNewInputData({ ...newInputData });
        setNewExpanded('panel11');
        event.preventDefault();
    }

    let [viewContentData, setViewContent] = useState(false);
    const handlePrint = () => {
        let printContents = document.getElementById('q15Reports')!.innerHTML;
        let printWindow = window.open('', '_blank');
        printWindow!.document.body.innerHTML = printContents;
        printWindow!.print();
        printWindow!.close();
    }

    return (
        <div className="row d-flex flex-column" style={{ width: "100%" }}>
            <div hidden={viewContentData} className="row" style={{ display: 'flex', marginLeft: '10px', gap: "10px", justifyContent: "flex-end" }}>
                <div className="col-md-3">
                    <h5 style={{ float: 'left' }}>Q15-Report</h5>
                </div>
                <div className="col-md-8">
                    <button className="btn" style={{ backgroundColor: secondarybt, border: 'none', width: 'fit-content', float: 'right', marginLeft: '5px' }} onClick={handlePrint}><IoPrintOutline style={{color: 'black', fontSize: '20px'}}/>Print</button>
                    <button className="btn" style={{ backgroundColor: secondarybt, border: 'none', width: 'fit-content', float: 'right', marginLeft: '5px' }} onClick={() => setViewContent(true)}>View</button>
                   </div>
            </div>
            <div hidden={viewContentData} className="row" >
                
                <div className="col-md-3 d-flex flex-column pe-1 pt-3" style={{ backgroundColor: '#EAF2FA', borderRadius: "5px" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker className="m-3 "  label="Start Date" value={newInputData.startDate}
                            onChange={handleDateChange} />
                        <DatePicker className="m-3" label="End Date" value={newInputData.endDate}
                            onChange={(e) => { handleNewDateChange(e) }} />
                    </LocalizationProvider>
                    <Autocomplete
                        className="m-3"
                        id="criteria"
                        options={data}
                        getOptionLabel={(option) => option}
                        value={addPatientName}
                        onChange={(e, v) => {
                            setAddPatientName(v);
                            let newPatientData = addNewPatient.filter((m: any) => m.basicDetails[0].name[0].given + " " + m.basicDetails[0].name[0].family === v).map((k) => { return k.id });
                            newInputData.patientName = newPatientData[0];
                            setNewInputData({ ...newInputData });
                        }}
                        sx={{ width: "88.4%" }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="patient name"
                                placeholder="patient name"
                                margin="none"
                                fullWidth
                            />
                        )}
                    />
                    <Autocomplete
                        className="m-3"
                        id="criteria"
                        options={getStaffData}
                        disabled={loginStaff.length > 0}
                        getOptionLabel={(option) => option}
                        value={loginStaff.length > 0 ? loginStaff : addNurseIncharge}
                        onChange={(e, v) => {
                            addNurseIncharge = v;
                            setAddNurseIncharge(v);
                            let newStaffData = addNewStaff.filter((m: any) => m.name[0].given + " " + m.name[0].family === v).map((k) => { return k.id });
                            newInputData.shiftIncharge = newStaffData[0];
                            setNewInputData({ ...newInputData });
                        }}
                        sx={{ width: "88.4%" }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Nurse Incharge"
                                placeholder="Nurse Incharge"
                                margin="none"
                                fullWidth
                            />
                        )}
                    />
                    <Autocomplete
                        className="m-3"
                        id="criteria"
                        options={getStaffData}
                        disabled={loginStaff.length > 0}
                        getOptionLabel={(option) => option}
                        value={loginStaff.length > 0 ? loginStaff : addEnteredBy}
                        onChange={(e, v) => {
                            addEnteredBy = v;
                            setAddEnteredBy(v);
                            let newStaffData = addNewStaff.filter((m: any) => m.name[0].given + " " + m.name[0].family === v).map((k) => { return k.id });
                            newInputData.criteria = newStaffData[0];
                            setNewInputData({ ...newInputData });
                        }}
                        sx={{ width: "88.4%" }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Entered By"
                                placeholder="Entered By"
                                margin="none"
                                fullWidth
                            />
                        )}
                    />
                </div>
                <div className="col-md-9">
                    {addCalendarDate !== undefined && addCalendarDate !== null && addCalendarDate.length !== 0 ? addCalendarDate.map((u: any) => (
                        <Accordion expanded={expanded === 'panelmain' + u} onChange={handleChange('panelmain' + u)}>
                            <AccordionSummary style={{ backgroundColor: "#FFF" }} aria-controls="panel1d-content" id="panel1d-header">
                                <Typography style={{ backgroundColor: "#FFF", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                    <span style={{ fontFamily: "poppins", fontSize: "18px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal" }}>
                                        {moment(u).format("MMM DD, YYYY")}
                                    </span>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails >
                                <Typography >
                                    {tableData !== null && tableData !== undefined && tableData.filter(j => u === moment(j.q15Date).format("YYYYMMDD")).length > 0 ? (tableData !== null && tableData !== undefined && tableData.filter(j => u === moment(j.q15Date).format("YYYYMMDD") && (newInputData.shiftIncharge === "" ||
                                        j.shiftIncharge.shiftInchargeA === newInputData.shiftIncharge || j.shiftIncharge.shiftInchargeB === newInputData.shiftIncharge || j.shiftIncharge.shiftInchargeC === newInputData.shiftIncharge) &&
                                        (newInputData.patientName === "" || newInputData.patientName === j.pid) && (newInputData.criteria === "" || newInputData.criteria === j.enteredBy.shiftA.slot1 || newInputData.criteria === j.enteredBy.shiftA.slot2 ||
                                            newInputData.criteria === j.enteredBy.shiftA.slot3 || newInputData.criteria === j.enteredBy.shiftA.slot4 || newInputData.criteria === j.enteredBy.shiftB.slot1 || newInputData.criteria === j.enteredBy.shiftB.slot2
                                            || newInputData.criteria === j.enteredBy.shiftB.slot3 || newInputData.criteria === j.enteredBy.shiftB.slot4 || newInputData.criteria === j.enteredBy.shiftC.slot1 || newInputData.criteria === j.enteredBy.shiftC.slot2
                                            || newInputData.criteria === j.enteredBy.shiftC.slot3 || newInputData.criteria === j.enteredBy.shiftC.slot4)).map((l: any, y: any) => (
                                                <Accordion style={{ borderStyle: "none" }} expanded={newExpanded === 'panel11'} onChange={handleNewChange('panel11')}>
                                                    <AccordionSummary style={{ backgroundColor: "#FFF" }} expandIcon={false} aria-controls="panel11d-content" id="panel11d-header">
                                                        <Typography style={{ width: "-webkit-fill-available" }}>
                                                            <div>
                                                                <span style={{ color: "#000", fontFamily: "poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal", position: 'relative', top: '-14px' }}>Shift Incharge:
                                                                    {newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeB !== "" && (
                                                                        (newAddExpanded === `Shift B: ${l.patientName}` || newAddExpandeds === 'Shift B' + "/" + y) &&
                                                                        staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeB).map((tableData: any) => (
                                                                            tableData.name[0].given + " " + tableData.name[0].family.charAt(0, 2)
                                                                        ))
                                                                    )}
                                                                    {newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeC !== "" && (
                                                                        (newAddExpanded === `Shift C: ${l.patientName}` || newAddExpandeds === 'Shift C' + "/" + y) &&
                                                                        staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeC).map((tableData: any) => (
                                                                            tableData.name[0].given + " " + tableData.name[0].family.charAt(0, 2)
                                                                        ))
                                                                    )}
                                                                    {newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "" && (
                                                                        (newAddExpanded === `Shift A: ${l.patientName}` || newAddExpandeds === 'Shift A' + "/" + y) &&
                                                                        staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeA).map((tableData: any) => (
                                                                            tableData.name[0].given + " " + tableData.name[0].family.charAt(0, 2)
                                                                        ))
                                                                    )}
                                                                    {newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeB && l.shiftIncharge.shiftInchargeB !== "" && (
                                                                        (newAddExpanded === `Shift B: ${l.patientName}` || newAddExpandeds === 'Shift B' + "/" + y) &&
                                                                        staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeB && i.id === newInputData.shiftIncharge).map((tableData: any) => (
                                                                            tableData.name[0].given + " " + tableData.name[0].family.charAt(0, 2)
                                                                        ))
                                                                    )}
                                                                    {newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeC && l.shiftIncharge.shiftInchargeC !== "" && (
                                                                        (newAddExpanded === `Shift C: ${l.patientName}` || newAddExpandeds === 'Shift C' + "/" + y) &&
                                                                        staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeC && i.id === newInputData.shiftIncharge).map((tableData: any) => (
                                                                            tableData.name[0].given + " " + tableData.name[0].family.charAt(0, 2)
                                                                        ))
                                                                    )}
                                                                    {newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "" && (
                                                                        (newAddExpanded === `Shift A: ${l.patientName}` || newAddExpandeds === 'Shift A' + "/" + y) &&
                                                                        staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeA && i.id === newInputData.shiftIncharge).map((tableData: any) => (
                                                                            tableData.name[0].given + " " + tableData.name[0].family.charAt(0, 2)
                                                                        ))
                                                                    )}
                                                                    {staffData?.filter((m: any) => m.id === newInputData.shiftIncharge && newInputData.shiftIncharge !== "" && newInputData.shiftIncharge !== l.shiftIncharge.shiftInchargeB && newInputData.shiftIncharge !== l.shiftIncharge.shiftInchargeC && newInputData.shiftIncharge !== l.shiftIncharge.shiftInchargeA).map((m: any) => (
                                                                        "--"
                                                                    ))}
                                                                </span>
                                                                <div className="bedorgForm-fields" style={{ zIndex: 1, top: "-15px", display: "flexDirection:''", flexDirection: "row-reverse", left: "unset", right: "-1px", width: "250px" }}>

                                                                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                                                        <InputLabel id="demo-select-small-label">Shift</InputLabel>
                                                                        <Select
                                                                            labelId="demo-select-small-label"
                                                                            id="shift" name="shift"
                                                                            // value={newInputData.shift[y]}
                                                                            value={newInputData.shift[y] || ''}
                                                                            label="Shift"
                                                                            // onChange={(e) => handleChanges(e, y)}
                                                                            onChange={(e) => handleChanges(e, y)}
                                                                        >
                                                                            <MenuItem value=" "><em>None</em></MenuItem>
                                                                            <MenuItem value="Shift A">Shift A</MenuItem>
                                                                            <MenuItem value="Shift B">Shift B</MenuItem>
                                                                            <MenuItem value="Shift C">Shift C</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                    <span style={{ position: "relative", top: "20px" }}>Select Shift:</span>
                                                                </div>
                                                                <div style={{ position: 'relative', width: '100%', top: '18px', left: '-10px' }}>
                                                                    <Accordion style={{ borderStyle: "none", top: '-12px' }} expanded={newAddExpanded === `Shift A: ${l.patientName}` || newAddExpandeds === 'Shift A' + "/" + y}
                                                                        onChange={(event, isExpanded) => {
                                                                            if (newAddExpandeds === 'Shift A' + "/" + y) {
                                                                                handleNewAddChanges('Shift A', y)(event, isExpanded);
                                                                            } else {
                                                                                handleNewAddChange(`Shift A: ${l.patientName}`)(event, isExpanded);
                                                                            }
                                                                        }}
                                                                    >
                                                                        <AccordionSummary style={{ backgroundColor: "#F7FAFE", borderRadius: '6px' }} aria-controls="panel11Ad-content" id="panel11Ad-header">
                                                                            <Typography >
                                                                                <span style={{ fontFamily: "poppins", color: "#000", fontSize: "14px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal", position: "relative", top: "0px" }}>Shift A:
                                                                                    <span style={{ fontFamily: "poppins", color: "#000", fontSize: "14px", fontStyle: "normal", fontWeight: 500, lineHeight: "normal" }}>{' '}{newTimeSlot[0]} {newTimeSlot[0] >= "12:00" && newTimeSlot[0] <= "23:45" ? "PM" : "AM"} to {newTimeSlot[31]} {newTimeSlot[31] >= "12:00" && newTimeSlot[31] <= "23:45" ? "PM" : "AM"}</span>
                                                                                </span>
                                                                                <div style={{ position: "absolute", left: "unset", whiteSpace: "nowrap", right: "90px", top: "12px", width: "131px", display: "flex", gap: "7px" }}>
                                                                                    <img src={Rectangle6215}></img>
                                                                                    <div style={{}}>Patient: {l.patientName}</div>
                                                                                </div>
                                                                            </Typography>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails style={{ backgroundColor: "#F7FAFE", borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px' }}>
                                                                            <Typography style={{ height: "448px", backgroundColor: "#F7FAFE", cursor: 'auto' }}>
                                                                                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                                                                                    <div style={{ width: "155px", fontFamily: "poppins", fontSize: "14px", fontWeight: 500, color: "#000", lineHeight: "normal" }}>{newTimeSlot[0]} {newTimeSlot[0] >= "12:00" && newTimeSlot[0] <= "23:45" ? "PM" : "AM"} to {newTimeSlot[7]} {newTimeSlot[7] >= "12:00" && newTimeSlot[7] <= "23:45" ? "PM" : "AM"}:

                                                                                        <div style={{ position: "absolute", left: "unset", top: "137px", width: "131px", display: "flex", gap: "7px", whiteSpace: "nowrap" }}>
                                                                                            <div style={{ position: "relative", top: "5px", width: "fit-contant" }}>Entered By:</div>
                                                                                            <img src={Rectangle6215}></img>
                                                                                            <div style={{ fontFamily: "poppins", fontSize: "13px", fontWeight: 400, color: "#000", lineHeight: "normal", position: "relative", top: "5px" }}>{((newInputData.criteria === "" && l.enteredBy.shiftA.slot1 !== "" && l.enteredBy.shiftA.slot1 !== null) && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") && staffData?.filter((i: any) => i.id === l.enteredBy.shiftA.slot1).map((tableData: any) => { return tableData.name[0].given + " " + tableData.name[0].family.charAt(0, 2) })) ||
                                                                                                ((newInputData.criteria !== "" && l.enteredBy.shiftA.slot1 !== "" && l.enteredBy.shiftA.slot1 !== null && l.enteredBy.shiftA.slot1 === newInputData.criteria) && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") && staffData?.filter((i: any) => i.id === l.enteredBy.shiftA.slot1).map((tableData: any) => { return tableData.name[0].given + " " + tableData.name[0].family.charAt(0, 2) })) ||
                                                                                                staffData?.filter((m: any) => m.id === newInputData.criteria && newInputData.criteria !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") && newInputData.criteria !== l.enteredBy.shiftA.slot1).map((m: any) => { return "--" }) && "--"}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                                                        return t >= 0 && t <= 7 && (<div style={{ display: "flex", gap: "10px", flexDirection: "column", width: "60px", height: "73px", border: "1px solid", borderRadius: "4px" }}>
                                                                                            <span style={{ textAlign: "center", color: " #415F9E", fontFamily: "poppins", fontSize: "15px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal" }}>{newTimeSlot[t]} </span>
                                                                                            <span style={{ textAlign: "center", border: "1px solid #E3E8F1", width: "45px", position: "relative", left: "7px" }}></span>
                                                                                            <span style={{ textAlign: "center", color: " #5E7494", fontFamily: "poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 600, lineHeight: "130%", letterSpacing: "0.016px" }} >{l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && m.location !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).map((n: any) => { return n.location }) : "NA"}-{l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && m.activity !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).map((n: any) => { return n.activity }) : "NA"}</span>
                                                                                        </div>)
                                                                                    })}
                                                                                </div>
                                                                                <span style={{ textAlign: "center", border: "1px solid ", position: "relative", left: "7px", display: "flex", width: "calc(100% - 11px)", top: "31px", borderColor: "#E3E8F1" }}></span>
                                                                                <div style={{ display: "flex", justifyContent: "space-evenly", position: "relative", top: "46px" }}>
                                                                                    <div style={{ width: "155px", fontFamily: "poppins", fontSize: "14px", fontWeight: 500, color: "#000", lineHeight: "normal" }}>{newTimeSlot[8]} {newTimeSlot[8] >= "12:00" && newTimeSlot[8] <= "23:45" ? "PM" : "AM"} to {newTimeSlot[15]} {newTimeSlot[15] >= "12:00" && newTimeSlot[15] <= "23:45" ? "PM" : "AM"}:

                                                                                        <div style={{ position: "absolute", left: "unset", top: "70px", width: "131px", display: "flex", gap: "7px", whiteSpace: "nowrap" }}>
                                                                                            <div style={{ position: "relative", top: "4px", width: "fit-contant" }}>Entered By:</div>
                                                                                            <img src={Rectangle6215}></img>
                                                                                            <div style={{ fontFamily: "poppins", fontSize: "13px", fontWeight: 400, color: "#000", lineHeight: "normal", position: "relative", top: "5px" }}>{((newInputData.criteria === "" && l.enteredBy.shiftA.slot2 !== "" && l.enteredBy.shiftA.slot2 !== null) && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") && staffData?.filter((i: any) => i.id === l.enteredBy.shiftA.slot2).map((tableData: any) => { return tableData.name[0].given + " " + tableData.name[0].family.charAt(0, 2) })) ||
                                                                                                ((newInputData.criteria !== "" && l.enteredBy.shiftA.slot2 !== "" && l.enteredBy.shiftA.slot2 !== null && l.enteredBy.shiftA.slot2 === newInputData.criteria) && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") && staffData?.filter((i: any) => i.id === l.enteredBy.shiftA.slot2).map((tableData: any) => { return tableData.name[0].given + " " + tableData.name[0].family.charAt(0, 2) })) ||
                                                                                                staffData?.filter((m: any) => m.id === newInputData.criteria && newInputData.criteria !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") && newInputData.criteria !== l.enteredBy.shiftA.slot2).map((m: any) => { return "--" }) && "--"}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                                                        return t >= 8 && t <= 15 && (<div style={{ display: "flex", gap: "10px", flexDirection: "column", width: "60px", height: "73px", border: "1px solid", borderRadius: "4px" }}>
                                                                                            <span style={{ textAlign: "center", color: " #415F9E", fontFamily: "poppins", fontSize: "15px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal" }}>{newTimeSlot[t]} </span>
                                                                                            <span style={{ textAlign: "center", border: "1px solid #E3E8F1", width: "45px", position: "relative", left: "7px" }}></span>
                                                                                            <span style={{ textAlign: "center", color: " #5E7494", fontFamily: "poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 600, lineHeight: "130%", letterSpacing: "0.016px" }} >{l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && m.location !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).map((n: any) => { return n.location }) : "NA"}-{l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && m.activity !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).map((n: any) => { return n.activity }) : "NA"}</span>
                                                                                        </div>)
                                                                                    })}
                                                                                </div>
                                                                                <span style={{ textAlign: "center", border: "1px solid", position: "relative", left: "7px", display: "flex", width: "calc(100% - 11px)", top: "75px", borderColor: "#E3E8F1" }}></span>
                                                                                <div style={{ display: "flex", justifyContent: "space-evenly", position: "relative", top: "86px" }}>
                                                                                    <div style={{ width: "155px", fontFamily: "poppins", fontSize: "14px", fontWeight: 500, color: "#000", lineHeight: "normal" }}>{newTimeSlot[16]} {newTimeSlot[16] >= "12:00" && newTimeSlot[16] <= "23:45" ? "PM" : "AM"} to {newTimeSlot[23]} {newTimeSlot[23] >= "12:00" && newTimeSlot[23] <= "23:45" ? "PM" : "AM"}:

                                                                                        <div style={{ position: "absolute", left: "unset", top: "70px", width: "131px", display: "flex", gap: "7px", whiteSpace: "nowrap" }}>
                                                                                            <div style={{ position: "relative", top: "5px", width: "fit-contant" }}>Entered By:</div>
                                                                                            <img src={Rectangle6215}></img>
                                                                                            <div style={{ fontFamily: "poppins", fontSize: "13px", fontWeight: 400, color: "#000", lineHeight: "normal", position: "relative", top: "5px" }}>{((newInputData.criteria === "" && l.enteredBy.shiftA.slot3 !== "" && l.enteredBy.shiftA.slot3 !== null) && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") && staffData?.filter((i: any) => i.id === l.enteredBy.shiftA.slot3).map((tableData: any) => { return tableData.name[0].given + " " + tableData.name[0].family.charAt(0, 2) })) ||
                                                                                                ((newInputData.criteria !== "" && l.enteredBy.shiftA.slot3 !== "" && l.enteredBy.shiftA.slot3 !== null && l.enteredBy.shiftA.slot3 === newInputData.criteria) && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") && staffData?.filter((i: any) => i.id === l.enteredBy.shiftA.slot3).map((tableData: any) => { return tableData.name[0].given + " " + tableData.name[0].family.charAt(0, 2) })) ||
                                                                                                staffData?.filter((m: any) => m.id === newInputData.criteria && newInputData.criteria !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") && newInputData.criteria !== l.enteredBy.shiftA.slot3).map((m: any) => { return "--" }) && "--"}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                                                        return t >= 16 && t <= 23 && (<div style={{ display: "flex", gap: "10px", flexDirection: "column", width: "60px", height: "73px", border: "1px solid", borderRadius: "4px" }}>
                                                                                            <span style={{ textAlign: "center", color: " #415F9E", fontFamily: "poppins", fontSize: "15px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal" }}>{newTimeSlot[t]} </span>
                                                                                            <span style={{ textAlign: "center", border: "1px solid #E3E8F1", width: "45px", position: "relative", left: "7px" }}></span>
                                                                                            <span style={{ textAlign: "center", color: " #5E7494", fontFamily: "poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 600, lineHeight: "130%", letterSpacing: "0.016px" }} >{l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && m.location !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).map((n: any) => { return n.location }) : "NA"}-{l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && m.activity !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).map((n: any) => { return n.activity }) : "NA"}</span>
                                                                                        </div>)
                                                                                    })}
                                                                                </div>
                                                                                <span style={{ textAlign: "center", border: "1px solid", position: "relative", left: "7px", display: "flex", width: "calc(100% - 11px)", top: "116px", borderColor: "#E3E8F1" }}></span>
                                                                                <div style={{ display: "flex", justifyContent: "space-evenly", position: "relative", top: "130px" }}>
                                                                                    <div style={{ width: "155px", fontFamily: "poppins", fontSize: "14px", fontWeight: 500, color: "#000", lineHeight: "normal" }}>{newTimeSlot[24]} {newTimeSlot[24] >= "12:00" && newTimeSlot[24] <= "23:45" ? "PM" : "AM"} to {newTimeSlot[31]} {newTimeSlot[31] >= "12:00" && newTimeSlot[31] <= "23:45" ? "PM" : "AM"}:

                                                                                        <div style={{ position: "absolute", left: "unset", top: "70px", width: "131px", display: "flex", gap: "7px", whiteSpace: "nowrap" }}>
                                                                                            <div style={{ position: "relative", top: "5px", width: "fit-contant" }}>Entered By:</div>
                                                                                            <img src={Rectangle6215}></img>
                                                                                            <div style={{ fontFamily: "poppins", fontSize: "13px", fontWeight: 400, color: "#000", lineHeight: "normal", position: "relative", top: "5px" }}>{((newInputData.criteria === "" && l.enteredBy.shiftA.slot4 !== "" && l.enteredBy.shiftA.slot4 !== null) && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") && staffData?.filter((i: any) => i.id === l.enteredBy.shiftA.slot4).map((tableData: any) => { return tableData.name[0].given + " " + tableData.name[0].family.charAt(0, 2) })) ||
                                                                                                ((newInputData.criteria !== "" && l.enteredBy.shiftA.slot4 !== "" && l.enteredBy.shiftA.slot4 !== null && l.enteredBy.shiftA.slot4 === newInputData.criteria) && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") && staffData?.filter((i: any) => i.id === l.enteredBy.shiftA.slot4).map((tableData: any) => { return tableData.name[0].given + " " + tableData.name[0].family.charAt(0, 2) })) ||
                                                                                                staffData?.filter((m: any) => m.id === newInputData.criteria && newInputData.criteria !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") && newInputData.criteria !== l.enteredBy.shiftA.slot4).map((m: any) => { return "--" }) && "--"}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                                                        return t >= 24 && t <= 31 && (<div style={{ display: "flex", gap: "10px", flexDirection: "column", width: "60px", height: "73px", border: "1px solid", borderRadius: "4px" }}>
                                                                                            <span style={{ textAlign: "center", color: " #415F9E", fontFamily: "poppins", fontSize: "15px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal" }}>{newTimeSlot[t]} </span>
                                                                                            <span style={{ textAlign: "center", border: "1px solid #E3E8F1", width: "45px", position: "relative", left: "7px" }}></span>
                                                                                            <span style={{ textAlign: "center", color: " #5E7494", fontFamily: "poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 600, lineHeight: "130%", letterSpacing: "0.016px" }} >{l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && m.location !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).map((n: any) => { return n.location }) : "NA"}-{l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && m.activity !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).map((n: any) => { return n.activity }) : "NA"}</span>
                                                                                        </div>)
                                                                                    })}
                                                                                </div>
                                                                            </Typography>
                                                                        </AccordionDetails>
                                                                    </Accordion>

                                                                    <Accordion style={{ borderStyle: "none", top: '-6px' }} expanded={newAddExpanded === `Shift B:${l.patientName}` || newAddExpandeds === 'Shift B' + "/" + y}
                                                                        onChange={(event, isExpanded) => {
                                                                            if (newAddExpandeds === 'Shift B' + "/" + y) {
                                                                                handleNewAddChanges('Shift B', y)(event, isExpanded);
                                                                            } else {
                                                                                handleNewAddChange(`Shift B:${l.patientName}`)(event, isExpanded);
                                                                            }
                                                                        }}
                                                                    >
                                                                        <AccordionSummary style={{ backgroundColor: "#F7FAFE", borderRadius: '6px' }} aria-controls="panel11Ad-content" id="panel11Ad-header">
                                                                            <Typography>
                                                                                <span style={{ fontFamily: "poppins", color: "#000", fontSize: "14px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal", position: "relative", top: "0px" }}>Shift B:
                                                                                    <span style={{ fontFamily: "poppins", color: "#000", fontSize: "14px", fontStyle: "normal", fontWeight: 500, lineHeight: "normal" }}>{' '}{newTimeSlot[32]} {newTimeSlot[32] >= "12:00" && newTimeSlot[32] <= "23:45" ? "PM" : "AM"} to {newTimeSlot[63]} {newTimeSlot[63] >= "12:00" && newTimeSlot[63] <= "23:45" ? "PM" : "AM"}</span>
                                                                                </span>
                                                                                <div style={{ position: "absolute", left: "unset", top: "12px", width: "131px", display: "flex", gap: "7px", whiteSpace: "nowrap", right: "90px" }}>
                                                                                    <img src={Rectangle6215}></img>
                                                                                    <div style={{}}>Patient:{l.patientName} </div>
                                                                                </div>
                                                                            </Typography>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails style={{ backgroundColor: "#F7FAFE", borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px' }}>
                                                                            <Typography style={{ height: "448px", backgroundColor: "#F7FAFE" }}>
                                                                                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                                                                                    <div style={{ width: "155px", fontFamily: "poppins", fontSize: "14px", fontWeight: 500, color: "#000", lineHeight: "normal" }}>{newTimeSlot[32]} {newTimeSlot[32] >= "12:00" && newTimeSlot[32] <= "23:45" ? "PM" : "AM"} to {newTimeSlot[39]} {newTimeSlot[39] >= "12:00" && newTimeSlot[39] <= "23:45" ? "PM" : "AM"}:

                                                                                        <div style={{ position: "absolute", left: "unset", top: "137px", width: "131px", display: "flex", gap: "7px", whiteSpace: "nowrap" }}>
                                                                                            <div style={{ position: "relative", top: "5px", width: "fit-contant" }}>Entered By:</div>
                                                                                            <img src={Rectangle6215}></img>
                                                                                            <div style={{ fontFamily: "poppins", fontSize: "13px", fontWeight: 400, color: "#000", lineHeight: "normal", position: "relative", top: "5px" }}>{((newInputData.criteria === "" && l.enteredBy.shiftB.slot1 !== "" && l.enteredBy.shiftB.slot1 !== null) && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeB && l.shiftIncharge.shiftInchargeB !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeB !== "") && staffData?.filter((i: any) => i.id === l.enteredBy.shiftB.slot1).map((tableData: any) => { return tableData.name[0].given + " " + tableData.name[0].family.charAt(0, 2) })) ||
                                                                                                ((newInputData.criteria !== "" && l.enteredBy.shiftB.slot1 !== "" && l.enteredBy.shiftB.slot1 !== null && l.enteredBy.shiftB.slot1 === newInputData.criteria) && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeB && l.shiftIncharge.shiftInchargeB !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeB !== "") && staffData?.filter((i: any) => i.id === l.enteredBy.shiftB.slot1).map((tableData: any) => { return tableData.name[0].given + " " + tableData.name[0].family.charAt(0, 2) })) ||
                                                                                                staffData?.filter((m: any) => m.id === newInputData.criteria && newInputData.criteria !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeB && l.shiftIncharge.shiftInchargeB !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeB !== "") && newInputData.criteria !== l.enteredBy.shiftB.slot1).map((m: any) => { return "--" }) && "--"}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                                                        return t >= 32 && t <= 39 && (<div style={{ display: "flex", gap: "10px", flexDirection: "column", width: "60px", height: "73px", border: "1px solid", borderRadius: "4px" }}>
                                                                                            <span style={{ textAlign: "center", color: " #415F9E", fontFamily: "poppins", fontSize: "15px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal" }}>{newTimeSlot[t]} </span>
                                                                                            <span style={{ textAlign: "center", border: "1px solid #E3E8F1", width: "45px", position: "relative", left: "7px" }}></span>
                                                                                            <span style={{ textAlign: "center", color: " #5E7494", fontFamily: "poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 600, lineHeight: "130%", letterSpacing: "0.016px" }} >{l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && m.location !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).map((n: any) => { return n.location }) : "NA"}-{l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && m.activity !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).map((n: any) => { return n.activity }) : "NA"}</span>
                                                                                        </div>)
                                                                                    })}

                                                                                </div>
                                                                                <span style={{ textAlign: "center", border: "1px solid ", position: "relative", left: "7px", display: "flex", width: "calc(100% - 11px)", top: "31px", borderColor: "#E3E8F1" }}></span>
                                                                                <div style={{ display: "flex", justifyContent: "space-evenly", position: "relative", top: "46px" }}>
                                                                                    <div style={{ width: "155px", fontFamily: "poppins", fontSize: "14px", fontWeight: 500, color: "#000", lineHeight: "normal" }}>{newTimeSlot[40]} {newTimeSlot[40] >= "12:00" && newTimeSlot[40] <= "23:45" ? "PM" : "AM"} to {newTimeSlot[47]} {newTimeSlot[47] >= "12:00" && newTimeSlot[47] <= "23:45" ? "PM" : "AM"}:

                                                                                        <div style={{ position: "absolute", left: "unset", top: "70px", width: "131px", display: "flex", gap: "7px", whiteSpace: "nowrap" }}>
                                                                                            <div style={{ position: "relative", top: "5px", width: "fit-contant" }}>Entered By:</div>
                                                                                            <img src={Rectangle6215}></img>
                                                                                            <div style={{ fontFamily: "poppins", fontSize: "13px", fontWeight: 400, color: "#000", lineHeight: "normal", position: "relative", top: "5px" }}>{((newInputData.criteria === "" && l.enteredBy.shiftB.slot2 !== "" && l.enteredBy.shiftB.slot2 !== null) && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeB && l.shiftIncharge.shiftInchargeB !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeB !== "") && staffData?.filter((i: any) => i.id === l.enteredBy.shiftB.slot2).map((tableData: any) => { return tableData.name[0].given + " " + tableData.name[0].family.charAt(0, 2) })) ||
                                                                                                ((newInputData.criteria !== "" && l.enteredBy.shiftB.slot2 !== "" && l.enteredBy.shiftB.slot2 !== null && l.enteredBy.shiftB.slot2 === newInputData.criteria) && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeB && l.shiftIncharge.shiftInchargeB !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeB !== "") && staffData?.filter((i: any) => i.id === l.enteredBy.shiftB.slot2).map((tableData: any) => { return tableData.name[0].given + " " + tableData.name[0].family.charAt(0, 2) })) ||
                                                                                                staffData?.filter((m: any) => m.id === newInputData.criteria && newInputData.criteria !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeB && l.shiftIncharge.shiftInchargeB !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeB !== "") && newInputData.criteria !== l.enteredBy.shiftB.slot2).map((m: any) => { return "--" }) && "--"}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                                                        return t >= 40 && t <= 47 && (<div style={{ display: "flex", gap: "10px", flexDirection: "column", width: "60px", height: "73px", border: "1px solid", borderRadius: "4px" }}>
                                                                                            <span style={{ textAlign: "center", color: " #415F9E", fontFamily: "poppins", fontSize: "15px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal" }}>{newTimeSlot[t]} </span>
                                                                                            <span style={{ textAlign: "center", border: "1px solid #E3E8F1", width: "45px", position: "relative", left: "7px" }}></span>
                                                                                            <span style={{ textAlign: "center", color: " #5E7494", fontFamily: "poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 600, lineHeight: "130%", letterSpacing: "0.016px" }} >{l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && m.location !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).map((n: any) => { return n.location }) : "NA"}-{l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && m.activity !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).map((n: any) => { return n.activity }) : "NA"}</span>
                                                                                        </div>)
                                                                                    })}

                                                                                </div>
                                                                                <span style={{ textAlign: "center", border: "1px solid", position: "relative", left: "7px", display: "flex", width: "calc(100% - 11px)", top: "75px", borderColor: "#E3E8F1" }}></span>
                                                                                <div style={{ display: "flex", justifyContent: "space-evenly", position: "relative", top: "86px" }}>
                                                                                    <div style={{ width: "155px", fontFamily: "poppins", fontSize: "14px", fontWeight: 500, color: "#000", lineHeight: "normal" }}>{newTimeSlot[48]} {newTimeSlot[48] >= "12:00" && newTimeSlot[48] <= "23:45" ? "PM" : "AM"} to {newTimeSlot[55]} {newTimeSlot[55] >= "12:00" && newTimeSlot[55] <= "23:45" ? "PM" : "AM"}:

                                                                                        <div style={{ position: "absolute", left: "unset", top: "70px", width: "131px", display: "flex", gap: "7px", whiteSpace: "nowrap" }}>
                                                                                            <div style={{ position: "relative", top: "5px", width: "fit-contant" }}>Entered By:</div>
                                                                                            <img src={Rectangle6215}></img>
                                                                                            <div style={{ fontFamily: "poppins", fontSize: "13px", fontWeight: 400, color: "#000", lineHeight: "normal", position: "relative", top: "5px" }}>{((newInputData.criteria === "" && l.enteredBy.shiftB.slot3 !== "" && l.enteredBy.shiftB.slot3 !== null) && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeB && l.shiftIncharge.shiftInchargeB !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeB !== "") && staffData?.filter((i: any) => i.id === l.enteredBy.shiftB.slot3).map((tableData: any) => { return tableData.name[0].given + " " + tableData.name[0].family.charAt(0, 2) })) ||
                                                                                                ((newInputData.criteria !== "" && l.enteredBy.shiftB.slot3 !== "" && l.enteredBy.shiftB.slot3 !== null && l.enteredBy.shiftB.slot3 === newInputData.criteria) && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeB && l.shiftIncharge.shiftInchargeB !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeB !== "") && staffData?.filter((i: any) => i.id === l.enteredBy.shiftB.slot3).map((tableData: any) => { return tableData.name[0].given + " " + tableData.name[0].family.charAt(0, 2) })) ||
                                                                                                staffData?.filter((m: any) => m.id === newInputData.criteria && newInputData.criteria !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeB && l.shiftIncharge.shiftInchargeB !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeB !== "") && newInputData.criteria !== l.enteredBy.shiftB.slot3).map((m: any) => { return "--" }) && "--"}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                                                        return t >= 48 && t <= 55 && (<div style={{ display: "flex", gap: "10px", flexDirection: "column", width: "60px", height: "73px", border: "1px solid", borderRadius: "4px" }}>
                                                                                            <span style={{ textAlign: "center", color: " #415F9E", fontFamily: "poppins", fontSize: "15px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal" }}>{newTimeSlot[t]} </span>
                                                                                            <span style={{ textAlign: "center", border: "1px solid #E3E8F1", width: "45px", position: "relative", left: "7px" }}></span>
                                                                                            <span style={{ textAlign: "center", color: " #5E7494", fontFamily: "poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 600, lineHeight: "130%", letterSpacing: "0.016px" }} >{l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && m.location !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).map((n: any) => { return n.location }) : "NA"}-{l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && m.activity !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).map((n: any) => { return n.activity }) : "NA"}</span>
                                                                                        </div>)
                                                                                    })}

                                                                                </div>
                                                                                <span style={{ textAlign: "center", border: "1px solid", position: "relative", left: "7px", display: "flex", width: "calc(100% - 11px)", top: "116px", borderColor: "#E3E8F1" }}></span>
                                                                                <div style={{ display: "flex", justifyContent: "space-evenly", position: "relative", top: "130px" }}>
                                                                                    <div style={{ width: "155px", fontFamily: "poppins", fontSize: "14px", fontWeight: 500, color: "#000", lineHeight: "normal" }}>{newTimeSlot[56]} {newTimeSlot[56] >= "12:00" && newTimeSlot[56] <= "23:45" ? "PM" : "AM"} to {newTimeSlot[63]} {newTimeSlot[63] >= "12:00" && newTimeSlot[63] <= "23:45" ? "PM" : "AM"}:

                                                                                        <div style={{ position: "absolute", left: "unset", top: "70px", width: "131px", display: "flex", gap: "7px", whiteSpace: "nowrap" }}>
                                                                                            <div style={{ position: "relative", top: "5px", width: "fit-contant" }}>Entered By:</div>
                                                                                            <img src={Rectangle6215}></img>
                                                                                            <div style={{ fontFamily: "poppins", fontSize: "13px", fontWeight: 400, color: "#000", lineHeight: "normal", position: "relative", top: "5px" }}>{((newInputData.criteria === "" && l.enteredBy.shiftB.slot4 !== "" && l.enteredBy.shiftB.slot4 !== null) && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeB && l.shiftIncharge.shiftInchargeB !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeB !== "") && staffData?.filter((i: any) => i.id === l.enteredBy.shiftB.slot4).map((tableData: any) => { return tableData.name[0].given + " " + tableData.name[0].family.charAt(0, 2) })) ||
                                                                                                ((newInputData.criteria !== "" && l.enteredBy.shiftB.slot4 !== "" && l.enteredBy.shiftB.slot4 !== null && l.enteredBy.shiftB.slot4 === newInputData.criteria) && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeB && l.shiftIncharge.shiftInchargeB !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeB !== "") && staffData?.filter((i: any) => i.id === l.enteredBy.shiftB.slot4).map((tableData: any) => { return tableData.name[0].given + " " + tableData.name[0].family.charAt(0, 2) })) ||
                                                                                                staffData?.filter((m: any) => m.id === newInputData.criteria && newInputData.criteria !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeB && l.shiftIncharge.shiftInchargeB !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeB !== "") && newInputData.criteria !== l.enteredBy.shiftB.slot4).map((m: any) => { return "--" }) && "--"}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                                                        return t >= 56 && t <= 63 && (<div style={{ display: "flex", gap: "10px", flexDirection: "column", width: "60px", height: "73px", border: "1px solid", borderRadius: "4px" }}>
                                                                                            <span style={{ textAlign: "center", color: " #415F9E", fontFamily: "poppins", fontSize: "15px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal" }}>{newTimeSlot[t]} </span>
                                                                                            <span style={{ textAlign: "center", border: "1px solid #E3E8F1", width: "45px", position: "relative", left: "7px" }}></span>
                                                                                            <span style={{ textAlign: "center", color: " #5E7494", fontFamily: "poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 600, lineHeight: "130%", letterSpacing: "0.016px" }} >{l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && m.location !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).map((n: any) => { return n.location }) : "NA"}-{l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && m.activity !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).map((n: any) => { return n.activity }) : "NA"}</span>
                                                                                        </div>)
                                                                                    })}

                                                                                </div>
                                                                            </Typography>
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                    <Accordion style={{ borderStyle: "none", marginBottom: '40px' }} expanded={newAddExpanded === `Shift C:${l.patientName}` || newAddExpandeds === 'Shift C' + "/" + y} onChange={(event, isExpanded) => {
                                                                        if (newAddExpandeds === 'Shift C' + "/" + y) {
                                                                            handleNewAddChanges('Shift C', y)(event, isExpanded);
                                                                        } else {
                                                                            handleNewAddChange(`Shift C:${l.patientName}`)(event, isExpanded);
                                                                        }
                                                                    }}>
                                                                        <AccordionSummary style={{ backgroundColor: "#F7FAFE", borderRadius: '6px' }} aria-controls="panel11Ad-content" id="panel11Ad-header">
                                                                            <Typography>
                                                                                <span style={{ fontFamily: "poppins", color: "#000", fontSize: "14px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal", position: "relative", top: "0px" }}>Shift C:
                                                                                    <span style={{ fontFamily: "poppins", color: "#000", fontSize: "14px", fontStyle: "normal", fontWeight: 500, lineHeight: "normal" }}>{' '}{newTimeSlot[64]} {newTimeSlot[64] >= "12:00" && newTimeSlot[64] <= "23:45" ? "PM" : "AM"} to {newTimeSlot[95]} {newTimeSlot[95] >= "12:00" && newTimeSlot[95] <= "23:45" ? "PM" : "AM"}</span>
                                                                                </span>
                                                                                <div style={{ position: "absolute", left: "unset", top: "12px", width: "131px", display: "flex", gap: "7px", whiteSpace: "nowrap", right: "90px" }}>
                                                                                    <img src={Rectangle6215}></img>
                                                                                    <div style={{}}>Patient: {l.patientName}</div>
                                                                                </div>
                                                                            </Typography>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails style={{ backgroundColor: "#F7FAFE", borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px' }}>
                                                                            <Typography style={{ height: "448px", backgroundColor: "#F7FAFE" }}>
                                                                                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                                                                                    <div style={{ width: "155px", fontFamily: "poppins", fontSize: "14px", fontWeight: 500, color: "#000", lineHeight: "normal" }}>{newTimeSlot[64]} {newTimeSlot[64] >= "12:00" && newTimeSlot[64] <= "23:45" ? "PM" : "AM"} to {newTimeSlot[71]} {newTimeSlot[71] >= "12:00" && newTimeSlot[71] <= "23:45" ? "PM" : "AM"}:

                                                                                        <div style={{ position: "absolute", left: "unset", top: "137px", width: "131px", display: "flex", gap: "7px", whiteSpace: "nowrap" }}>
                                                                                            <div style={{ position: "relative", top: "5px", width: "fit-contant" }}>Entered By:</div>
                                                                                            <img src={Rectangle6215}></img>
                                                                                            <div style={{ fontFamily: "poppins", fontSize: "13px", fontWeight: 400, color: "#000", lineHeight: "normal", position: "relative", top: "5px" }}>{((newInputData.criteria === "" && l.enteredBy.shiftC.slot1 !== "" && l.enteredBy.shiftC.slot1 !== null) && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeC && l.shiftIncharge.shiftInchargeC !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeC !== "") && staffData?.filter((i: any) => i.id === l.enteredBy.shiftC.slot1).map((tableData: any) => { return tableData.name[0].given + " " + tableData.name[0].family.charAt(0, 2) })) ||
                                                                                                ((newInputData.criteria !== "" && l.enteredBy.shiftC.slot1 !== "" && l.enteredBy.shiftC.slot1 !== null && l.enteredBy.shiftC.slot1 === newInputData.criteria) && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeC && l.shiftIncharge.shiftInchargeC !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeC !== "") && staffData?.filter((i: any) => i.id === l.enteredBy.shiftC.slot1).map((tableData: any) => { return tableData.name[0].given + " " + tableData.name[0].family.charAt(0, 2) })) ||
                                                                                                staffData?.filter((m: any) => m.id === newInputData.criteria && newInputData.criteria !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeC && l.shiftIncharge.shiftInchargeC !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeC !== "") && newInputData.criteria !== l.enteredBy.shiftC.slot1).map((m: any) => { return "--" }) && "--"}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                                                        return t >= 64 && t <= 71 && (<div style={{ display: "flex", gap: "10px", flexDirection: "column", width: "60px", height: "73px", border: "1px solid", borderRadius: "4px" }}>
                                                                                            <span style={{ textAlign: "center", color: " #415F9E", fontFamily: "poppins", fontSize: "15px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal" }}>{newTimeSlot[t]} </span>
                                                                                            <span style={{ textAlign: "center", border: "1px solid #E3E8F1", width: "45px", position: "relative", left: "7px" }}></span>
                                                                                            <span style={{ textAlign: "center", color: " #5E7494", fontFamily: "poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 600, lineHeight: "130%", letterSpacing: "0.016px" }} >{l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && m.location !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).map((n: any) => { return n.location }) : "NA"}-{l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && m.activity !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).map((n: any) => { return n.activity }) : "NA"}</span>
                                                                                        </div>)
                                                                                    })}

                                                                                </div>
                                                                                <span style={{ textAlign: "center", border: "1px solid ", position: "relative", left: "7px", display: "flex", width: "calc(100% - 11px)", top: "31px", borderColor: "#E3E8F1" }}></span>
                                                                                <div style={{ display: "flex", justifyContent: "space-evenly", position: "relative", top: "46px" }}>
                                                                                    <div style={{ width: "157px", fontFamily: "poppins", fontSize: "14px", fontWeight: 500, color: "#000", lineHeight: "normal" }}>{newTimeSlot[72]} {newTimeSlot[72] >= "12:00" && newTimeSlot[72] <= "23:45" ? "PM" : "AM"} to {newTimeSlot[79]} {newTimeSlot[79] >= "12:00" && newTimeSlot[79] <= "23:45" ? "PM" : "AM"}:

                                                                                        <div style={{ position: "absolute", left: "unset", top: "70px", width: "131px", display: "flex", gap: "7px", whiteSpace: "nowrap" }}>
                                                                                            <div style={{ position: "relative", top: "5px", width: "fit-contant" }}>Entered By:</div>
                                                                                            <img src={Rectangle6215}></img>
                                                                                            <div style={{ fontFamily: "poppins", fontSize: "13px", fontWeight: 400, color: "#000", lineHeight: "normal", position: "relative", top: "5px" }}>{((newInputData.criteria === "" && l.enteredBy.shiftC.slot2 !== "" && l.enteredBy.shiftC.slot2 !== null) && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeC && l.shiftIncharge.shiftInchargeC !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeC !== "") && staffData?.filter((i: any) => i.id === l.enteredBy.shiftC.slot2).map((tableData: any) => { return tableData.name[0].given + " " + tableData.name[0].family.charAt(0, 2) })) ||
                                                                                                ((newInputData.criteria !== "" && l.enteredBy.shiftC.slot2 !== "" && l.enteredBy.shiftC.slot2 !== null && l.enteredBy.shiftC.slot2 === newInputData.criteria) && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeC && l.shiftIncharge.shiftInchargeC !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeC !== "") && staffData?.filter((i: any) => i.id === l.enteredBy.shiftC.slot2).map((tableData: any) => { return tableData.name[0].given + " " + tableData.name[0].family.charAt(0, 2) })) ||
                                                                                                staffData?.filter((m: any) => m.id === newInputData.criteria && newInputData.criteria !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeC && l.shiftIncharge.shiftInchargeC !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeC !== "") && newInputData.criteria !== l.enteredBy.shiftC.slot2).map((m: any) => { return "--" }) && "--"}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                                                        return t >= 72 && t <= 79 && (<div style={{ display: "flex", gap: "10px", flexDirection: "column", width: "60px", height: "73px", border: "1px solid", borderRadius: "4px" }}>
                                                                                            <span style={{ textAlign: "center", color: " #415F9E", fontFamily: "poppins", fontSize: "15px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal" }}>{newTimeSlot[t]} </span>
                                                                                            <span style={{ textAlign: "center", border: "1px solid #E3E8F1", width: "45px", position: "relative", left: "7px" }}></span>
                                                                                            <span style={{ textAlign: "center", color: " #5E7494", fontFamily: "poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 600, lineHeight: "130%", letterSpacing: "0.016px" }} >{l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && m.location !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).map((n: any) => { return n.location }) : "NA"}-{l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && m.activity !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).map((n: any) => { return n.activity }) : "NA"}</span>
                                                                                        </div>)
                                                                                    })}
                                                                                </div>
                                                                                <span style={{ textAlign: "center", border: "1px solid", position: "relative", left: "7px", display: "flex", width: "calc(100% - 11px)", top: "75px", borderColor: "#E3E8F1" }}></span>
                                                                                <div style={{ display: "flex", justifyContent: "space-evenly", position: "relative", top: "86px" }}>
                                                                                    <div style={{ width: "159px", fontFamily: "poppins", fontSize: "14px", fontWeight: 500, color: "#000", lineHeight: "normal" }}>{newTimeSlot[80]} {newTimeSlot[80] >= "12:00" && newTimeSlot[80] <= "23:45" ? "PM" : "AM"} to {newTimeSlot[87]} {newTimeSlot[87] >= "12:00" && newTimeSlot[87] <= "23:45" ? "PM" : "AM"}:

                                                                                        <div style={{ position: "absolute", left: "unset", top: "70px", width: "131px", display: "flex", gap: "7px", whiteSpace: "nowrap" }}>
                                                                                            <div style={{ position: "relative", top: "5px", width: "fit-contant" }}>Entered By:</div>
                                                                                            <img src={Rectangle6215}></img>
                                                                                            <div style={{ fontFamily: "poppins", fontSize: "13px", fontWeight: 400, color: "#000", lineHeight: "normal", position: "relative", top: "5px" }}>{((newInputData.criteria === "" && l.enteredBy.shiftC.slot3 !== "" && l.enteredBy.shiftC.slot3 !== null) && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeC && l.shiftIncharge.shiftInchargeC !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeC !== "") && staffData?.filter((i: any) => i.id === l.enteredBy.shiftC.slot3).map((tableData: any) => { return tableData.name[0].given + " " + tableData.name[0].family.charAt(0, 2) })) ||
                                                                                                ((newInputData.criteria !== "" && l.enteredBy.shiftC.slot3 !== "" && l.enteredBy.shiftC.slot3 !== null && l.enteredBy.shiftC.slot3 === newInputData.criteria) && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeC && l.shiftIncharge.shiftInchargeC !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeC !== "") && staffData?.filter((i: any) => i.id === l.enteredBy.shiftC.slot3).map((tableData: any) => { return tableData.name[0].given + " " + tableData.name[0].family.charAt(0, 2) })) ||
                                                                                                staffData?.filter((m: any) => m.id === newInputData.criteria && newInputData.criteria !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeC && l.shiftIncharge.shiftInchargeC !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeC !== "") && newInputData.criteria !== l.enteredBy.shiftC.slot3).map((m: any) => { return "--" }) && "--"}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                                                        return t >= 80 && t <= 87 && (<div style={{ display: "flex", gap: "10px", flexDirection: "column", width: "60px", height: "73px", border: "1px solid", borderRadius: "4px" }}>
                                                                                            <span style={{ textAlign: "center", color: " #415F9E", fontFamily: "poppins", fontSize: "15px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal" }}>{newTimeSlot[t]} </span>
                                                                                            <span style={{ textAlign: "center", border: "1px solid #E3E8F1", width: "45px", position: "relative", left: "7px" }}></span>
                                                                                            <span style={{ textAlign: "center", color: " #5E7494", fontFamily: "poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 600, lineHeight: "130%", letterSpacing: "0.016px" }} >{l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && m.location !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).map((n: any) => { return n.location }) : "NA"}-{l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && m.activity !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).map((n: any) => { return n.activity }) : "NA"}</span>
                                                                                        </div>)
                                                                                    })}

                                                                                </div>
                                                                                <span style={{ textAlign: "center", border: "1px solid", position: "relative", left: "7px", display: "flex", width: "calc(100% - 11px)", top: "116px", borderColor: "#E3E8F1" }}></span>
                                                                                <div style={{ display: "flex", justifyContent: "space-evenly", position: "relative", top: "130px" }}>
                                                                                    <div style={{ width: "158px", fontFamily: "poppins", fontSize: "14px", fontWeight: 500, color: "#000", lineHeight: "normal" }}>{newTimeSlot[88]} {newTimeSlot[88] >= "12:00" && newTimeSlot[88] <= "23:45" ? "PM" : "AM"} to {newTimeSlot[95]} {newTimeSlot[95] >= "12:00" && newTimeSlot[95] <= "23:45" ? "PM" : "AM"}:

                                                                                        <div style={{ position: "absolute", left: "unset", top: "70px", width: "131px", display: "flex", gap: "7px", whiteSpace: "nowrap" }}>
                                                                                            <div style={{ position: "relative", top: "5px", width: "fit-contant" }}>Entered By:</div>
                                                                                            <img src={Rectangle6215}></img>
                                                                                            <div style={{ fontFamily: "poppins", fontSize: "13px", fontWeight: 400, color: "#000", lineHeight: "normal", position: "relative", top: "5px" }}>{((newInputData.criteria === "" && l.enteredBy.shiftC.slot4 !== "" && l.enteredBy.shiftC.slot4 !== null) && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeC && l.shiftIncharge.shiftInchargeC !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeC !== "") && staffData?.filter((i: any) => i.id === l.enteredBy.shiftC.slot4).map((tableData: any) => { return tableData.name[0].given + " " + tableData.name[0].family.charAt(0, 2) })) ||
                                                                                                ((newInputData.criteria !== "" && l.enteredBy.shiftC.slot4 !== "" && l.enteredBy.shiftC.slot4 !== null && l.enteredBy.shiftC.slot4 === newInputData.criteria) && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeC && l.shiftIncharge.shiftInchargeC !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeC !== "") && staffData?.filter((i: any) => i.id === l.enteredBy.shiftC.slot4).map((tableData: any) => { return tableData.name[0].given + " " + tableData.name[0].family.charAt(0, 2) })) ||
                                                                                                staffData?.filter((m: any) => m.id === newInputData.criteria && newInputData.criteria !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeC && l.shiftIncharge.shiftInchargeC !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeC !== "") && newInputData.criteria !== l.enteredBy.shiftC.slot4).map((m: any) => { return "--" }) && "--"}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                                                        return t >= 88 && t <= 95 && (<div style={{ display: "flex", gap: "10px", flexDirection: "column", width: "60px", height: "73px", border: "1px solid", borderRadius: "4px" }}>
                                                                                            <span style={{ textAlign: "center", color: " #415F9E", fontFamily: "poppins", fontSize: "15px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal" }}>{newTimeSlot[t]} </span>
                                                                                            <span style={{ textAlign: "center", border: "1px solid #E3E8F1", width: "45px", position: "relative", left: "7px" }}></span>
                                                                                            <span style={{ textAlign: "center", color: " #5E7494", fontFamily: "poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 600, lineHeight: "130%", letterSpacing: "0.016px" }} >{l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t === 95 ? t = 0 : t + 1].replace(/:/g, '') && m.location !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t === 95 ? t = 0 : t + 1].replace(/:/g, '') && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).map((n: any) => { return n.location }) : "NA"}-{l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t === 95 ? t = 0 : t + 1].replace(/:/g, '') && m.activity !== "" && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t === 95 ? t = 0 : t + 1].replace(/:/g, '') && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "")).map((n: any) => { return n.activity }) : "NA"}</span>
                                                                                        </div>)
                                                                                    })}

                                                                                </div>
                                                                            </Typography>
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                </div>
                                                            </div>
                                                        </Typography>
                                                    </AccordionSummary>
                                                </Accordion>))) : (
                                        <div style={{ fontFamily: "poppins", fontSize: "18px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal", textAlign: 'center' }}>No Records Found</div>
                                    )
                                    }
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    )) : <div style={{ fontFamily: "poppins", fontSize: "18px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal", textAlign: 'center' }}>No Records Found</div>}
                </div>
            </div>
            <div id="q15Reports" hidden={!viewContentData}>
                {viewContentData && 
                  <div className="d-flex flex-row justify-content-end gap-2" >
                    <button className="btn" style={{backgroundColor: primarybg, color: white }} onClick={handlePrint}>Print</button>
                    <button className="btn" style={{backgroundColor: primarybg, color: white }} onClick={() => setViewContent(false)}>Close</button>
                  </div>
                }
                {addCalendarDate !== undefined && addCalendarDate !== null && addCalendarDate.length !== 0 ? addCalendarDate.map((u: any) => (
                    tableData !== null && tableData !== undefined && tableData.filter(j => u === moment(j.q15Date).format("YYYYMMDD")).length > 0 ? (tableData !== null && tableData !== undefined && tableData.filter(j => u === moment(j.q15Date).format("YYYYMMDD") && (newInputData.shiftIncharge === "" ||
                        j.shiftIncharge.shiftInchargeA === newInputData.shiftIncharge || j.shiftIncharge.shiftInchargeB === newInputData.shiftIncharge || j.shiftIncharge.shiftInchargeC === newInputData.shiftIncharge) &&
                        (newInputData.patientName === "" || newInputData.patientName === j.pid) && (newInputData.criteria === "" || newInputData.criteria === j.enteredBy.shiftA.slot1 || newInputData.criteria === j.enteredBy.shiftA.slot2 ||
                            newInputData.criteria === j.enteredBy.shiftA.slot3 || newInputData.criteria === j.enteredBy.shiftA.slot4 || newInputData.criteria === j.enteredBy.shiftB.slot1 || newInputData.criteria === j.enteredBy.shiftB.slot2
                            || newInputData.criteria === j.enteredBy.shiftB.slot3 || newInputData.criteria === j.enteredBy.shiftB.slot4 || newInputData.criteria === j.enteredBy.shiftC.slot1 || newInputData.criteria === j.enteredBy.shiftC.slot2
                            || newInputData.criteria === j.enteredBy.shiftC.slot3 || newInputData.criteria === j.enteredBy.shiftC.slot4)).map((l: any) => (
                                <div>
                                    <div id="removePadding" style={{ textAlign: 'center' }} className="p-col-12 p-md-12">
                                        <img src={trilliumImage} alt="" style={{ height: '100px', width: '100px' }} /></div>
                                    <div id="removePadding" style={{ textAlign: 'center', fontSize: '26px', fontWeight: 'bold' }} className="p-col-12 p-md-12">
                                        Mettler Health Care Organization
                                    </div>
                                    <div id="removePadding" style={{ textAlign: 'right', width: "48%", fontSize: '20px', fontWeight: 'bold', display: "flex", justifyContent: "flex-start", position: "relative", top: "31px" }}>Date:<span style={{ fontWeight: 400 }}>{moment(l.q15Date).format("MMM DD, YYYY")}</span></div>
                                    <div style={{ display: 'flex' }}>
                                        <div id="removePadding" style={{ textAlign: 'right', width: "48%", fontSize: '20px', fontWeight: 'bold' }}>Patient Name:</div>

                                        <div style={{ width: '2%' }}></div>
                                        <div id="removePadding" style={{ textAlign: "left", width: "50%", fontSize: '20px' }} >{patientData?.filter((i: any) => i.id === l.pid).map((tableData: any) => { return tableData.basicDetails[0].name[0].given + " " + tableData.basicDetails[0].name[0].family.charAt(0, 2) })}</div>
                                    </div>
                                    <div style={{ width: "100%", height: "30px" }}></div>

                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ width: '31%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                        <div style={{ width: '42%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Loction</div>
                                        <div style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Activity</div>
                                        <div style={{ width: '30.7%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                        <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Loction</div>
                                        <div style={{ width: '31%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Activity</div>
                                        <div style={{ width: '30.7%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                        <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Loction</div>
                                        <div style={{ width: '31%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Activity</div>
                                    </div>
                                    <div style={{ width: '100%', display: 'flex' }}>
                                        <div style={{ width: '34%' }}>
                                            {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                return t >= 0 && t <= 15 && (<>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div style={{ width: '30.7%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{s}</div>
                                                        <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && m.locationName !== "" && m.locationName !== null && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || (newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") || (l.shiftIncharge.shiftInchargeA === m.shiftIncharge))).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || (newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") || (l.shiftIncharge.shiftInchargeA === m.shiftIncharge))).map((n: any) => { return n.locationName }) : ""}
                                                        </div>
                                                        <div style={{ width: '31%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && m.activityName !== "" && m.activityName !== null && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || (newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") || (l.shiftIncharge.shiftInchargeA === m.shiftIncharge))).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || (newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") || (l.shiftIncharge.shiftInchargeA === m.shiftIncharge))).map((n: any) => { return n.activityName }) : ""}</div>
                                                    </div>
                                                </>)
                                            })}
                                        </div>

                                        <div style={{ width: '33%' }}>
                                            {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                return t >= 32 && t <= 47 && (<>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div style={{ width: '30.7%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{s}</div>
                                                        <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && m.locationName !== "" && m.locationName !== null && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || (newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") || (l.shiftIncharge.shiftInchargeA === m.shiftIncharge))).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || (newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") || (l.shiftIncharge.shiftInchargeA === m.shiftIncharge))).map((n: any) => { return n.locationName }) : ""}</div>
                                                        <div style={{ width: '31%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && m.activityName !== "" && m.activityName !== null && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || (newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") || (l.shiftIncharge.shiftInchargeA === m.shiftIncharge))).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || (newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") || (l.shiftIncharge.shiftInchargeA === m.shiftIncharge))).map((n: any) => { return n.activityName }) : ""}</div>
                                                    </div>
                                                </>)
                                            })}
                                        </div>
                                        <div style={{ width: '33%' }}>
                                            {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                return t >= 64 && t <= 79 && (<>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div style={{ width: '30.7%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{s}</div>
                                                        <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && m.locationName !== "" && m.locationName !== null && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || (newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") || (l.shiftIncharge.shiftInchargeA === m.shiftIncharge))).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || (newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") || (l.shiftIncharge.shiftInchargeA === m.shiftIncharge))).map((n: any) => { return n.locationName }) : ""}</div>
                                                        <div style={{ width: '31%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && m.activityName !== "" && m.activityName !== null && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || (newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") || (l.shiftIncharge.shiftInchargeA === m.shiftIncharge))).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || (newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") || (l.shiftIncharge.shiftInchargeA === m.shiftIncharge))).map((n: any) => { return n.activityName }) : ""}</div>
                                                    </div>
                                                </>)
                                            })}
                                        </div>
                                    </div>
                                    <div style={{ width: '100%', display: 'flex' }}>
                                        <div style={{ width: '34%' }}>
                                            {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                return t >= 16 && t <= 31 && (<>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div style={{ width: '30.7%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{s}</div>
                                                        <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && m.locationName !== "" && m.locationName !== null && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || (newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") || (l.shiftIncharge.shiftInchargeA === m.shiftIncharge))).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || (newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") || (l.shiftIncharge.shiftInchargeA === m.shiftIncharge))).map((n: any) => { return n.locationName }) : ""}</div>
                                                        <div style={{ width: '31%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && m.activityName !== "" && m.activityName !== null && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || (newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") || (l.shiftIncharge.shiftInchargeA === m.shiftIncharge))).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || (newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") || (l.shiftIncharge.shiftInchargeA === m.shiftIncharge))).map((n: any) => { return n.activityName }) : ""}</div>
                                                    </div>
                                                </>)
                                            })}
                                        </div>
                                        <div style={{ width: '33%' }}>
                                            {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                return t >= 48 && t <= 63 && (<>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div style={{ width: '30.7%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{s}</div>
                                                        <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && m.locationName !== "" && m.locationName !== null && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || (newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") || (l.shiftIncharge.shiftInchargeA === m.shiftIncharge))).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || (newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") || (l.shiftIncharge.shiftInchargeA === m.shiftIncharge))).map((n: any) => { return n.locationName }) : ""}</div>
                                                        <div style={{ width: '31%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && m.activityName !== "" && m.activityName !== null && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || (newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") || (l.shiftIncharge.shiftInchargeA === m.shiftIncharge))).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t + 1].replace(/:/g, '') && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || (newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") || (l.shiftIncharge.shiftInchargeA === m.shiftIncharge))).map((n: any) => { return n.activityName }) : ""}</div>
                                                    </div>
                                                </>)
                                            })}
                                        </div>

                                        <div style={{ width: '33%' }}>
                                            {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                return t >= 80 && t <= 95 && (<>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div style={{ width: '30.7%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{s}</div>
                                                        <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t === 95 ? t = 0 : t + 1].replace(/:/g, '') && m.locationName !== "" && m.locationName !== null && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || (newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") || (l.shiftIncharge.shiftInchargeA === m.shiftIncharge))).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t === 95 ? t = 0 : t + 1].replace(/:/g, '') && m.locationName !== "" && m.locationName !== null && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || (newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") || (l.shiftIncharge.shiftInchargeA === m.shiftIncharge))).map((n: any) => { return n.locationName }) : ""}</div>
                                                        <div style={{ width: '31%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t === 95 ? t = 0 : t + 1].replace(/:/g, '') && m.activityName !== "" && m.activityName !== null && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || (newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") || (l.shiftIncharge.shiftInchargeA === m.shiftIncharge))).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t === 95 ? t = 0 : t + 1].replace(/:/g, '') && m.activityName !== "" && m.activityName !== null && ((newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "") || (newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "") || (l.shiftIncharge.shiftInchargeA === m.shiftIncharge))).map((n: any) => { return n.activityName }) : ""}</div>
                                                    </div>
                                                </>)
                                            })}
                                        </div>
                                    </div>
                                    <div style={{ width: "100%", height: "30px" }}></div>
                                    <div style={{ display: 'flex', flexDirection: "row", width: "100%", justifyContent: "space-between" }} >
                                        <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center', height: "50px", lineHeight: 2.5, fontWeight: 'bold' }}>Shift</div>
                                        <div style={{ width: '50%', border: '1px solid #C9C9C9', textAlign: 'center', height: "50px", lineHeight: 2.5, fontWeight: 'bold' }}>Staff Name</div>
                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: "50px", lineHeight: 2.5, fontWeight: 'bold' }}>Sign Name</div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: "row", width: "100%", justifyContent: "space-between" }} >
                                        <div style={{ width: '20%', borderLeft: "1px solid #C9C9C9 ", textAlign: 'center', height: "30px", lineHeight: 2.5 }}></div>
                                        <div style={{ width: '50%', border: '1px solid #C9C9C9', textAlign: 'center', height: "30px", lineHeight: 1.5 }}>
                                            {/* {newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "" && staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeA).map((tableData: any) => { return tableData.name[0].given }) ||
                                                newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "" && staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeA && i.id === newInputData.shiftIncharge).map((tableData: any) => { return tableData.name[0].given }) || "--"} */}
                                      {l.shiftIncharge.shiftInchargeNameA}
                                        </div>
                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: "30px", lineHeight: 2.5 }}></div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: "row", width: "100%", justifyContent: "space-between" }} >
                                        <div style={{ width: '20%', borderLeft: "1px solid #C9C9C9 ", textAlign: 'center', height: "30px", lineHeight: 2.5 }}></div>
                                        <div style={{ width: '50%', border: '1px solid #C9C9C9', textAlign: 'center', height: "30px", lineHeight: 1.5 }}>
                                            {/* {newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "" && staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeB).map((tableData: any) => { return tableData.name[0].given }) ||
                                                newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeB && l.shiftIncharge.shiftInchargeB !== "" && staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeB && i.id === newInputData.shiftIncharge).map((tableData: any) => { return tableData.name[0].given }) || "--"} */}
                                            {l.enteredBy.shiftA.slotName1}
                                        </div>

                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: "30px", lineHeight: 2.5 }}></div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: "row", width: "100%", justifyContent: "space-between" }} >
                                        <div style={{ width: '20%', borderLeft: "1px solid #C9C9C9 ", textAlign: 'center', height: "30px", lineHeight: 2.5 }}>{newTimeSlot[0]} to {newTimeSlot[31]}</div>
                                        <div style={{ width: '50%', border: '1px solid #C9C9C9', textAlign: 'center', height: "30px", lineHeight: 1.5 }}>
                                            {/* {newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "" && staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeC).map((tableData: any) => { return tableData.name[0].given }) ||
                                                newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeC && l.shiftIncharge.shiftInchargeC !== "" && staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeC && i.id === newInputData.shiftIncharge).map((tableData: any) => { return tableData.name[0].given }) || "--"} */}
                                                 {l.enteredBy.shiftA.slotName2}
                                        </div>

                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: "30px", lineHeight: 2.5 }}></div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: "row", width: "100%", justifyContent: "space-between" }} >
                                        <div style={{ width: '20%', borderLeft: "1px solid #C9C9C9 ", textAlign: 'center', height: "30px", lineHeight: 2.5 }}></div>
                                        <div style={{ width: '50%', border: '1px solid #C9C9C9', textAlign: 'center', height: "30px", lineHeight: 1.5 }}>
                                            {/* {newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "" && staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeC).map((tableData: any) => { return tableData.name[0].given }) ||
                                                newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeC && l.shiftIncharge.shiftInchargeC !== "" && staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeC && i.id === newInputData.shiftIncharge).map((tableData: any) => { return tableData.name[0].given }) || "--"} */}
                                                   {l.enteredBy.shiftA.slotName3}
                                        </div>

                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: "30px", lineHeight: 2.5 }}></div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: "row", width: "100%", justifyContent: "space-between" }} >
                                        <div style={{ width: '20%', borderLeft: "1px solid #C9C9C9 ", borderBottom: "1px solid #C9C9C9", textAlign: 'center', height: "30px", lineHeight: 2.5 }}></div>
                                        <div style={{ width: '50%', border: '1px solid #C9C9C9', textAlign: 'center', height: "30px", lineHeight: 1.5 }}>
                                            {/* {newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "" && staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeC).map((tableData: any) => { return tableData.name[0].given }) ||
                                                newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeC && l.shiftIncharge.shiftInchargeC !== "" && staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeC && i.id === newInputData.shiftIncharge).map((tableData: any) => { return tableData.name[0].given }) || "--"} */}
                                                   {l.enteredBy.shiftA.slotName4}
                                        </div>

                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: "30px", lineHeight: 2.5 }}></div>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: "row", width: "100%", justifyContent: "space-between" }} >
                                        <div style={{ width: '20%', borderLeft: "1px solid #C9C9C9 ", textAlign: 'center', height: "30px", lineHeight: 2.5 }}></div>
                                        <div style={{ width: '50%', border: '1px solid #C9C9C9', textAlign: 'center', height: "30px", lineHeight: 1.5 }}>
                                            {/* {newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "" && staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeA).map((tableData: any) => { return tableData.name[0].given }) ||
                                                newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "" && staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeA && i.id === newInputData.shiftIncharge).map((tableData: any) => { return tableData.name[0].given }) || "--"} */}
                                                   {l.shiftIncharge.shiftInchargeNameB}
                                        </div>
                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: "30px", lineHeight: 2.5 }}></div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: "row", width: "100%", justifyContent: "space-between" }} >
                                        <div style={{ width: '20%', borderLeft: "1px solid #C9C9C9 ", textAlign: 'center', height: "30px", lineHeight: 2.5 }}></div>
                                        <div style={{ width: '50%', border: '1px solid #C9C9C9', textAlign: 'center', height: "30px", lineHeight: 1.5 }}>
                                            {/* {newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "" && staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeB).map((tableData: any) => { return tableData.name[0].given }) ||
                                                newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeB && l.shiftIncharge.shiftInchargeB !== "" && staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeB && i.id === newInputData.shiftIncharge).map((tableData: any) => { return tableData.name[0].given }) || "--"} */}
                                                {l.enteredBy.shiftB.slotName1}
                                        </div>

                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: "30px", lineHeight: 2.5 }}></div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: "row", width: "100%", justifyContent: "space-between" }} >
                                        <div style={{ width: '20%', borderLeft: "1px solid #C9C9C9 ", textAlign: 'center', height: "30px", lineHeight: 2.5 }}>{newTimeSlot[32]} to {newTimeSlot[63]}</div>

                                        <div style={{ width: '50%', border: '1px solid #C9C9C9', textAlign: 'center', height: "30px", lineHeight: 1.5 }}>
                                            {/* {newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "" && staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeC).map((tableData: any) => { return tableData.name[0].given }) ||
                                                newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeC && l.shiftIncharge.shiftInchargeC !== "" && staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeC && i.id === newInputData.shiftIncharge).map((tableData: any) => { return tableData.name[0].given }) || "--"} */}
                                                    {l.enteredBy.shiftB.slotName2}
                                        </div>

                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: "30px", lineHeight: 2.5 }}></div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: "row", width: "100%", justifyContent: "space-between" }} >
                                        <div style={{ width: '20%', borderLeft: "1px solid #C9C9C9 ", textAlign: 'center', height: "30px", lineHeight: 2.5 }}></div>
                                        <div style={{ width: '50%', border: '1px solid #C9C9C9', textAlign: 'center', height: "30px", lineHeight: 1.5 }}>
                                            {/* {newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "" && staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeC).map((tableData: any) => { return tableData.name[0].given }) ||
                                                newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeC && l.shiftIncharge.shiftInchargeC !== "" && staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeC && i.id === newInputData.shiftIncharge).map((tableData: any) => { return tableData.name[0].given }) || "--"} */}
                                                    {l.enteredBy.shiftB.slotName3}
                                        </div>

                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: "30px", lineHeight: 2.5 }}></div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: "row", width: "100%", justifyContent: "space-between" }} >
                                        <div style={{ width: '20%', borderLeft: "1px solid #C9C9C9 ", borderBottom: "1px solid #C9C9C9", textAlign: 'center', height: "30px", lineHeight: 2.5 }}></div>
                                        <div style={{ width: '50%', border: '1px solid #C9C9C9', textAlign: 'center', height: "30px", lineHeight: 1.5 }}>
                                            {/* {newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "" && staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeC).map((tableData: any) => { return tableData.name[0].given }) ||
                                                newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeC && l.shiftIncharge.shiftInchargeC !== "" && staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeC && i.id === newInputData.shiftIncharge).map((tableData: any) => { return tableData.name[0].given }) || "--"} */}
                                            {l.enteredBy.shiftB.slotName4}
                                        </div>

                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: "30px", lineHeight: 2.5 }}></div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: "row", width: "100%", justifyContent: "space-between" }} >
                                        <div style={{ width: '20%', borderLeft: "1px solid #C9C9C9 ", textAlign: 'center', height: "30px", lineHeight: 2.5 }}></div>
                                        <div style={{ width: '50%', border: '1px solid #C9C9C9', textAlign: 'center', height: "30px", lineHeight: 1.5 }}>
                                            {/* {newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "" && staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeA).map((tableData: any) => { return tableData.name[0].given }) ||
                                                newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeA && l.shiftIncharge.shiftInchargeA !== "" && staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeA && i.id === newInputData.shiftIncharge).map((tableData: any) => { return tableData.name[0].given }) || "--"} */}
                                                {l.shiftIncharge.shiftInchargeNameC}
                                        </div>
                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: "30px", lineHeight: 2.5 }}></div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: "row", width: "100%", justifyContent: "space-between" }} >
                                        <div style={{ width: '20%', borderLeft: "1px solid #C9C9C9 ", textAlign: 'center', height: "30px", lineHeight: 2.5 }}></div>
                                        <div style={{ width: '50%', border: '1px solid #C9C9C9', textAlign: 'center', height: "30px", lineHeight: 1.5 }}>
                                            {/* {newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "" && staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeB).map((tableData: any) => { return tableData.name[0].given }) ||
                                                newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeB && l.shiftIncharge.shiftInchargeB !== "" && staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeB && i.id === newInputData.shiftIncharge).map((tableData: any) => { return tableData.name[0].given }) || "--"} */}
                                                {l.enteredBy.shiftC.slotName1}
                                        </div>

                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: "30px", lineHeight: 2.5 }}></div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: "row", width: "100%", justifyContent: "space-between" }} >
                                        <div style={{ width: '20%', borderLeft: "1px solid #C9C9C9 ", textAlign: 'center', height: "30px", lineHeight: 2.5 }}>{newTimeSlot[64]} to {newTimeSlot[95]}</div>
                                        <div style={{ width: '50%', border: '1px solid #C9C9C9', textAlign: 'center', height: "30px", lineHeight: 1.5 }}>
                                            {/* {newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "" && staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeC).map((tableData: any) => { return tableData.name[0].given }) ||
                                                newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeC && l.shiftIncharge.shiftInchargeC !== "" && staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeC && i.id === newInputData.shiftIncharge).map((tableData: any) => { return tableData.name[0].given }) || "--"} */}
                                                {l.enteredBy.shiftC.slotName2}
                                        </div>

                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: "30px", lineHeight: 2.5 }}></div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: "row", width: "100%", justifyContent: "space-between" }} >
                                        <div style={{ width: '20%', borderLeft: "1px solid #C9C9C9 ", textAlign: 'center', height: "30px", lineHeight: 2.5 }}></div>
                                        <div style={{ width: '50%', border: '1px solid #C9C9C9', textAlign: 'center', height: "30px", lineHeight: 1.5 }}>
                                            {/* {newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "" && staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeC).map((tableData: any) => { return tableData.name[0].given }) ||
                                                newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeC && l.shiftIncharge.shiftInchargeC !== "" && staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeC && i.id === newInputData.shiftIncharge).map((tableData: any) => { return tableData.name[0].given }) || "--"} */}
                                                {l.enteredBy.shiftC.slotName3}
                                        </div>

                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: "30px", lineHeight: 2.5 }}></div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: "row", width: "100%", justifyContent: "space-between" }} >
                                        <div style={{ width: '20%', borderLeft: "1px solid #C9C9C9 ", borderBottom: "1px solid #C9C9C9", textAlign: 'center', height: "30px", lineHeight: 2.5 }}></div>
                                        <div style={{ width: '50%', border: '1px solid #C9C9C9', textAlign: 'center', height: "30px", lineHeight: 1.5 }}>
                                            {/* {newInputData.shiftIncharge === "" && l.shiftIncharge.shiftInchargeA !== "" && staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeC).map((tableData: any) => { return tableData.name[0].given }) ||
                                                newInputData.shiftIncharge !== "" && newInputData.shiftIncharge === l.shiftIncharge.shiftInchargeC && l.shiftIncharge.shiftInchargeC !== "" && staffData?.filter((i: any) => i.id === l.shiftIncharge.shiftInchargeC && i.id === newInputData.shiftIncharge).map((tableData: any) => { return tableData.name[0].given }) || "--"} */}
                                                 {l.enteredBy.shiftC.slotName4}
                                        </div>

                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: "30px", lineHeight: 2.5 }}></div>
                                    </div>
                                    <div style={{ width: "100%", height: "30px" }}></div>
                                    <div style={{ width: "100%", height: "30px" }}></div>
                                    <div style={{ display: 'flex', flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
                                        <div style={{ width: '17%', border: '1px solid #C9C9C9', textAlign: 'center', height: "30px", lineHeight: 1.8, fontWeight: 600 }}>shift</div>
                                        <div style={{ width: '83%', border: '1px solid #C9C9C9', textAlign: 'center', height: "30px", lineHeight: 1.8, fontWeight: 600 }}>Remarks</div>
                                    </div>
                                    {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                        return l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t === 95 ? t = 0 : t + 1].replace(/:/g, '') && m.remarks !== null && m.remarks).length > 0 ? l.data.filter((m: any) => m.q15Time === newTimeSlot[t].replace(/:/g, '') + "-" + newTimeSlot[t === 95 ? t = 0 : t + 1].replace(/:/g, '') && m.remarks !== null && m.remarks).map((n: any) => {
                                            return (<>
                                                <div style={{ display: 'flex', flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
                                                    <div style={{ width: '17%', border: '1px solid #C9C9C9', textAlign: 'center', height: "30px", lineHeight: 1.8, fontWeight: 600 }}>{newTimeSlot[t] + " - " + newTimeSlot[t === 95 ? t = 0 : t + 1]}</div>
                                                    <div style={{ width: '83%', border: '1px solid #C9C9C9', textAlign: 'center', height: "30px", lineHeight: 1.8, fontWeight: 600 }}>{n.remarks}</div>
                                                </div>
                                            </>)
                                        }) : ""
                                    })}
                                    <div style={{ display: 'flex', flexDirection: "row", width: "100%", justifyContent: "space-between", pageBreakAfter: "always" }}></div>
                                </div>
                            ))) : (
                        <div style={{ fontFamily: "poppins", fontSize: "18px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal", textAlign: 'center' }}>No Records Found</div>
                    )
                )) : <div style={{ fontFamily: "poppins", fontSize: "18px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal", textAlign: 'center' }}>No Records Found</div>}

            </div>
        </div>
    );
};